import { CLEAR_CAMPAIGNS, UPDATE_CAMPAIGNS } from "../actions/campaigns";

export default (prevState = { campaigns: [] }, action) => {
  switch (action.type) {
    case UPDATE_CAMPAIGNS:
      return { ...prevState, campaigns: action.campaigns };
    case CLEAR_CAMPAIGNS: {
      return { ...prevState, campaigns: [] };
    }
    default:
      return prevState;
  }
};
