import React from "react";
import Footer from "../../components/Footer";
import style from "./style.module.scss";

import mouth from "../../assets/FAQ/mouth.svg";
import tlMouth from "../../assets/FAQ/top-left-mouth.svg";

export default function FAQ() {
  return (
    <div className={style.faq}>
      <div className="bcg-svg">
        <div className={style.bottomRightMouth}>
          <img src={mouth} alt="mouth image" />
        </div>
        <div className={style.topLeftMouth}>
          <img src={tlMouth} alt="mouth image" />
        </div>
      </div>
      <div className={style.questions}>
        <h2>frequently asked questions</h2>
        <div className={style.question}>
          <h3>What Is Keff?</h3>
          <p>
            Kef.gg allows you to earn doing what you enjoy most - playing games.
            get paid instantly for doing stuff online
          </p>
        </div>
        <div className={style.question}>
          <h3>Does It Cost Money?</h3>
          <p>There is no need to spend money in order to earn free coins.</p>
        </div>
        <div className={style.question}>
          <h3>What Are $Kay Coins?</h3>
          <p>
            $Kay coins are a virtual currency that can be used to exchange for a
            variety of rewards. 1000 $Kay coins are worth $1 USD
          </p>
        </div>
        <div className={style.question}>
          <h3>How Do I get Started?</h3>
          <p>
            Sign up using your preferred method to discover a new community of
            likewise people
          </p>
        </div>
        <div className={style.question}>
          <h3>How Much Money Can You Really Earn On Kef.gg?</h3>
          <p>
            There is no glass ceiling on Kef.gg. with 15% lifetime referrals on
            friends you brought - you will earn on all of their results earned
            on the platform - Become a partner
          </p>
        </div>
        <div className={style.question}>
          <h3>How do I earn coins?</h3>
          <p>
            Start with Earn page, where you will see our available offers based
            on your location. Complete offers to earn Coins. all offers are
            displayed with a detailed description of needs to be done
          </p>
        </div>
        <div className={style.question}>
          <h3>How long does it take to receive rewards?</h3>
          <p>
            That's how long it takes to get rewarded. <br /> Most offers award
            coins within 5 minutes, but as mentioned above, there are rare cases
            where the credit of the offer is delayed by 48 hours. <br /> It can
            take up to 12 hours for unverified accounts to receive their
            rewards. <br />
            Our staff will check an account once it has filled in at least
            10,000 coins ($1,000 worth of offers).
          </p>
        </div>
        <div className={style.question}>
          <h3>Why have I not received my coins?</h3>
          <p>
            In order to combat fraud, our staff manually review certain high
            value offers. To see if your offer is pending verification, click on
            your Profile and then click on the Held Offers tab. <br /> If the
            offer is not pending our verification, you must contact the offer
            wall provider you used to complete the task.
          </p>
        </div>
        <div className={style.question}>
          <h3>
            Our staff here at Kef.gg cannot investigate why an offer has not
            credited properly. You must contact the offer wall providers
            directly to receive compensation.
          </h3>
        </div>
        <div className={style.question}>
          <h3>Can I use VPN, VPS, Or Emulator?</h3>
          <p>
            No. Use of this type of software is strictly prohibited. Users
            caught using a VPN, VPS, or Emulator will be permanently banned.
          </p>
        </div>
        <div className={style.question}>
          <h3>Can I use Multiple accounts?</h3>
          <p>
            No. Users are only permitted to have one account. Users with
            multiple accounts will be permanently banned.
          </p>
        </div>
        <div className={style.question}>
          <h3>
            We permit one account per household, it is up to you to ensure no
            other users in your household register on the site, as this will
            result in a permanent ban.
          </h3>
        </div>
        <a href="mailto: admin@kef.gg">Contact Our On-Site Support</a>
      </div>
    </div>
  );
}
