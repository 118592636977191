import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import Message from "./Message";
import Input from "./Input";
import { useSelector } from "react-redux";
import { store } from "../../store";
import { updateChat } from "../../store/actions/chatActive";
import { Disable as DisableChat } from "../../store/actions/chatActive";
import Mouth from "../../assets/Chat/mouth.svg";
import EventGA from "../../components/EventGA";

import style from "./style.module.scss";
const client_id = Date.now();
let ENV = "";
if (process.env.REACT_APP_STATUS === "PRODUCTION") {
  ENV = "kef.gg";
} else {
  ENV = "staging.kef.gg";
}
const ws = new WebSocket(`wss://${ENV}/api/chatWebSocket/${client_id}`);

export default function Chat() {
  const active = useSelector((state) => state.updateChat.boolean);
  const [socketData, setSocketData] = useState();
  const [ting, setTing] = useState(false);
  const username =
    useSelector(
      (state) =>
        state.authReducer.user?.attributes?.given_name ??
        state.authReducer.user?.username
    ) ?? "";
  const [time, setTime] = useState();
  const [message, setMessage] = useState([]);
  const [picId, setPicId] = useState();
  const [onlineUsers, setOnlineUsers] = useState(0);
  const logedIn = useSelector((state) => state.authReducer.authenticated);

  const handleResponse = (data) => {
    sendMessage(data);
  };

  const handleUsers = (data) => {
    setOnlineUsers(data);
  };
  useEffect(() => {
    const avatarLocalStorage = localStorage.getItem("avatar");
    setPicId(avatarLocalStorage);
    ws.addEventListener("error", function (m) {});
    ws.addEventListener("open", function (m) {});
    ws.addEventListener("message", function (m) {
      setSocketData(m.data);
      const obj = JSON.parse(m.data);
      setMessage((current) => [...current, obj]);
      if (obj?.chat_start == true) {
      } else {
        setTing(true);
      }
    });
    ws.addEventListener("close", function (m) {});
    return () => {
      ws.close();
    };
  }, []);

  function formatDate() {
    var date = new Date();
    var hours = date.getUTCHours();
    var minutes = date.getUTCMinutes();
    var seconds = date.getUTCSeconds();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + ":" + seconds + " " + ampm;
    return strTime;
  }

  function sendMessage(msg) {
    waitForSocketConnection(ws, function () {
      var currentTime = formatDate();
      setTime(currentTime);
      ws.send(
        JSON.stringify({
          username,
          currentTime,
          msg,
          picId,
        })
      );
    });
  }

  function waitForSocketConnection(socket, callback) {
    setTimeout(function () {
      if (socket.readyState === 1) {
        if (callback !== undefined) {
          callback();
        }
        return;
      } else {
        waitForSocketConnection(socket, callback);
      }
    }, 5);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setTing(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [ting]);

  return (
    <div className={`${style.container} ${active ? "" : style.active}`}>
      <div className={style.contentContainer}>
        <div className={style.buttonContainer}>
          <button className="button-secondary">Chat</button>
          <button
            onClick={() => {
              window.open("https://discord.gg/MB3h237d6n", "_blank");
            }}
            className="button-inactive"
          >
            Support
          </button>
        </div>
        <div className={style.onlineContainer}>
          <div className={style.icon} />
          <p className={style.count}>ONLINE: {onlineUsers}</p>
        </div>
        <div className={style.messageContainer}>
          <div className={style.messageWrapper}>
            {socketData &&
              message
                .slice(0)
                .reverse()
                .map((value, index) => {
                  const user = Object.values(value);
                  const strategiesArray = Object.keys(value).map(
                    (key) => value[key]
                  );
                  return <Message data={value} handleUsers={handleUsers} />;
                })}
          </div>
        </div>
      </div>
      <div className={style.inputContainer}>
        <Input handleResponse={handleResponse} />
      </div>
      <div
        className={style.collapser}
        onClick={() => {
          store.dispatch(updateChat(!active));
        }}
      >
        <svg
          className={style.arrow}
          width="20"
          height="11"
          viewBox="0 0 20 11"
          fill="none"
        >
          <path
            d="M13.75 10.0086L20 5.20072L13.75 0.392822V2.79677H0V7.60467H13.75V10.0086Z"
            fill="white"
            fill-opacity="0.9"
          />
        </svg>
      </div>
      <div
        className={style.chatSvg}
        onClick={() => {
          store.dispatch(updateChat(!active));
          <EventGA
            category={"ButtonClick"}
            action={"(Button) Chat Open/Close"}
          />;
        }}
      >
        <img
          src={Mouth}
          alt="image"
          className={`${style.chat} ${ting ? style.ting : ""}`}
        />
      </div>
    </div>
  );
}
