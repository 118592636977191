import {
  UPDATE_FEATURED_OFFERS,
  CLEAR_FEATURED_OFFERS,
} from "../actions/featuredOffers";

export default (prevState = { featuredOffers: [] }, action) => {
  switch (action.type) {
    case UPDATE_FEATURED_OFFERS:
      return { ...prevState, featuredOffers: action.featuredOffers };
    case CLEAR_FEATURED_OFFERS: {
      return { ...prevState, featuredOffers: [] };
    }
    default:
      return prevState;
  }
};
