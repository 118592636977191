import React, { useState } from "react";

import style from "./style.module.scss";

import location from "../../assets/PrivacyPolicy/location.svg";

export default function PrivacyPolicy() {
  // const [isActive, setActive] = useState(false)
  // const showMenu = () => {
  //     setActive(!isActive)
  // }

  // const [selectedLang, setSelectedLang] = useState('Select Language')
  // document.querySelectorAll('.style_option__2Woc6 label').forEach(o => {
  //     o.addEventListener('click', () => {
  //         setSelectedLang(o.innerText);
  //         // setActive(!isActive)
  //     })
  // })

  return (
    <div className={style.privacyPolicy}>
      <div className={style.bcgSvg}>
        <div className={style.topLeftEye}>
          <svg
            width="111"
            height="109"
            viewBox="0 0 111 109"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M36.2765 57.5581C36.2765 57.5581 37.0925 74.471 18.4918 86.1823C-3.20603 99.8473 -16.2537 87.5539 -16.2537 87.5539C-15.2509 85.7785 -12.6054 81.683 -7.12859 76.5867C-6.95281 76.9174 -6.7651 77.2431 -6.56545 77.5639C-1.56996 85.1177 8.5308 87.3225 16.2196 82.5373C23.9083 77.7522 26.391 67.7161 21.8198 59.8982C21.6768 59.6684 21.5321 59.4359 21.3719 59.2206C31.0931 56.051 36.2765 57.5581 36.2765 57.5581Z"
              fill="white"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.8316 59.8974C26.4028 67.7152 23.9201 77.7513 16.2314 82.5365C8.54258 87.3216 -1.55817 85.1169 -6.55366 77.5631C-6.75443 77.2405 -6.94215 76.9147 -7.1168 76.5859C-4.88102 74.5176 -2.52935 72.5782 -0.0731867 70.7771C1.81248 69.3898 3.89261 67.9714 6.1844 66.5376C8.56335 65.0571 10.8062 63.8037 12.9157 62.7459C15.6488 61.3586 18.4821 60.1782 21.3918 59.2147C21.5385 59.4384 21.6886 59.6676 21.8316 59.8974Z"
              fill="#62A3FF"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.5969 63.6943C15.852 67.3179 14.7427 72.0835 11.1191 74.3387C7.49557 76.5938 2.72993 75.4845 0.474789 71.8609C0.260042 71.5173 0.0733603 71.1569 -0.0834395 70.7832C1.80222 69.3959 3.88236 67.9775 6.17414 66.5437C8.5531 65.0632 10.7959 63.8098 12.9054 62.752C13.1591 63.0484 13.3903 63.3634 13.5969 63.6943Z"
              fill="#1D1E5F"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M-2.14432 75.5008L0.949972 72.5362"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M-1.23724 81.3538L3.09723 74.422"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.24134 83.8726L5.54014 75.3533"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.49681 83.8764L8.39574 75.5868"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.6567 74.6219L15.3742 82.2021"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.9473 72.7549L19.986 77.8216"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.75 72.0166L22.3393 72.3889"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.2646 70.5059L19.5232 71.7299"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.7588 67.5684L22.8559 66.8271"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.2646 65.0439L21.1593 61.7619"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <ellipse
              cx="4.66768"
              cy="75.6385"
              rx="3.49301"
              ry="3.49301"
              transform="rotate(-31.8963 4.66768 75.6385)"
              fill="white"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M36.2752 57.5573C42.8065 68.0517 36.5848 83.7264 22.3733 92.571C8.16172 101.416 -8.64252 100.086 -15.1805 89.581C-15.5863 88.9289 -15.9464 88.2495 -16.2584 87.5477C-16.2584 87.5477 -3.20733 99.8465 18.4871 86.1761C37.0979 74.481 36.2752 57.5573 36.2752 57.5573Z"
              fill="#FFE2BF"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M30.0617 80.6354C34.446 75.1577 38.6416 67.0446 36.2798 57.567"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.3499 84.7124C27.0515 84.032 27.8002 83.2584 28.5684 82.4016"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.99572 95.0139L9.59788 92.4825"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.40208 95.237L7.51464 93.0254"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.5785 92.0047L12.058 92.7752"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M36.2784 57.5574C36.2784 57.5574 25.5803 54.4268 6.1762 66.5405C-8.79134 75.8819 -14.6422 84.71 -16.2485 87.5586C-20.8874 77.1579 -14.5854 62.8659 -1.26863 54.5781C12.9429 45.7335 29.7539 47.0737 36.2784 57.5574Z"
              fill="#FFE2BF"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.2246 52.2236C16.8139 51.9998 18.4171 51.8894 20.0221 51.8932"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M-5.70107 62.747C-1.8972 59.187 4.36251 54.5412 12.6251 52.6918"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M-16.2501 87.5585C-16.2501 87.5585 -15.7401 77.79 -3.9308 68.6589"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.8025 52.7625C26.8025 52.7625 28.054 53.4838 28.813 55.993"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.7832 50.7959C21.7832 50.7959 24.8723 52.4813 25.7201 57.1218"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M46.7938 51.0103C46.7938 51.0103 61.6078 59.2116 80.3297 47.695C102.172 34.2625 96.9047 17.127 96.9047 17.127C94.8716 17.241 90.0261 17.8075 83.0351 20.4708C83.2541 20.7745 83.4635 21.0868 83.6631 21.4076C88.2343 29.2254 85.7516 39.2615 78.0629 44.0467C70.3741 48.8318 60.2733 46.627 55.2778 39.0733C55.1348 38.8435 54.9918 38.6137 54.8677 38.3721C47.7227 45.6992 46.7938 51.0103 46.7938 51.0103Z"
              fill="white"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M55.2659 39.0887C60.2614 46.6425 70.3622 48.8472 78.051 44.0621C85.7397 39.2769 88.2224 29.2409 83.6512 21.423C83.4527 21.104 83.2525 20.7823 83.0232 20.4862C80.1801 21.5787 77.4015 22.8321 74.7009 24.2404C72.6234 25.3196 70.4323 26.5595 68.1354 27.9852C65.7565 29.4657 63.6415 30.9245 61.7507 32.3338C59.3 34.1745 56.9899 36.1952 54.8393 38.3791C54.9853 38.6257 55.1229 38.8589 55.2659 39.0887Z"
              fill="#62A3FF"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M62.3071 33.3788C64.5622 37.0023 69.3279 38.1116 72.9514 35.8565C76.575 33.6014 77.6843 28.8357 75.4292 25.2122C75.2155 24.8679 74.9746 24.5412 74.7088 24.2354C72.6313 25.3145 70.4401 26.5544 68.1433 27.9801C65.7643 29.4607 63.6494 30.9194 61.7586 32.3287C61.9147 32.692 62.098 33.0431 62.3071 33.3788L62.3071 33.3788Z"
              fill="#1D1E5F"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M79.8516 24.4696L75.8252 25.9365"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M83.2223 23.2421L80.8682 24.0996"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M84.7021 27.8679L76.5703 28.6982"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M84.5432 33.8997L76.3262 31.3027"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M82.2282 38.6153L75.2754 33.9639"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M73.4106 35.5692L78.1265 43.1467"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M70.7226 36.797L72.1617 45.3525"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M66.6153 43.4749L66.2458 45.0626"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M68.1222 36.9857L66.8998 42.247"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M65.2697 36.1323L61.0287 43.0697"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M63.227 34.576L57.2331 39.3114"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <ellipse
              cx="76.9685"
              cy="30.6414"
              rx="3.49301"
              ry="3.49301"
              transform="rotate(-31.8963 76.9685 30.6414)"
              fill="white"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M46.7941 51.0087C53.3254 61.5031 70.1363 62.8434 84.3479 53.9987C98.5594 45.1541 104.78 29.4953 98.2417 18.99C97.8359 18.338 97.3854 17.7149 96.8935 17.125C96.8935 17.125 102.164 34.2659 80.3184 47.693C61.6067 59.2258 46.7941 51.0087 46.7941 51.0087Z"
              fill="#FFE2BF"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M70.2367 55.6367C63.3843 57.1485 54.254 57.3327 46.792 51.0254"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M75.5315 54.1043C74.6112 54.4333 73.5882 54.7661 72.4785 55.0742"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M91.9962 43.981L89.9023 42.5088"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M93.3435 41.7546L91.3096 40.8789"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M88.5971 44.0711L89.0783 44.8443"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M46.7948 51.0097C46.7948 51.0097 48.7192 40.0608 68.1402 27.9741C83.1222 18.65 93.6324 17.3145 96.8976 17.1314C89.6208 8.37984 74.0121 7.72401 60.6953 16.0117C46.4838 24.8564 40.2702 40.526 46.7948 51.0097Z"
              fill="#FFE2BF"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M51.296 29.769C50.3929 31.0954 49.5872 32.4855 48.8852 33.9284"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M69.9821 15.643C65.1082 17.4836 58.1782 21.0465 52.8798 27.6406"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M96.9001 17.1364C96.9001 17.1364 87.9106 13.2799 74.5029 19.8428"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M46.669 40.3974C46.669 40.3974 46.7635 41.8388 48.6879 43.6414"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M47.1243 35.0258C47.1243 35.0258 47.2705 38.539 51.0596 41.349"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className={style.topRightBell}>
          <svg
            width="70"
            height="92"
            viewBox="0 0 70 92"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.402 21.6211C26.6066 20.9564 28.1381 20.7397 30.6639 20.9185C36.2337 21.3111 46.6402 23.6155 58.3107 27.2111C70.0437 30.8281 79.9955 34.791 84.7958 37.6126C86.93 38.8617 88.0429 39.8826 87.8417 40.5402C87.5609 41.4533 84.7941 41.5222 80.3902 40.8914C74.4905 40.0457 65.6505 37.9471 55.935 34.9554C46.2195 31.9637 37.7999 28.7532 32.4421 26.1386C28.3974 24.1655 26.1213 22.5377 26.402 21.6211Z"
              fill="#1D1E5F"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle
              cx="56.367"
              cy="34.0792"
              r="4.94333"
              transform="rotate(-136.61 56.367 34.0792)"
              fill="#FF81AF"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M53.2217 34.1035C53.2217 34.1035 53.0474 31.9986 55.3074 31.168"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M38.0203 81.3206L38.5836 79.4925C38.9779 79.6551 39.3929 79.7993 39.82 79.9308L40.8654 80.2539L40.4109 81.7299C40.2295 82.3244 40.5644 82.9535 41.1589 83.1349C41.7535 83.3163 42.3825 82.9813 42.5639 82.3868L43.0202 80.909L44.0922 81.2331C44.521 81.3663 44.9423 81.4785 45.361 81.5641L44.7995 83.3939C44.2269 85.2659 42.2452 86.3194 40.3732 85.7468C38.5012 85.1743 37.4478 83.1926 38.0203 81.3206Z"
              fill="#FF5986"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.3484 28.3175C30.1136 31.1187 41.1454 35.6924 54.5061 39.8039C67.8669 43.9154 79.5646 46.3411 84.2534 46.142C81.3196 55.7744 73.0607 62.3129 69.5611 65.1765C67.7014 66.7027 65.5406 68.7297 63.9076 70.315C63.2118 70.9876 62.5592 71.7035 61.9537 72.4585L59.0267 76.0943C57.9684 77.4322 56.7187 78.6068 55.3179 79.5803C52.2499 81.6665 48.9658 82.3664 45.3529 81.5769C44.9342 81.4913 44.5093 81.3757 44.0841 81.2459L39.8151 79.9293C39.388 79.7978 38.973 79.6536 38.5787 79.4911C35.1457 78.1066 32.8315 75.6881 31.4662 72.2403C30.8556 70.6476 30.4826 68.9737 30.3593 67.2724L29.9823 62.6187C29.908 61.6517 29.7715 60.6905 29.5737 59.741C29.1159 57.5094 28.4696 54.6207 27.7901 52.3138C26.508 47.9833 23.3574 37.9303 26.3484 28.3175Z"
              fill="#FFE483"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.4042 21.6231C26.1217 22.538 28.3994 24.1639 32.4352 26.1319C37.7929 28.7465 46.2588 31.9629 55.928 34.9488C65.5973 37.9346 74.4836 40.0391 80.3833 40.8847C84.7853 41.5138 87.5471 41.4539 87.8348 40.5335L86.4125 45.3919C86.2729 45.8457 85.5162 46.0884 84.2529 46.1417C79.5641 46.3408 67.8578 43.9241 54.5056 39.8036C41.1535 35.683 30.113 31.1183 26.3479 28.3172C25.3365 27.5644 24.844 26.9334 24.9836 26.4796L26.4042 21.6231Z"
              fill="#FFBA68"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M37.1351 75.0241C35.6149 73.88 34.5648 72.1912 33.9426 69.9696L37.1351 75.0241Z"
              fill="#FFE483"
            />
            <path
              d="M37.1351 75.0241C35.6149 73.88 34.5648 72.1912 33.9426 69.9696"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M67.4466 46.6365C70.4132 47.4352 73.1515 48.0527 75.4765 48.4585"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M63.0126 45.346C63.8967 45.6258 64.7619 45.8844 65.6083 46.1216"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M43.7227 53.5947L40.999 46.3782"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M41.0518 52.3682L39.4744 48.1823"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M45.6123 52.752L43.2675 46.5345"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M38.8355 52.6037L45.9577 47.5958"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M41.9052 52.9722L45.9005 50.1602"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M38.3364 50.4296L42.8199 47.2762"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M51.5645 64.6046C51.999 64.8741 53.7659 65.7827 55.8691 64.349C55.8776 64.3446 55.888 64.346 55.8949 64.3526C55.9019 64.3592 55.9039 64.3695 55.9 64.3782C54.5885 66.5603 55.5913 68.2699 55.8866 68.6903C55.891 68.6988 55.8896 68.7092 55.883 68.7162C55.8764 68.7231 55.8661 68.7252 55.8574 68.7212C55.4211 68.4536 53.6578 67.5448 51.5511 68.9787C51.5426 68.9831 51.5322 68.9817 51.5252 68.9751C51.5183 68.9685 51.5162 68.9582 51.5202 68.9495C52.8352 66.7673 51.8288 65.0578 51.5353 64.6356C51.5309 64.627 51.5323 64.6167 51.5389 64.6097C51.5455 64.6027 51.5558 64.6007 51.5645 64.6046Z"
              fill="white"
              stroke="#0D052C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className={style.bottomMarker}>
          <img src={location} alt="" />
        </div>
      </div>
      <div className={style.header}>
        <div className={style.title}>
          <h2>Privacy Policy</h2>
          <h3>last updated - 2020-07-09</h3>
        </div>
        {/* <div className={style.lang}>
                    <div className={`${style.optionsContainer} ${isActive ? style.active : ''}`}>
                        <div className={style.option}>
                            <input type="radio" id="english" name="lang" />
                            <label htmlFor="english">English</label>
                        </div>
                        <div className={style.option}>
                            <input type="radio" id="mandarinChinese" name="lang" />
                            <label htmlFor="mandarinChinese">Mandarin Chinese</label>
                        </div>
                        <div className={style.option}>
                            <input type="radio" id="hindi" name="lang" />
                            <label htmlFor="hindi">Hindi</label>
                        </div>
                        <div className={style.option}>
                            <input type="radio" id="spanish" name="lang" />
                            <label htmlFor="spanish">Spanish</label>
                        </div>
                        <div className={style.option}>
                            <input type="radio" id="arabic" name="lang" />
                            <label htmlFor="arabic">Arabic</label>
                        </div>
                        <div className={style.option}>
                            <input type="radio" id="bengali" name="lang" />
                            <label htmlFor="bengali">Bengali</label>
                        </div>
                        <div className={style.option}>
                            <input type="radio" id="french" name="lang" />
                            <label htmlFor="french">French</label>
                        </div>
                        <div className={style.option}>
                            <input type="radio" id="russian" name="lang" />
                            <label htmlFor="russian">Russian</label>
                        </div>
                        <div className={style.option}>
                            <input type="radio" id="portugese" name="lang" />
                            <label htmlFor="portugese">Portugese</label>
                        </div>
                        <div className={style.option}>
                            <input type="radio" id="french" name="lang" />
                            <label htmlFor="french">French</label>
                        </div>
                    </div>
                    <div className={style.selected} onClick={showMenu}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.58133 0 0 3.582 0 8C0 12.418 3.58133 16 8 16C12.418 16 16 12.418 16 8C16 3.582 12.418 0 8 0ZM8.09333 12.6787C5.51467 12.6787 3.42667 10.5853 3.42667 8C3.42667 5.41467 5.51467 3.32133 8.09333 3.32133C9.35333 3.32133 10.4067 3.786 11.2147 4.54067L9.89867 5.85933V5.85667C9.40867 5.38867 8.78733 5.14867 8.09333 5.14867C6.55333 5.14867 5.302 6.45267 5.302 7.99733C5.302 9.54067 6.55333 10.8487 8.09333 10.8487C9.49067 10.8487 10.4413 10.0473 10.6373 8.94733H8.09333V7.12267H12.4833C12.542 7.436 12.5733 7.76267 12.5733 8.10533C12.5733 10.7787 10.7887 12.6787 8.09333 12.6787Z" fill="white" />
                        </svg>
                        <span>{selectedLang}</span>
                        <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.68435 0.218094C7.54383 0.0784061 7.35374 0 7.1556 0C6.95746 0 6.76737 0.0784061 6.62685 0.218094L3.93435 2.87309L1.27935 0.218094C1.13883 0.0784061 0.948738 0 0.750599 0C0.552459 0 0.362371 0.0784061 0.221849 0.218094C0.151552 0.287817 0.0957565 0.370767 0.05768 0.462162C0.0196035 0.553556 0 0.651586 0 0.750594C0 0.849603 0.0196035 0.947633 0.05768 1.03903C0.0957565 1.13042 0.151552 1.21337 0.221849 1.28309L3.40185 4.46309C3.47157 4.53339 3.55452 4.58919 3.64592 4.62726C3.73731 4.66534 3.83534 4.68494 3.93435 4.68494C4.03336 4.68494 4.13139 4.66534 4.22278 4.62726C4.31418 4.58919 4.39713 4.53339 4.46685 4.46309L7.68435 1.28309C7.75464 1.21337 7.81044 1.13042 7.84852 1.03903C7.88659 0.947633 7.9062 0.849603 7.9062 0.750594C7.9062 0.651586 7.88659 0.553556 7.84852 0.462162C7.81044 0.370767 7.75464 0.287817 7.68435 0.218094Z" fill="#E94560" />
                        </svg>
                    </div>
                </div> */}
      </div>
      <div className={style.info}>
        <div className={style.single}>
          <h3>1 / Introduction</h3>
          <p>
            Kef.gg have designed this site in compliance with data protection
            regulations to assure you, the user, can use our services knowing
            the integrity of your personal information is secure. This privacy
            policy describes the steps we take to maintain the integrity of your
            information and disclose both how and why we collect your
            information. By continuing to use our services, you are consenting
            to the collection and storage of your data as described in this
            privacy policy.
          </p>
        </div>
        <div className={style.single}>
          <h3>2 / Data Collection</h3>
          <p>
            When using our services, you may be required to share personal
            information about yourself, such as your email address, full name,
            home address etc. This information is collected for the sole purpose
            of providing you with suitable surveys and offers. A particular
            survey may have a determined audience required, so this information
            helps filter out irrelevant surveys which you would not have
            qualified for. We will never knowingly collect personal information
            from anyone under 13 years of age. <br />
            We also collect non-personal information, including your IP address,
            browser type and cookies. This information allows us to provide you
            with a better experience and allows us to minimize fraud on our
            services.
          </p>
        </div>
        <div className={style.single}>
          <h3>3 / Cookies and Local Storage</h3>
          <p>
            This site uses Cookies to identify your computer and allow us to
            associate requests from your computer with the relevant user. Local
            Storage provides useful functionality, such as remembering user
            preferences from previous browsing sessions. <br />
            By using our services, you are agreeing with our use of Cookies and
            Local Storage outlined above. Cookies and Local Storage may be
            cleared or blocked at any time, however the functionality of our
            services will be severely limited if you choose to block them.
          </p>
        </div>
        <div className={style.single}>
          <h3>4 / Third Parties</h3>
          <p>
            The Site uses third-party partners such as offer walls and survey
            routers to provide you as a user with offers and surveys. The
            information that we share with these partners varies depending on
            the offer type. For regular ‘offers’, the only information we share
            is your unique ID generated by us to uniquely identify your account.
            For ‘surveys’, the only information we share is your unique ID,
            along with any information you have provided to us, which is passed
            to help the provider filter out irrelevant surveys. <br />
            Any third-party services integrated in our site may collect
            information from you on their own website as well, and they have
            separate and independent privacy policies. We bear no responsibility
            or liability for their content and activities. If you are
            dissatisfied or concerned about any of these third-party privacy
            policies, please contact us and we will be happy to investigate.
          </p>
        </div>
        <div className={style.single}>
          <h3>5 / Sharing of Information</h3>
          <p>
            Our policy is to not to share, sell, or rent your personal
            information to third parties for our own personal benefit, however
            there may be situations where we are required to share your personal
            details with third parties, such as relevant law enforcement
            agencies. If we believe that it is reasonably necessary to comply
            with law enforcement or other regulatory bodies, we may do so
            without your prior consent or notice. <br />
            We may also share your personal information if we believe it is
            necessary to investigate, prevent, or act regarding situations that
            pose a threat to the security, rights and assets of the company, or
            violations to our policies or terms of service. We may also share
            your personal information if necessary in cases of fraud, such as
            credit card fraud on offers. <br />
            The disclosure of sensitive information to relevant organizations or
            authorities is up to the sole discretion of the management of the
            company.
          </p>
        </div>
        <div className={style.single}>
          <h3>6 / Security</h3>
          <p>
            We take your data protection and sensitivity very seriously. We
            employ industry standard techniques to protect against unauthorized
            access of data about you that we store, including personal
            information. <br />
            The personal information we collect about you is stored in secure
            servers, hosted by industry-leading hosting providers. We also
            maintain many security measures to attempt to protect against the
            loss, misuse and alteration of the personal information within our
            control. <br />
            However, no method of transmission or electronic storage is 100%
            secure. Therefore, we cannot guarantee the security of such
            information or be responsible for any damage that results from a
            breach in security. Furthermore, you should be aware that the
            Internet and other various networking communication media are not
            entirely secure, and your information may therefore be subject to
            interception or loss which is beyond our reasonable control. In
            conclusion, while we strive to protect your personal information, we
            cannot ensure or warrant the security of any personal information
            you transmit to us, and any transmission is done at your own risk.
            Accordingly, we assume no responsibility or liability for disclosure
            of your personal information or other information due to errors in
            transmission, unauthorized third-party access, or other causes
            beyond our reasonable control.
          </p>
        </div>
        <div className={style.single}>
          <h3>7 / For European Residents</h3>
          <p>
            If you are located in Europe, please be advised of the following
            required by the General Data Protection Regulation: The Company may
            provide the Service to Users located in Europe. <br />
            In its interaction with European Users of the Service, the Company
            is a data controller such that we are the organization which decides
            the purposes for which and the ways in which any personal data that
            we have collected is used. <br />
            You agree that we are entitled to obtain, use and process the
            information you provide to us to enable us to discharge the
            services. <br />
            Further, you agree that we are allowed to store your data on servers
            located around the world. <br />
            Our lawful reason for processing your personal information will be
            "legitimate interests". Under "legitimate interests" we can process
            your personal information if we have a genuine and legitimate reason
            and we are not harming any of your rights and interests. <br />
            Pursuant to applicable law, Kef.gg may disclose or transfer personal
            data and other information collected through the
            Application/Services to third parties. <br />
            We will process your persona data in a lawful, fair and transparent
            manner on only for the legitimate purpose of being able to provide
            the service. <br />
            We will only request, process and maintain personal information to
            the extent necessary to be able to provide the Service. We will keep
            in such form that, and only for the duration that, permits
            identification of data subjects for no longer than necessary. <br />
            You may withdraw consent at any time and request that your personal
            information be erased. <br />
            You may request a copy of all personal information maintained about
            you and request that it be rectified in the event any information is
            incorrect. <br />
            The Company maintains all records in their entirety until and unless
            the User requests account deletion, at which time we will delete all
            personal information without undue delay. If you request that we
            erase your personal information, we will also be required to delete
            your account and you will not be eligible to use the service unless
            you open another account.
          </p>
        </div>
        <div className={style.single}>
          <h3>8 / For Californian Residents</h3>
          <p>
            The California Code of Regulations defines a "resident" as: <br />
            Every individual who is in the State of California for other than a
            temporary or transitory purpose and every individual who is
            domiciled in the State of California who is outside the State of
            California for a temporary or transitory purpose. <br />
            All other individuals are defined as non-residents. If this
            definition of "resident" applies to you, we must adhere to certain
            rights and obligations regarding your personal information. To the
            extent those rights apply to you, they are described below. The
            Company also handles certain personal information on behalf of
            third-parties and/or partners of the Company. You should contact
            these third-parties and/or partners to exercise any rights you may
            have with respect to that personal information. <br />
            Pursuant to the California Consumer Protection Act of 2018 (“CCPA”),
            and subject to certain exceptions and limitations, residents of
            California are able to contact the Company to exercise the rights
            described below with respect to certain personal information that
            the Company holds about them. <br />
            You are entitled to receive the following: <br />
            Whether we collect and use your personal information The business
            purpose for collecting personal information <br />
            <br />
            The categories of your personal information that the Company has
            collected in the preceding 12 months <br />
            The categories of sources from which that information was collected{" "}
            <br />
            The categories of personal information that we disclosed for a
            business purpose <br />
            The categories of third parties to whom the personal information was
            disclosed for a business purpose <br />
            The specific pieces of personal information the Company has
            collected about you. <br />
            You also hold the entitlement to request the deletion of your
            personal information, just like any other user of the Site. If you
            ask us to delete your personal information, we will respect your
            request and delete your personal information, subject to certain
            exceptions provided by law, such as (but not limited to) the
            exercise by another user of his or her right to free speech, our
            compliance requirements resulting from a legal obligation or any
            processing that may be required to protect against illegal
            activities. Furthermore, residents of California are also subject to
            the following other privacy rights: <br /> <br />
            We will not discriminate against if you exercise your privacy
            rights. <br />
            You may object to the processing of your personal data You may
            request correction of your personal data if it is incorrect or no
            longer relevant, or ask to restrict the processing of the data.{" "}
            <br />
            You can designate an authorized agent to make a request under the
            CCPA on your behalf. <br />
            If you are a California resident and would like to make such a
            request to exercise any of these rights, please submit your request
            in writing to us using the contact information provided in Section
            (11). <br />
          </p>
        </div>
        <div className={style.single}>
          <h3>9 / Right to Withdraw Consent</h3>
          <p>
            You have the right to withdraw your consent at any time or to access
            and request that we rectify or remove your personal data from our
            system(s). You may exercise this right by submitting a written
            request to the contact information provided in Section (11).
          </p>
        </div>
        <div className={style.single}>
          <h3>10 / Changes to this Privacy Policy</h3>
          <p>
            We might update this Privacy Policy from time to time, with or
            without prior notice, so you should bookmark this page and check it
            regularly. We will inform you by changing the date on this page
            noting when the policy was last updated. Continued use of the Site
            is used as your consent.
          </p>
        </div>
        <div className={style.single}>
          <h3>11 / Contact</h3>
          <p>
            If you have any more questions in regards to our privacy policy,
            please contact us at admin@kef.gg If you contact us to exercise your
            privacy rights, for your account's protection we might request proof
            of your identity.
          </p>
        </div>
      </div>
    </div>
  );
}
