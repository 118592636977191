import { combineReducers } from "redux";

import lastEarningsReducer from "./lastEarnings";
import campaignsReducer from "./campaigns";
import featuredOffersReducer from "./featuredOffers";
import authReducer from "./auth";
import updateUser from "./username";
import updateTracker from "./tracker";
import updateChat from "./chatActive";
import offerClicked from "./offerClicked";
import temporary from "./temporary";
import userBalance from "./userBalance";

export default combineReducers({
  updateUser,
  updateTracker,
  offerClicked,
  lastEarningsReducer,
  campaignsReducer,
  featuredOffersReducer,
  authReducer,
  updateChat,
  temporary,
  userBalance,
});
