import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import API from "../../api/";

import Select from "../../components/Select";
import cactus from "../../assets/OfferWall/cactus.svg";
import moment from "moment";

import style from "./style.module.scss";

export default function EarningsTab() {
  const history = useHistory();
  const [active, setActive] = useState("earnings");
  const [filter, setFilter] = useState("offers");
  const [selected, setSelected] = useState("");
  const [data, setData] = useState([]);
  const [monthFilterd, setMonthFilterd] = useState([]);
  const [isShown, setIsShown] = useState(-1);

  const Client = API.getInstance();
  useEffect(async () => {
    const response = await Client.getProfileEarnings();
    setData(response.data);
    setMonthFilterd(response.data);
  }, []);

  useEffect(() => {
    setMonthFilterd([]);
    data &&
      data.map((d) => {
        var date = moment(d.date);
        if (selected != -1) {
          if (date.month() == selected) {
            console.log(true);
            setMonthFilterd((oldArray) => [...oldArray, d]);
          }
        } else {
          setMonthFilterd(data);
        }
      });
    console.log(monthFilterd);
  }, [selected]);

  return (
    <div className={style.container}>
      <div className={style.tabContainer}>
        <button
          className={`${style.tab} ${
            active === "earnings" ? style.active : ""
          }`}
          onClick={() => setActive("earnings")}
        >
          Earnings
        </button>
        <button
          className={`${style.tab} ${active === "offers" ? style.active : ""}`}
          onClick={() => setActive("offers")}
        >
          Hold offers
        </button>
        <button
          className={`${style.tab} ${
            active === "chargebacks" ? style.active : ""
          }`}
          onClick={() => setActive("chargebacks")}
        >
          Chargebacks
        </button>
      </div>
      <div className={style.buttonsContainer}>
        <button
          className={` button ${filter === "offers" ? "button-secondary" : ""}`}
          onClick={() => setFilter("offers")}
        >
          Offers
        </button>
        <button
          className={`button ${filter === "videos" ? "button-secondary" : ""}`}
          onClick={() => setFilter("videos")}
        >
          Videos
        </button>
      </div>
      <div className={style.inputContainer}>
        <Select
          placeholder="Offer walls"
          value={selected}
          setValue={setSelected}
          options={[
            { value: "-1", label: "All" },
            { value: "0", label: "January" },
            { value: "1", label: "February" },
            { value: "2", label: "March" },
            { value: "3", label: "April" },
            { value: "4", label: "May" },
            { value: "5", label: "June" },
            { value: "6", label: "July" },
            { value: "7", label: "August" },
            { value: "8", label: "September" },
            { value: "9", label: "October" },
            { value: "10", label: "November" },
            { value: "11", label: "December" },
          ]}
        />
      </div>
      <div className={style.tableContainer}>
        <div className={style.tableHead}>
          <p className={style.label}>Name</p>
          <p className={style.label}>Coins</p>
          <p className={style.label}>Offer Name </p>
          <p className={style.label}>Wall</p>
          <p className={style.label}>Date</p>
        </div>
        <div className={style.tableContent}>
          {monthFilterd != 0 ? (
            monthFilterd
              //.filter((month) => moment().month(month.date) == selected)
              .sort(function (a, b) {
                return new Date(a.date) - new Date(b.date);
              })
              .map((earning, index) => {
                const dateTimeAgo = moment(earning.date).fromNow();
                return (
                  <div className={style.tableItem} key={index}>
                    <p className={style.value}>{earning.type}</p>
                    <p className={style.value}>
                      {earning.amount}
                      <img
                        src="/assets/svgs/coin.svg"
                        alt="coin"
                        className={style.icon}
                      />
                    </p>
                    <p className={style.value}>
                      {earning.offer_name != "" ? earning.offer_name : "None"}
                    </p>
                    <p className={style.value}>{earning.source}</p>
                    <p className={style.value}>{dateTimeAgo}</p>
                  </div>
                );
              })
          ) : (
            <div className={style.noEarningsContainer}>
              <div className={style.imageContainer}>
                <img src={cactus} alt="Image" className={style.cactus} />
              </div>
              <p
                className={style.countryDescription}
                onClick={() => {
                  history.push("/earn");
                }}
              >
                {selected != -1 && selected != ""
                  ? moment().month(selected).format("MMMM") +
                    " wasn't so hot, click the link to start earning"
                  : " You have never earned coins, go to Earn page and start earning!"}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className={style.earningTable}>
        <div className={style.tableWrapper}>
          <table className={style.desktopTable}>
            <tr className={style.tableTitles}>
              <th className={style.label}>Name</th>
              <th className={style.label}>Coins</th>
              <th className={style.label}>Offer Name </th>
              <th className={style.label}>Wall</th>
              <th className={style.label}>Date</th>
            </tr>
            {monthFilterd != 0 ? (
              monthFilterd.reverse().map((earning, index) => {
                const dateTimeAgo = moment(earning.date).fromNow();
                return (
                  <tr className={style.tableTitles} key={index}>
                    <th className={style.value}>{earning.type}</th>
                    <th className={style.value}>
                      {earning.amount}
                      <img
                        src="/assets/svgs/coin.svg"
                        alt="coin"
                        className={style.icon}
                      />
                    </th>
                    <th className={style.value}>
                      {earning.offer_name != "" ? earning.offer_name : "None"}
                    </th>
                    <th className={style.value}>{earning.source}</th>
                    <th
                      className={style.value}
                      onMouseEnter={() => setIsShown(index)}
                      onMouseLeave={() => setIsShown(-1)}
                    >
                      {dateTimeAgo}

                      {isShown == index ? (
                        <div className={style.currentDate}>{earning.date}</div>
                      ) : null}
                    </th>
                  </tr>
                );
              })
            ) : (
              <div className={style.noEarningsContainer}>
                <div className={style.imageContainer}>
                  <img src={cactus} alt="Image" className={style.cactus} />
                </div>
                <p
                  className={style.countryDescription}
                  onClick={() => {
                    history.push("/earn");
                  }}
                >
                  {selected != -1 && selected != ""
                    ? moment().month(selected).format("MMMM") +
                      " wasn't so hot, click the link to start earning"
                    : " You have never earned coins, Please to Earn page and start earning!"}
                </p>
              </div>
            )}
          </table>
        </div>
      </div>
    </div>
  );
}
