import { UPDATE_USERNAME } from "../actions/username";

export default (prevState = { username: "" }, action) => {
  switch (action.type) {
    case UPDATE_USERNAME:
      return { ...prevState, username: action.username };
    default:
      return prevState;
  }
};
