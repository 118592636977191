import React from "react";

import style from "./style.module.scss";

import Eyes from "../../assets/BlogArticle/eyes.svg";
import Brain from "../../assets/BlogArticle/brain.svg";
import mainBlog from "./blog11.png";

export default function BlogArticle11() {
  return (
    <div className={style.backgroundContainer}>
      <div className={style.dateContainer}>
        <p className={style.timeDate}>04/06/2023</p>
        <p className={style.timeline}>2 Minutes Read</p>
      </div>
      <div className={style.title}>
        The Ultimate Guide to Building Your Kingdom in Dice Dreams / By Don E
        lemon.
      </div>
      <div className={style.mainPicContainer}>
        <img src={mainBlog} alt="" className={style.blogPhoto} />
        <img src={Eyes} alt="" className={style.eyes} />
        <img src={Brain} alt="" className={style.brain} />
      </div>
      <div className={style.subContent}>
        <p>
          Get ready to level up and score some epic rewards in Dice Dreams!
          Building your kingdom is the key, and we've got the complete guide
          right here.
        </p>
      </div>
      <div className={style.subContent}>
        <p>
          How to Build a Kingdom in Dice Dreams? It's simple, just follow these
          steps:
        </p>
        <div className={style.subtitle}></div>
        <p>
          1. Tap the hammer icon - Look for that hammer icon chilling in the
          bottom right corner of your screen. Tap it like a boss to open up the
          Kingdom screen.
        </p>
        <div className={style.subtitle}></div>
        <p>
          2.Build or upgrade buildings - To create a kickass kingdom, you gotta
          construct 6 different buildings. Each building has 5 upgrade levels,
          so that's a total of 30 levels to conquer!
        </p>

        <div className={style.subtitle}></div>
        <p>
          To upgrade a building, just tap on the building coin button. And check
          this out, when you complete the first level of a building, a peon will
          come out and hand you a sweet gift. Plus, you'll get a shiny crown
          added to your inventory. Nice!
        </p>
        <div className={style.subtitle}>
          Oh, and when you reach the fifth level of a building, you'll unlock
          some extra goodies. It could be rolls, it could be coins. Either way,
          it's a win!
        </div>
        <p>
          1. Finish all buildings - To become the ruler of your own kingdom, you
          gotta finish constructing all 6 buildings. Once that's done, you're
          ready to move on to the next level kingdom. Boom!
        </p>
        <div className={style.subtitle}>
          That's it, my friend! We hope this guide helps you conquer levels,
          rack up those free rolls and coins, and dominate Dice Dreams. But
          wait, there's more!
        </div>
        <p>
          Don't forget to check out the Dice Dreams free rolls and coins post
          for even more awesome ways to score those precious rewards.
        </p>
      </div>
    </div>
  );
}
