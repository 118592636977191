import React from "react";

import { useRef } from "react";
import style from "./style.module.scss";

import Eyes from "../../assets/BlogArticle/eyes.svg";
import Image from "../../assets/BlogArticle/Rectangle.png";
import Brain from "../../assets/BlogArticle/brain.svg";
import blogmain from "../../assets/BlogMain/blogimage3.jpg";

export default function BlogArticle3() {
  return (
    <div className={style.backgroundContainer}>
      <div className={style.dateContainer}>
        <p className={style.timeDate}>23/08/2022</p>
        <p className={style.timeline}>4 Minutes Read</p>
      </div>
      <div className={style.title}>Games to earn crypto with Introduction</div>
      <div className={style.mainPicContainer}>
        <img src={blogmain} alt="" className={style.blogPhoto} />
        <img src={Eyes} alt="" className={style.eyes} />
        <img src={Brain} alt="" className={style.brain} />
      </div>
      <div className={style.subContent}>
        <p>
          Lately, I've been trying to earn some crypto by playing games. It's
          not easy, and most of them require a lot of time and effort, but here
          are some that are worth your time:
        </p>
        <div className={style.subtitle}>Kef.gg</div>
        <p>
          Kef.gg is a free-to-play platform that offers you the chance to earn
          cryptocurrency while discovering new digital products. The more you
          play, the more you win crypto. And while this game may peculiar
          —because it's design and all—it's not! You can actually earn instantly
          cryptocurrency for completing games and surveys on your phone, tablet
          or PC. To start earning, just open up Kef.gg and go to the earn page,
          click on one of the offer-walls for them to present the offers for you
          to choose from. Then once you've chosen your favorite game or pay-out,
          go ahead and try it out. The more you play, the more you earn! And
          once you've completed a game or survey, Kef.gg will automatically
          allow you to withdraw your earnings
        </p>
        <div className={style.subtitle}>CryptoZombies</div>
        <p>
          CryptoZombies is a game that allows you to learn how to code Ethereum
          dapps, as well as build your own. The game is simple: you need only
          press a button in order for the zombies on screen to be killed by
          their own attacks. This works because there are no real-life
          consequences for pressing wrong buttons or not pressing at all; all
          that matters is whether or not your code runs correctly when run
          through the blockchain's nodes (which happen on every computer
          connected). Once you've learned how this works, head over here and
          create an account!
        </p>
        <div className={style.subtitle}>Splinterlands</div>
        <p>
          The game is a strategy game where you play against others in order to
          earn crypto. You can play for free, but if you want more of a
          challenge and a higher chance of winning battles, then staking your
          crypto may be the way for you. This will give players an opportunity
          to get more coins from the game when they win battles or trade with
          other players. There are also many ways that players can earn crypto
          by mining their own cryptocurrency through Splinterlands’ blockchain
          system. If this sounds like something that interests you, then read
          on!
        </p>
        <div className={style.subtitle}>Dapp University</div>
        <p>
          Dapp University is a free online course that teaches you how to build
          decentralized applications on Ethereum. It's also a great place to
          start if you're new to dapps, or even crypto in general. The course
          focuses on building and deploying games using the Truffle Framework,
          an open-source toolkit for developing smart contracts and applications
          with JavaScript code. The first part of the course covers getting
          started with Truffle and deploying your first game onto the Rinkeby
          testnet; this gives students enough knowledge about how Ethereum works
          so that they can continue learning as they progress through subsequent
          lessons.
        </p>
        <div className={style.subtitle}>KITT.AI</div>
        <p>
          KITT.AI is a game that teaches you how to build a chatbot. You can
          earn crypto for completing lessons, quests, challenges and
          achievements. The game is available on iOS and Android devices, so
          it's easy to get started if you've got an app on your phone or tablet!
        </p>
        <div className={style.subtitle}>
          Earn crypto for free when you play these games
        </div>
        <p>
          If you're looking for a way to earn cryptocurrency without investing
          in it, you can find plenty of options. Some of these are fun and
          educational, while others are simply meant to get your hands on some
          free crypto so that you can play the games and learn about
          cryptocurrency at the same time. Here's how each of them works:
          <ul>
            <li>
              CryptoKitties: This is one of the most popular games out there
              right now. In this game, players collect different breeds of cats
              (or "kitties"), which they use to breed together or sell on an
              auction site called CryptoKitties Marketplace. The game uses
              blockchain technology so that owners can keep track of their pets'
              details—their names/breeds; where they live; when they were born;
              etc.—and even see how many kitty cards other people own!
            </li>
            <li>
              NFTs: Non-fungible tokens (NFTs) allow users to create unique
              digital assets out of any type object using blockchain technology
              like Bitcoin Cash (BCH). For example, if someone wanted a digital
              version
            </li>
          </ul>
        </p>
        <div className={style.subtitle}>Conclusion</div>
        <p>
          So there you have it, five games to earn crypto when you play them. I
          hope this article helped you find some new ways to spend your time and
          money on the blockchain!
        </p>
      </div>
    </div>
  );
}
