import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import style from "./style.module.scss";
import bePatient from "../../assets/Footer/be-patient.svg";
import discordLogo from "../../assets/Footer/discordLogo.svg";
import steamLogo from "../../assets/Footer/steamLogo.svg";
import twitterLogo from "../../assets/Footer/twitterLogo.svg";
import sms from "../../assets/Footer/sms.svg";
import EventGA from "../../components/EventGA";
import Draggable from "react-draggable";

export default function Footer(props) {
  return (
    <footer style={{ backgroundColor: props.bcg }}>
      <div className={style.bcgSvg}>
        <div className={style.bottomLeftFigure}>
          <img src={bePatient} alt="Bottom image" />
        </div>
      </div>
      <div className={style.rightsContainer}>
        <h4 className={style.year}>&copy; 2023 Kef.gg</h4>
        <h4 className={style.year}>All Right Reserved Kef.gg</h4>
      </div>
      <div className={style.footerLinks}>
        <div className={style.news}>
          <h1 className={style.fomo}>DON'T GET FOMO</h1>
          <h3 className={style.subFomo}>
            We'll drop the best new contests in your inbox every week.
          </h3>
          {/* <div className={style.emailContainer}>
            <img src={sms} alt="Icon" />
            <input
              className={style.emailInput}
              type="email"
              id="search"
              name="search"
              pattern="[^ @]*@[^ @]*"
              placeholder="name@example.com"
            />
            <button className={style.newsLetter}>Join</button>
          </div> */}
          <iframe
            src="https://embeds.beehiiv.com/957850ca-27b2-4427-802a-12f455594924?slim=true"
            height="52"
            frameborder="0"
            scrolling="no"
          ></iframe>
        </div>
        <Draggable>
          <div
            className={style.fingerLeft}
            onClick={() => {
              <EventGA
                category={"Finger"}
                action={"LEFT Finger Figured Out!"}
              />;
            }}
          ></div>
        </Draggable>
        <Draggable>
          <div
            className={style.fingerRight}
            onClick={() => {
              <EventGA
                category={"Finger"}
                action={"RIGHT Finger Figured Out!"}
              />;
            }}
          ></div>
        </Draggable>

        <Draggable>
          <div
            className={style.smileFace}
            onClick={() => {
              <EventGA
                category={"Smile Face"}
                action={"Smile Face Figured Out!"}
              />;
            }}
          ></div>
        </Draggable>

        <div className={(style.links, style.socialLinks)}>
          <h3>Social</h3>
          <div className={style.social__links}>
            <a
              href="https://twitter.com/kefgg1"
              target="_blank"
              onClick={() => {
                <EventGA
                  category={"ButtonClick"}
                  action={"(Button) Twitter"}
                />;
              }}
            >
              <img src={twitterLogo} alt="Twitter Logo" />
            </a>

            <a
              href="https://discord.gg/Kd37dkr2a9"
              target="_blank"
              onClick={() => {
                <EventGA
                  category={"ButtonClick"}
                  action={"(Button) Discord"}
                />;
              }}
            >
              <img src={discordLogo} alt="Discord Logo" />
            </a>
            <a
              href="https://steamcommunity.com/groups/OGkef"
              target="_blank"
              onClick={() => {
                <EventGA category={"ButtonClick"} action={"(Button) Steam"} />;
              }}
            >
              <img src={steamLogo} alt="Steam Logo" />
            </a>
          </div>
        </div>
        <div className={style.links}>
          <h3>Support</h3>
          <Link
            to="/faq"
            onClick={() => {
              <EventGA category={"ButtonClick"} action={"(Button) FAQ"} />;
            }}
          >
            <h3>FAQ</h3>
          </Link>
          <Link
            to="/contact"
            onClick={() => {
              <EventGA category={"ButtonClick"} action={"(Button) Contact"} />;
            }}
          >
            <h3>Contact</h3>
          </Link>
          {/* <Link to="/paypal-guides">
            <h3>Paypal Guides</h3>
          </Link>
          <Link to="/earning-guide">
            <h3>Earning Guide</h3>
          </Link> */}
        </div>
        <div className={style.links}>
          <h3>About</h3>
          <Link
            to="/terms-of-service"
            onClick={() => {
              <EventGA
                category={"ButtonClick"}
                action={"(Button) Terms of service"}
              />;
            }}
          >
            <h3>Terms Of Service</h3>
          </Link>
          <Link
            to="/privacy-policy"
            onClick={() => {
              <EventGA
                category={"ButtonClick"}
                action={"(Button) Privacy policy"}
              />;
            }}
          >
            <h3>Privacy Policy</h3>
          </Link>
          <Link
            to="/blog"
            onClick={() => {
              <EventGA
                category={"ButtonClick"}
                action={"(Button) Blogs Footer"}
              />;
            }}
          >
            <h3>Blogs</h3>
          </Link>
        </div>
        <p className={style.kefAmmount}>$1 = 1000Kcoins</p>
      </div>
    </footer>
  );
}
