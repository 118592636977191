/* eslint-disable */
// //WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =
  process.env.REACT_APP_STATUS === "PRODUCTION"
    ? {
        aws_cognito_region: "us-east-1",
        aws_user_pools_id: "us-east-1_3sjq9SNJC",
        aws_user_pools_web_client_id: "5rq9bbut1vm40edrcv0q1ikebl",
        federationTarget: "COGNITO_USER_POOLS",
        oauth: {
          domain: "auth.kef.gg",
          scope: [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin",
          ],
          redirectSignIn: "https://kef.gg/handler/",
          redirectSignOut: "https://kef.gg/",
          responseType: "code",
        },
      }
    : {
        aws_cognito_region: "us-east-1",
        aws_user_pools_id: "us-east-1_JtCSt1F0c",
        aws_user_pools_web_client_id: "4k2ec1940465uki30rp8c53st8",
        federationTarget: "COGNITO_USER_POOLS",
        oauth: {
          domain: "auth.staging.kef.gg",
          scope: [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin",
          ],
          redirectSignIn: "https://staging.kef.gg/handler/",
          redirectSignOut: "https://staging.kef.gg/",
          responseType: "code",
        },
      };

export default awsmobile;
/*  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:5850847e-5ae9-4783-b185-850f4b3174f1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_as6amu2yw",
  aws_user_pools_web_client_id: "22ku06ehefpgiptj5no7mlmjta",
  federationTarget: ["COGNITO_USER_POOLS"],
  oauth: {
    domain: "kefgoogle.auth.us-east-1.amazoncognito.com",
    scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: "http://localhost:3000",
    redirectSignOut: "http://localhost:3000",
    clientId: "22ku06ehefpgiptj5no7mlmjta",
    responseType: "token",
  },
  aws_cognito_login_mechanisms: ["PREFERRED_USERNAME"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
*/
