import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CloudLeft from "../../../../assets/Withdraw/cloud-left.png";
import CloudRight from "../../../../assets/Withdraw/cloud-right.png";
import CloudBot from "../../../../assets/Withdraw/cloud-bot.png";
import Dots3 from "../../../../assets/Dots/Dots3.png";
import Dots7 from "../../../../assets/Dots/Dots7.png";
import Dots15 from "../../../../assets/Dots/Dots15.png";
import allocationsData from "./allocations.json";
import axios from "axios";
import style from "./style.module.scss";
import { store, persistor } from "../../../../store";

import Select from "../../../../components/Select";
import Input from "../../../../components/Input";
import ProgresBar from "../../../../components/ProgresBar";
import { Link, useLocation } from "react-router-dom";
import API from "../../../../api";
import { useSelector } from "react-redux";
import { updateUserBalance } from "../../../../store/actions/userBalance";

import tree from "../../../../assets/Dots/tree.png";
import co2 from "../../../../assets/Dots/co2.png";
import turtle from "../../../../assets/Dots/turtle.png";

export default function Dots() {
  const router = useLocation();
  const wrapperRef = useRef(null);
  const routerHistory = useHistory();
  const logedIn = useSelector((state) => state.authReducer.authenticated);
  const MINIMUM_ORDER = 3;
  const [trees, setTrees] = useState(MINIMUM_ORDER);
  const [message, setMessage] = useState("");
  const [coins, setCoins] = useState(0);
  const [allocations, setAllocations] = useState([]);
  const [selected, setSelected] = useState("");
  const Client = API.getInstance();

  useEffect(async () => {
    if (logedIn) {
      const response = await Client.getUserBalance();
      console.log(response.data);
      setCoins(response.data);
      const allocationsData = await Client.getUserDotsAllocations();
      setAllocations(allocationsData.data);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      routerHistory.push(router.pathname);
    }
  };

  const maxQuantity = () => {
    return Math.floor(coins / 1000);
  };

  const maximumQuantity = maxQuantity();
  const percentageAsNumber = () => {
    if (maximumQuantity - MINIMUM_ORDER === 0) {
      console.error("Maximum quantity must be greater than minimum quantity");
      return null; // Avoid division by 0
    }
    let percentage =
      ((trees - MINIMUM_ORDER) / (maximumQuantity - MINIMUM_ORDER)) * 100;
    percentage = Math.max(0, Math.min(100, percentage)); // Ensures the percentage is between 0 and 100
    return percentage;
  };

  async function handleSubmit(event) {
    event.preventDefault();
    if (!selected) {
      return setMessage("Please select first ");
    }
    if (coins <= 3000) {
      return setMessage("You should have at least 3000 Kef Coins to withdraw.");
    } else if (trees > maximumQuantity) {
      return setMessage(
        `You dont have enough coins to plant more than ${maximumQuantity} trees.`
      );
    } else if (trees < MINIMUM_ORDER) {
      return setMessage(`You must plant at least ${MINIMUM_ORDER} trees.`);
    } else {
      try {
        setMessage("Requesting Transfer...");
        const response = await Client.requestTreesWithdraw(selected, trees);

        if (response.data == true) {
          setMessage("Transfer Requested Sucessfully.");
          let coinsAmount = trees * 1000;
          setCoins(coins - coinsAmount);
          store.dispatch(updateUserBalance(true));

          routerHistory.push(`/withdraw?popup=tree-certeficates`);
        } else if (response.data == false) {
          setMessage("Transfer Failed. Please try again later.");
        }
      } catch (error) {
        setMessage("Transfer Failed. Please try again later.");
        console.log(error);
      }
    }
  }

  const reformatData = (data) => {
    return data.map((item) => ({
      value: item.allocation_id,
      label: item.display_name,
    }));
  };

  const findAllocationById = () => {
    return allocations.find(
      (allocation) => allocation.allocation_id === selected
    );
  };

  const reformattedData = reformatData(allocations);
  const selectedIdAllocation = findAllocationById();

  return (
    <div className={style.allcontent}>
      <div className={style.container}>
        <div className={style.background} ref={wrapperRef}>
          <form
            className={style.content}
            onSubmit={(event) => handleSubmit(event)}
            id="FORMID"
          >
            <Link className={style.closeButton} to={router.pathname}>
              <svg width="24" height="24" viewBox="0 0 20 20">
                <path
                  d="M11.1746 10.0002L16.4246 4.75849C16.5815 4.60157 16.6697 4.38874 16.6697 4.16682C16.6697 3.9449 16.5815 3.73207 16.4246 3.57515C16.2677 3.41823 16.0549 3.33008 15.833 3.33008C15.611 3.33008 15.3982 3.41823 15.2413 3.57515L9.99962 8.82515L4.75796 3.57515C4.60104 3.41823 4.38821 3.33008 4.16629 3.33008C3.94437 3.33008 3.73154 3.41823 3.57462 3.57515C3.4177 3.73207 3.32955 3.9449 3.32955 4.16682C3.32955 4.38874 3.4177 4.60157 3.57462 4.75849L8.82462 10.0002L3.57462 15.2418C3.49652 15.3193 3.43452 15.4115 3.39221 15.513C3.34991 15.6146 3.32812 15.7235 3.32812 15.8335C3.32812 15.9435 3.34991 16.0524 3.39221 16.154C3.43452 16.2555 3.49652 16.3477 3.57462 16.4252C3.65209 16.5033 3.74426 16.5653 3.84581 16.6076C3.94736 16.6499 4.05628 16.6717 4.16629 16.6717C4.2763 16.6717 4.38522 16.6499 4.48677 16.6076C4.58832 16.5653 4.68049 16.5033 4.75796 16.4252L9.99962 11.1752L15.2413 16.4252C15.3188 16.5033 15.4109 16.5653 15.5125 16.6076C15.614 16.6499 15.7229 16.6717 15.833 16.6717C15.943 16.6717 16.0519 16.6499 16.1534 16.6076C16.255 16.5653 16.3472 16.5033 16.4246 16.4252C16.5027 16.3477 16.5647 16.2555 16.607 16.154C16.6493 16.0524 16.6711 15.9435 16.6711 15.8335C16.6711 15.7235 16.6493 15.6146 16.607 15.513C16.5647 15.4115 16.5027 15.3193 16.4246 15.2418L11.1746 10.0002Z"
                  fill="#E87588"
                />
              </svg>
            </Link>
            <div className={style.dotsContainer}>
              <h4 className={style.textTitle}>Please select down below.</h4>
              <div className={style.optionContainer}>
                {allocationsData.map((allocationOption) => {
                  let imageSrc;
                  switch (allocationOption.Image) {
                    case "turtle":
                      imageSrc = turtle;
                      break;
                    case "tree":
                      imageSrc = tree;
                      break;
                    case "co2":
                      imageSrc = co2;
                      break;
                    default:
                      imageSrc = ""; // Handle default case if necessary
                      break;
                  }

                  return (
                    <div
                      className={`${style.option} ${
                        selected === allocationOption.Allocation_id &&
                        style.selected
                      }`}
                      onClick={() => {
                        setSelected(allocationOption.Allocation_id);
                      }}
                    >
                      <div className={style.allocationContainer}>
                        <img
                          className={style.allocationImage}
                          src={imageSrc}
                          alt={allocationOption.Image}
                        />
                        <p>{allocationOption.Impact_type_name}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <p className={style.allocationDescription}>
                {selected &&
                  allocationsData.find(
                    (allocation) => allocation.Allocation_id === selected
                  ).Description}
              </p>
              {/* {selectedIdAllocation && (
                <img
                  className={style.treesImg}
                  src={selectedIdAllocation.allocation_image}
                />
              )} */}
              <div className={style.quantityContainer}>
                <div
                  className={style.quantityButton}
                  onClick={() => {
                    if (trees > 1) {
                      setTrees(trees - 1);
                    }
                  }}
                >
                  -
                </div>
                <h3>{trees}</h3>
                <div
                  className={style.quantityButton}
                  onClick={() => {
                    setTrees(trees + 1);
                  }}
                >
                  +
                </div>
              </div>
              <div className={style.progresWrapper}>
                <h5>{MINIMUM_ORDER}</h5>
                <div className={style.progresContainer}>
                  <ProgresBar value={percentageAsNumber()} />
                </div>
                <h5>{maximumQuantity}</h5>
              </div>
              <h4 className={style.treesText}>
                You are going to buy{" "}
                <span className={style.treesTextQuantity}>{trees}</span> and you
                will recieve a certeficate for that.
              </h4>
            </div>
            {message.length ? (
              <div className={style.errorContainer}>
                <p className={style.message}>{message}</p>
              </div>
            ) : null}
            <div className={style.withdrawButton}>
              <button
                form="FORMID"
                type="submit"
                value="submit"
                className="button-secondary"
              >
                Withdraw
              </button>
            </div>
          </form>
          <img src={CloudLeft} alt="Cloud Left" className={style.cloudL} />
          <img src={CloudRight} alt="Cloud Right" className={style.cloudR} />
          <img src={CloudBot} alt="Cloud Bottom" className={style.cloudBot} />
        </div>
      </div>
    </div>
  );
}
