import React from "react";

import { useRef } from "react";
import style from "./style.module.scss";

import Eyes from "../../assets/BlogArticle/eyes.svg";
import Image from "../../assets/BlogArticle/Rectangle.png";
import Brain from "../../assets/BlogArticle/brain.svg";
import blogmain from "../../assets/BlogMain/gaming4.png";
import blog14 from "./blog14.jpg";

export default function BlogArticle14() {
  return (
    <div className={style.backgroundContainer}>
      <div className={style.dateContainer}>
        <p className={style.timeDate}>04/06/2023</p>
        <p className={style.timeline}>5 Minutes Read</p>
      </div>
      <div className={style.title}>
        The Ultimate Guide to Inviting Friends in Dice Dreams / By Don E Lemon
      </div>
      <div className={style.mainPicContainer}>
        <img src={blog14} alt="" className={style.blogPhoto} />
        <img src={Eyes} alt="" className={style.eyes} />
        <img src={Brain} alt="" className={style.brain} />
      </div>
      <div className={style.subContent}>
        <p>
          Hey there, Dice Dreams enthusiasts! Get ready to level up your game
          and score some epic rewards by inviting your friends to join the fun.
          We've got the complete guide right here, so buckle up!
        </p>
        <p style={{ marginTop: "24px", marginBottom: "24px" }}>
          How to Invite Friends and Get Rewards in Dice Dreams? It's as easy as
          rolling the dice, my friend. Just follow these steps:
          <ul>
            <li>
              Open up the game's main menu - You know the drill. Tap that menu
              icon like a boss and let the excitement begin.
            </li>
            <li>
              Click on the INVITE FRIENDS option - Look for the magic button
              that says "Invite Friends." It's your ticket to a whole new level
              of awesomeness.
            </li>
            <li>
              Click the green Invite button and send that invite link to your
              friends - Don't hold back, spread the Dice Dreams love! Hit that
              green Invite button, and boom, your invite link is ready to go.
              Send it to your friends and watch the excitement unfold.
            </li>
            <li>
              Your friend joins the game - Once your friend receives your invite
              and jumps into the Dice Dreams world, the real adventure begins.
            </li>
            <li>
              Complete the invite friend bar and collect your rewards - Time to
              reap the rewards, my friend! As your friends join and play the
              game using your invite link, the invite friend bar starts filling
              up. Once it's complete, you'll unlock some sweet rewards. Roll the
              dice, collect those free rolls and coins, and maybe even snag some
              awesome game items along the way. It's a win-win!.
            </li>
          </ul>
        </p>
        <p>
          Oh, and guess what? If you're already Facebook friends with someone
          who's playing Dice Dreams, they'll automatically be added to your
          list. How convenient is that?
        </p>
        <p style={{ marginTop: "24px", marginBottom: "24px" }}>
          Now, here are a few things to keep in mind about inviting friends in
          Dice Dreams:
          <ul>
            <li>To invite friends, simply send them the invite link.</li>
            <li>
              You'll receive rewards once you complete the invite friend target
              bar.
            </li>
            <li>
              Your friends need to play the game using their Facebook login.
              It's all about connecting and sharing the fun!
            </li>
          </ul>
        </p>
        <div className={style.subtitle}></div>
        <p>
          Feel free to send the invite link as a private message or post it on
          your social media. The more, the merrier!
        </p>

        <div className={style.subtitle}></div>
        <p>
          That's the scoop, my fellow Dice Dreams enthusiasts! You're now armed
          with the complete guide to inviting friends and scoring those sweet
          rewards. So go ahead, invite your friends, conquer the game together,
          and let the good times roll!
        </p>
      </div>
    </div>
  );
}
