import React from "react";

import style from "./style.module.scss";

export default function ProgresBar(props) {
  const { value } = props;
  return (
    <div className={style.container}>
      <div className={style.indicator} style={{ width: `${value}%` }} />
    </div>
  );
}
