export const UPDATE_TRACKER = "UPDATE_TRACKER";
export const UPDATE_CLICKID = "UPDATE_CLICKID";

export const updateTracker = (tracker) => {
  return {
    type: UPDATE_TRACKER,
    tracker: tracker,
  };
};
export const updateClickID = (clickid) => {
  return {
    type: UPDATE_CLICKID,
    clickid: clickid,
  };
};
