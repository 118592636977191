import React from "react";

import { useRef, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import API from "../../api";
import style from "./style.module.scss";
import moment from "moment";
import Loading from "../../components/LoadingScreen";

import Cloud from "../../assets/BlogMain/cloud.svg";

import blogmain from "../../assets/BlogMain/blogimage1.jpg";
import blogmain2 from "../../assets/BlogMain/blogimage2.jpg";
import blogmain3 from "../../assets/BlogMain/blogimage3.jpg";
//import gaming1 from "../../assets/BlogMain/gaming1.png";
import gaming2 from "../../assets/BlogMain/gaming2.png";
import gaming3 from "../../assets/BlogMain/gaming3.png";
import gaming4 from "../../assets/BlogMain/gaming4.png";
import googleBlog from "../BlogArticle10/googleBlog.jpg";
import blog11 from "../BlogArticle11/blog11.png";
import blog12 from "../BlogArticle12/blog12.jpg";
import blog13 from "../BlogArticle13/blog13.jpg";
import blog14 from "../BlogArticle14/blog14.jpg";
import blog15 from "../BlogArticle15/blog15.jpg";

import EventGA from "../../components/EventGA";
//====== SWIPER IMPORTS ========//

import { Navigation, Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

import "swiper/swiper.scss";
import "swiper/modules/navigation/navigation.scss";
import "swiper/modules/pagination/pagination.scss";

export default function BlogMain() {
  const Client = API.getInstance();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const history = useHistory();
  const [blogs, setBlogs] = useState([]);
  const lastBlog = blogs[blogs.length - 1];
  const { category } = useParams();
  console.log(category);
  useEffect(async () => {
    const response = await Client.getAllBlogs(
      "pub_af1816e8-b6db-423d-882b-f97c77ae4019"
    );
    console.log(response.data);
    if (category === "blogs") {
      setBlogs(response.data.api_kef);
    }
    if (category === "guides") {
      setBlogs(response.data.api_kef_guides);
    }
    if (category === "gaming") {
      setBlogs(response.data.api_kef_gaming);
    }
  }, [category]);

  if (blogs.length === 0) {
    return (
      <div className={style.loadingWrapper}>
        <Loading />
      </div>
    );
  }

  return (
    <div className={style.backgroundContainer}>
      <EventGA category={"PageView"} action={"(Page) Blog"} />
      {blogs.length > 1 && lastBlog && (
        <div className={style.mainBlogContainer}>
          <div
            className={style.mainBlog}
            onClick={() => {
              history.push(`/article/${lastBlog.post_id}`);
            }}
          >
            <img
              className={style.blogPic}
              src={lastBlog.thumbnail_url}
              alt=""
            />
            <div className={style.dateContainer}>
              <p className={style.timeDate}>
                {" "}
                {`Published Date: ${moment
                  .unix(lastBlog.publish_date)
                  .format("MM/DD/YYYY")}`}
              </p>
              {/* <p className={style.timeline}>4 Minutes Read</p> */}
            </div>
            <p className={style.blogTitle}>{lastBlog.title}</p>
            <div className={style.blogSubtitle}>{lastBlog.sub_title}</div>
          </div>
          <img className={style.cloud} src={Cloud} alt="Image" />
        </div>
      )}
      <div className={style.swiperContainer}>
        <div className={style.swiper}>
          {blogs.map((blog) => (
            <div
              key={blog.post_id}
              className={style.otherBlogsContainer}
              onClick={() => {
                history.push(`/article/${blog.post_id}`);
              }}
            >
              <div className={style.imageWrapper}>
                <img
                  src={blog.thumbnail_url}
                  className={style.blogImage}
                  alt=""
                />
              </div>
              <div className={style.descriptionContainer}>
                <div className={style.dateContainer}>
                  <p className={style.timeDate}>
                    {`Published Date: ${moment
                      .unix(blog.publish_date)
                      .format("MM/DD/YYYY")}`}
                  </p>
                  {/* <p className={style.timeline}>6 Minutes Read</p> */}
                </div>
                <p className={style.blogTitle}>{blog.title}</p>
                <div className={style.blogSubtitle}>{blog.sub_title}</div>
              </div>
            </div>
          ))}
          {/* <div
            className={style.otherBlogsContainer}
            onClick={() => {
              history.push("/article");
            }}
          >
            <div className={style.imageWrapper}>
              <img src={blogmain} className={style.blogImage} alt="" />
            </div>
            <div className={style.descriptionContainer}>
              <div className={style.dateContainer}>
                <p className={style.timeDate}>18/05/2022</p>
                <p className={style.timeline}>6 Minutes Read</p>
              </div>
              <p className={style.blogTitle}>Play to Earn Platforms & Games</p>
              <div className={style.blogSubtitle}>
                As the world rushes towards Web 3.0, NFTs, and Metaverse,
                Play-To-Earn games and platforms have experienced a rise in
                popularity. Are you, too...
              </div>
            </div>
          </div>
          <div
            className={style.otherBlogsContainer}
            onClick={() => {
              history.push("/article2");
            }}
          >
            <div className={style.imageWrapper}>
              <img src={blogmain2} className={style.blogImage} alt="" />
            </div>
            <div className={style.descriptionContainer}>
              <div className={style.dateContainer}>
                <p className={style.timeDate}>18/05/2022</p>
                <p className={style.timeline}>6 Minutes Read</p>
              </div>
              <p className={style.blogTitle}>
                Top 6 Play to Earn Cash Playing Apps
              </p>
              <div className={style.blogSubtitle}>
                Are you interested in earning real money in your spare time
                playing games? The newest wave of NFT and blockchain games
                allows you to earn
              </div>
            </div>
          </div>
          <div
            className={style.otherBlogsContainer}
            onClick={() => {
              history.push("/article3");
            }}
          >
            <div className={style.imageWrapper}>
              <img src={blogmain3} className={style.blogImage} alt="" />
            </div>
            <div className={style.descriptionContainer}>
              <div className={style.dateContainer}>
                <p className={style.timeDate}>18/05/2022</p>
                <p className={style.timeline}>4 Minutes Read</p>
              </div>
              <p className={style.blogTitle}>
                Games to earn crypto with Introduction
              </p>
              <div className={style.blogSubtitle}>
                Lately, I've been trying to earn some crypto by playing games.
                It's not easy, and most of them require a lot of time and
                effort, but here are...
              </div>
            </div>
          </div>
          <div
            className={style.otherBlogsContainer}
            onClick={() => {
              history.push("/article4");
            }}
          >
            <div className={style.imageWrapper}>
              <img src={gaming3} className={style.blogImage} alt="Img" />
            </div>
            <div className={style.descriptionContainer}>
              <div className={style.dateContainer}>
                <p className={style.timeDate}>19/01/2023</p>
                <p className={style.timeline}>6 Minutes Read</p>
              </div>
              <p className={style.blogTitle}>
                RUST - The Crowd’s favorite Skins
              </p>
              <div className={style.blogSubtitle}>
                RUST is a popular online multiplayer game that has been gaining
                popularity in the gaming community. The game's survival and
                combat mechanics have captured the attention of gamers, making
                it a popular choice for game streaming and esports.
              </div>
            </div>
          </div>
          <div
            className={style.otherBlogsContainer}
            onClick={() => {
              history.push("/article5");
            }}
          >
            <div className={style.imageWrapper}>
              <img src={gaming2} className={style.blogImage} alt="Img" />
            </div>
            <div className={style.descriptionContainer}>
              <div className={style.dateContainer}>
                <p className={style.timeDate}>23/08/2022</p>
                <p className={style.timeline}>8 Minutes Read</p>
              </div>
              <p className={style.blogTitle}>
                Fortnite research recently published about focus among teens
              </p>
              <div className={style.blogSubtitle}>
                As a content creator, I am always on the lookout for new and
                interesting research that can provide valuable insights into the
                gaming community.
              </div>
            </div>
          </div>
          <div
            className={style.otherBlogsContainer}
            onClick={() => {
              history.push("/article6");
            }}
          >
            <div className={style.imageWrapper}>
              <img src={gaming4} className={style.blogImage} alt="Img" />
            </div>
            <div className={style.descriptionContainer}>
              <div className={style.dateContainer}>
                <p className={style.timeDate}>23/08/2022</p>
                <p className={style.timeline}>6 Minutes Read</p>
              </div>
              <p className={style.blogTitle}>
                Kef.gg - the leading community to make flexible money online
              </p>
              <div className={style.blogSubtitle}>
                As a young person in today's society, finding ways to make extra
                income can be a constant struggle. Traditional methods of
                earning money often require a significant investment of time,
                money, and effort...
              </div>
            </div>
          </div>
          <div
            className={style.otherBlogsContainer}
            onClick={() => {
              history.push("/article7");
            }}
          >
            <div className={style.imageWrapper}>
              <img
                src="https://fiverr-res.cloudinary.com/npm-assets/layout-server/fiverr-og-logo.5fd6463.png"
                className={style.blogImage}
                alt="Img"
              />
            </div>
            <div className={style.descriptionContainer}>
              <div className={style.dateContainer}>
                <p className={style.timeDate}>23/08/2022</p>
                <p className={style.timeline}>3 Minutes Read</p>
              </div>
              <p className={style.blogTitle}>
                Fiver app gets people to grow their talents
              </p>
              <div className={style.blogSubtitle}>
                As a hustler and content creator, I'm always on the lookout for
                tools that can help me streamline my work and increase my
                productivity...
              </div>
            </div>
          </div>
          <div
            className={style.otherBlogsContainer}
            onClick={() => {
              history.push("/article8");
            }}
          >
            <div className={style.imageWrapper}>
              <img
                src="https://s40123.pcdn.co/wp-content/uploads/2017/04/deal-dash.png"
                className={style.blogImage}
                alt="Img"
              />
            </div>
            <div className={style.descriptionContainer}>
              <div className={style.dateContainer}>
                <p className={style.timeDate}>28/02/2023</p>
                <p className={style.timeline}>4 Minutes Read</p>
              </div>
              <p className={style.blogTitle}>Deal Dash review</p>
              <div className={style.blogSubtitle}>
                In today's fast-paced world, time is a precious commodity, and
                so is money. We all love a good bargain, but scouring the
              </div>
            </div>
          </div>
          <div
            className={style.otherBlogsContainer}
            onClick={() => {
              history.push("/article9");
            }}
          >
            <div className={style.imageWrapper}>
              <img
                src="https://play-lh.googleusercontent.com/b260V5sPJ66Bn9NYhlKISABE6ZiCrEFxXs0993lOLgzFoHHz2FexnrtXbJal95LhkEo"
                className={style.blogImage}
                alt="Img"
              />
            </div>
            <div className={style.descriptionContainer}>
              <div className={style.dateContainer}>
                <p className={style.timeDate}>28/02/2023</p>
                <p className={style.timeline}>4 Minutes Read</p>
              </div>
              <p className={style.blogTitle}>
                CSR 2 Realistic Drag Racing: The Ultimate Mobile Racing
                Experience
              </p>
              <div className={style.blogSubtitle}>
                Are you a fan of high-speed racing games? Look no further than
                CSR 2 Realistic Drag Racing. This mobile app is the ultimate
                drag racing experience,
              </div>
            </div>
          </div>
          <div
            className={style.otherBlogsContainer}
            onClick={() => {
              history.push("/article10");
            }}
          >
            <div className={style.imageWrapper}>
              <img src={googleBlog} className={style.blogImage} alt="Img" />
            </div>
            <div className={style.descriptionContainer}>
              <div className={style.dateContainer}>
                <p className={style.timeDate}>28/02/2023</p>
                <p className={style.timeline}>4 Minutes Read</p>
              </div>
              <p className={style.blogTitle}>
                Kef.gg VS Google / when you are the product
              </p>
              <div className={style.blogSubtitle}>
                As I'm navigating my way through the world, I've always felt
                like something was off. I knew that companies like Google were
                making a lot of money, but I never...
              </div>
            </div>
          </div>
          <div
            className={style.otherBlogsContainer}
            onClick={() => {
              history.push("/article11");
            }}
          >
            <div className={style.imageWrapper}>
              <img src={blog11} className={style.blogImage} alt="Img" />
            </div>
            <div className={style.descriptionContainer}>
              <div className={style.dateContainer}>
                <p className={style.timeDate}>04/06/2023</p>
                <p className={style.timeline}>2 Minutes Read</p>
              </div>
              <p className={style.blogTitle}>
                The Ultimate Guide to Building Your Kingdom in Dice Dreams / By
                Don E lemon.
              </p>
              <div className={style.blogSubtitle}>
                Get ready to level up and score some epic rewards in Dice
                Dreams! Building your kingdom is the key, and we've got the
                complete guide right here.
              </div>
            </div>
          </div>
          <div
            className={style.otherBlogsContainer}
            onClick={() => {
              history.push("/article12");
            }}
          >
            <div className={style.imageWrapper}>
              <img src={blog12} className={style.blogImage} alt="Img" />
            </div>
            <div className={style.descriptionContainer}>
              <div className={style.dateContainer}>
                <p className={style.timeDate}>04/06/2023</p>
                <p className={style.timeline}>4 Minutes Read</p>
              </div>
              <p className={style.blogTitle}>
                Dice Dreams Stickers Guide: Everything You Need to Know / By Don
                E Lemon
              </p>
              <div className={style.blogSubtitle}>
                This comprehensive guide provides valuable information about
                sticker packs, sticker trading, golden stickers, and sticker set
                rewards in Dice Dreams.
              </div>
            </div>
          </div>
          <div
            className={style.otherBlogsContainer}
            onClick={() => {
              history.push("/article13");
            }}
          >
            <div className={style.imageWrapper}>
              <img src={blog13} className={style.blogImage} alt="Img" />
            </div>
            <div className={style.descriptionContainer}>
              <div className={style.dateContainer}>
                <p className={style.timeDate}>04/06/2023</p>
                <p className={style.timeline}>3 Minutes Read</p>
              </div>
              <p className={style.blogTitle}>
                Unleash the Power of the Daily Rewards Calendar in Dice Dreams!
                / By Don E Lemon
              </p>
              <div className={style.blogSubtitle}>
                Listen up, folks! We've got an insane feature in Dice Dreams
                that's gonna blow your mind. It's called the
              </div>
            </div>
          </div>
          <div
            className={style.otherBlogsContainer}
            onClick={() => {
              history.push("/article14");
            }}
          >
            <div className={style.imageWrapper}>
              <img src={blog14} className={style.blogImage} alt="Img" />
            </div>
            <div className={style.descriptionContainer}>
              <div className={style.dateContainer}>
                <p className={style.timeDate}>04/06/2023</p>
                <p className={style.timeline}>3 Minutes Read</p>
              </div>
              <p className={style.blogTitle}>
                The Ultimate Guide to Inviting Friends in Dice Dreams / By Don E
                Lemon
              </p>
              <div className={style.blogSubtitle}>
                Hey there, Dice Dreams enthusiasts! Get ready to level up your
                game and score some epic rewards by inviting your friends to
                join the fun. We've got the complete guide right here,
              </div>
            </div>
          </div>
          <div
            className={style.otherBlogsContainer}
            onClick={() => {
              history.push("/article15");
            }}
          >
            <div className={style.imageWrapper}>
              <img src={blog15} className={style.blogImage} alt="Img" />
            </div>
            <div className={style.descriptionContainer}>
              <div className={style.dateContainer}>
                <p className={style.timeDate}>04/06/2023</p>
                <p className={style.timeline}>3 Minutes Read</p>
              </div>
              <p className={style.blogTitle}>
                Unleash the Thrill of Dice Dreams with Free Rolls and Coins
                (Daily Link Rewards) / By Don E Lemon
              </p>
              <div className={style.blogSubtitle}>
                Calling all Dice Dreams enthusiasts! Get ready to embark on an
                exhilarating journey filled with free rolls, coins, and epic
                rewards. We've got the lowdown on how to collect
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
