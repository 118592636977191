import React from "react";

import { useRef } from "react";
import style from "./style.module.scss";

import Eyes from "../../assets/BlogArticle/eyes.svg";
import Image from "../../assets/BlogArticle/Rectangle.png";
import Brain from "../../assets/BlogArticle/brain.svg";
import blogmain from "../../assets/BlogMain/gaming3.png";

export default function BlogArticle4() {
  return (
    <div className={style.backgroundContainer}>
      <div className={style.dateContainer}>
        <p className={style.timeDate}>19/01/2023</p>
        <p className={style.timeline}>6 Minutes Read</p>
      </div>
      <div className={style.title}>RUST - The Crowd’s favorite Skins</div>
      <div className={style.mainPicContainer}>
        <img src={blogmain} alt="" className={style.blogPhoto} />
        <img src={Eyes} alt="" className={style.eyes} />
        <img src={Brain} alt="" className={style.brain} />
      </div>
      <div className={style.subContent}>
        <p>
          RUST is a popular online multiplayer game that has been gaining
          popularity in the gaming community. The game's survival and combat
          mechanics have captured the attention of gamers, making it a popular
          choice for game streaming and esports. Game streaming is one of the
          key ways that RUST has grown in popularity. Players can live stream
          their gameplay on platforms such as Twitch and YouTube, allowing
          others to watch and learn from their strategies. This has also led to
          the growth of the RUST esports scene, as professional players compete
          in online tournaments for cash prizes. The gaming community plays a
          huge role in the success of RUST. Players can join online communities
          such as Discord and Reddit to connect with other players, share tips
          and tricks, and find teammates for online tournaments.
        </p>
        <div className={style.subtitle}>Video</div>
        <iframe
          width="853"
          height="480"
          src="https://www.youtube.com/embed/tsV_Lxn44xk"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
        <div className={style.subtitle}></div>
        <p>
          As a content creator, I am always looking for new and interesting ways
          to engage with the gaming community. One aspect of RUST that I find
          particularly interesting is the game's skins. In RUST, skins are
          cosmetic items that can be applied to weapons and equipment to change
          their appearance. These skins range from simple color changes to
          elaborate designs and patterns.
        </p>
        <div className={style.subtitle}></div>
        <p>
          Not only do skins add an extra level of customization to the game, but
          they also provide a great opportunity for players to express their
          individuality and stand out in the game.
        </p>
        <div className={style.subtitle}></div>
        <p>
          They also have a monetary value, players can trade them with others or
          sell them in the Steam marketplace. Skins are a fun and creative
          aspect of RUST that adds to the player's experience.
        </p>

        <div className={style.subtitle}></div>
        <p>
          TOP Rust Streamers for January 2023
          <ul>
            <li>Ricoy.</li>
            <li>spoonkid.</li>
            <li>CoconutB.</li>
            <li>Blooprint.</li>
            <li>IsVolcano.</li>
            <li>Agustabell212</li>
            <li>lawlergg</li>
            <li>Panpots</li>
          </ul>
        </p>
        <div className={style.subtitle}></div>
        <p>
          <a
            href="https://store.steampowered.com/agecheck/app/252490/"
            style={{ color: "var(--secondary-color)" }}
          >
            Find it here
          </a>
        </p>
      </div>
    </div>
  );
}
