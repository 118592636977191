import { UPDATE_TRACKER } from "../actions/tracker";
import { UPDATE_CLICKID } from "../actions/tracker";

export default (prevState = { tracker: "", clickid: "" }, action) => {
  switch (action.type) {
    case UPDATE_TRACKER:
      return { ...prevState, tracker: action.tracker };
    case UPDATE_CLICKID:
      return { ...prevState, clickid: action.clickid };

    default:
      return prevState;
  }
};
