import { withRouter } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const HandleLogin = () => {
  const router = useParams();

  return <h1>Handle Login</h1>;
};

export default withRouter(HandleLogin);
