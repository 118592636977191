import React from "react";

import style from "./style.module.scss";

import mushroom from "../../assets/Contact/mushroom.svg";

export default function Contact() {
  return (
    <div className={style.contact}>
      <div className={style.contactContent}>
        <h2>Contact</h2>
        <p>
          Don't hesitate to get in contact with us, for support or business, and
          we'll get back to you within 24 hours! <br /> <br />
          For every support inquiry, please contact{" "}
          <a href="https://discord.gg/MB3h237d6n" target="_blank">
            the Live Support chat.
          </a>{" "}
          <br /> <br />
          Business inquiries: <a href="#">admin@kef.gg</a> (No Support) <br />{" "}
          <br />
          Support inquiries can only be done through the Live Support chat.{" "}
          <br /> <br />
          Discord:{" "}
          <a href="https://discord.com/invite/Kd37dkr2a9" target="_blank">
            Join here
          </a>{" "}
          <br /> <br />
          Twitter:{" "}
          <a href="https://twitter.com/kefgg1" target="_blank">
            @kefgg1
          </a>
        </p>
      </div>
      <div className={style.backgroundContainer}>
        <img className={style.background} src={mushroom} alt="Background" />
      </div>
    </div>
  );
}
