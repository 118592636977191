import React from "react";

import { useRef } from "react";
import style from "./style.module.scss";

import Eyes from "../../assets/BlogArticle/eyes.svg";
import Image from "../../assets/BlogArticle/Rectangle.png";
import Brain from "../../assets/BlogArticle/brain.svg";
import blogmain from "../../assets/BlogMain/gaming4.png";

export default function BlogArticle8() {
  return (
    <div className={style.backgroundContainer}>
      <div className={style.dateContainer}>
        <p className={style.timeDate}>28/02/2023</p>
        <p className={style.timeline}>4 Minutes Read</p>
      </div>
      <div className={style.title}>Deal Dash review</div>
      <div className={style.mainPicContainer}>
        <img
          src="https://s40123.pcdn.co/wp-content/uploads/2017/04/deal-dash.png"
          alt=""
          className={style.blogPhoto}
        />
        <img src={Eyes} alt="" className={style.eyes} />
        <img src={Brain} alt="" className={style.brain} />
      </div>
      <div className={style.subContent}>
        <p>Deal Dash: The Ultimate App for Winning Deals</p>
        <div className={style.subtitle}></div>
        <p>
          In today's fast-paced world, time is a precious commodity, and so is
          money. We all love a good bargain, but scouring the internet and
          brickand-mortar stores for the best deals can be a tedious and
          timeconsuming task. That's where Deal Dash comes in, the ultimate app
          for winning deals on everything from electronics and fashion to
          household items and toys.
        </p>
        <div className={style.subtitle}></div>
        <p>
          Deal Dash is a unique platform that offers an exciting and fun way to
          shop online, giving users the opportunity to bid on items and win them
          for a fraction of their retail price. The app is free to download on
          the Google Play store and is incredibly easy to use. One of the things
          that set Deal Dash apart from other online shopping platforms is its
          "auction-style" model. Users can browse through a vast selection of
          items, ranging from brand-name products to gift cards, and bid on the
          ones they want. Each bid increases the price of the item by a few
          cents, and the last bidder when the clock runs out gets to purchase
          the item at the final price
        </p>
        <div className={style.subtitle}></div>
        <p>
          Try it out <a href="http://bit.ly/3nrE3OQ">iOS</a>
          <a href="http://bit.ly/3TOITle">Android</a>
        </p>
        <div className={style.subtitle}></div>
        <p>
          But what makes Deal Dash so addictive is that every bid costs just a
          few cents, and users can win items worth hundreds of dollars for
          pennies on the dollar. The app also offers a "Buy It Now" feature,
          which allows users to purchase an item at its retail price and get all
          their bids back, making it a risk-free proposition.
        </p>
        <div className={style.subtitle}></div>
        <p>
          In addition to its exciting bidding model, Deal Dash is also
          incredibly userfriendly, with a clean and intuitive interface that
          makes it easy to navigate. Users can search for items by category,
          brand, or price range, and there are helpful filters that can be used
          to sort items by popularity, ending soon, or newly added.
        </p>
        <div className={style.subtitle}></div>
        <p>
          The app also features a "Watchlist" function, which allows users to
          keep track of items they're interested in and receive alerts when the
          auction is about to end. This feature is especially useful for items
          that are in high demand, as it ensures that users don't miss out on
          their chance to bid. Deal Dash also offers a range of payment options,
          including credit cards and PayPal, making it easy for users to
          purchase their items and get them delivered right to their doorstep.
          The app also has a robust customer support team that can be reached
          through live chat or email, ensuring that any issues or concerns are
          promptly addressed.
        </p>
        <div className={style.subtitle}></div>
        <p>
          In conclusion, Deal Dash is the ultimate app for anyone looking to
          save money and win great deals online. Its exciting auction-style
          model, easyto-use interface, and vast selection of items make it a
          must-have for any savvy shopper. So why wait? Download Deal Dash today
          and start winning great deals!
        </p>
        <p>
          Try it out <a href="http://bit.ly/3nrE3OQ">iOS</a>
          <a href="http://bit.ly/3TOITle">Android</a>
        </p>
      </div>
    </div>
  );
}
