import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Google from "../../../assets/Login/Google-Icon.png";
import CloudLeft from "../../../assets/Withdraw/cloud-left.png";
import CloudRight from "../../../assets/Withdraw/cloud-right.png";
import CloudBot from "../../../assets/Withdraw/cloud-bot.png";
import PayPalText from "../../../assets/Withdraw/PayPal.png";
import PayPalLogo from "../../../assets/Withdraw/PayPalLogo.png";

import style from "./style.module.scss";

import API from "../../../api/";

import Input from "../../../components/Input";
import { Link, useLocation } from "react-router-dom";

export default function PayPal() {
  const router = useLocation();
  const wrapperRef = useRef(null);
  const routerHistory = useHistory();
  const [email, setEmail] = useState("");
  const [selected, setSelected] = useState(1);
  const [message, setMessage] = useState("");
  const [coins, setCoins] = useState();
  const [emailAdress, setEmailAdress] = useState("");
  const fee = "$10.0";
  const coinprice = "0.00";
  const Client = API.getInstance();
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      routerHistory.push(router.pathname);
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();
    if (coins <= 5000) {
      setMessage("You should have at least 5000 Kef Coins to withdraw.");
      console.log(message);
    } else if (selected == 0) {
      setMessage("Please enter the amount of coins you want to withdraw.");
    } else if (selected > coins) {
      setMessage("You cannot withdraw more coins than you have");
    } else if (emailAdress.length == 0) {
      setMessage("Please enter an email address.");
    } else {
      try {
        setMessage("Requesting Transfer...");
        const response = await Client.requestPaypalWithdraw(
          selected,
          emailAdress.toString()
        );
        console.log(response);

        if (response.data == true) {
          setMessage("Transfer Requested Sucessfully.");
          setCoins(coins - selected);
          routerHistory.push(`/withdraw?popup=payment-confirmation`);
        } else {
          setMessage("Transfer Failed. Please try again later.");
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  return (
    <div className={style.allcontent}>
      <div className={style.container}>
        <div className={style.background} ref={wrapperRef}>
          <div className={style.content}>
            <Link className={style.closeButton} to={router.pathname}>
              <svg width="24" height="24" viewBox="0 0 20 20">
                <path
                  d="M11.1746 10.0002L16.4246 4.75849C16.5815 4.60157 16.6697 4.38874 16.6697 4.16682C16.6697 3.9449 16.5815 3.73207 16.4246 3.57515C16.2677 3.41823 16.0549 3.33008 15.833 3.33008C15.611 3.33008 15.3982 3.41823 15.2413 3.57515L9.99962 8.82515L4.75796 3.57515C4.60104 3.41823 4.38821 3.33008 4.16629 3.33008C3.94437 3.33008 3.73154 3.41823 3.57462 3.57515C3.4177 3.73207 3.32955 3.9449 3.32955 4.16682C3.32955 4.38874 3.4177 4.60157 3.57462 4.75849L8.82462 10.0002L3.57462 15.2418C3.49652 15.3193 3.43452 15.4115 3.39221 15.513C3.34991 15.6146 3.32812 15.7235 3.32812 15.8335C3.32812 15.9435 3.34991 16.0524 3.39221 16.154C3.43452 16.2555 3.49652 16.3477 3.57462 16.4252C3.65209 16.5033 3.74426 16.5653 3.84581 16.6076C3.94736 16.6499 4.05628 16.6717 4.16629 16.6717C4.2763 16.6717 4.38522 16.6499 4.48677 16.6076C4.58832 16.5653 4.68049 16.5033 4.75796 16.4252L9.99962 11.1752L15.2413 16.4252C15.3188 16.5033 15.4109 16.5653 15.5125 16.6076C15.614 16.6499 15.7229 16.6717 15.833 16.6717C15.943 16.6717 16.0519 16.6499 16.1534 16.6076C16.255 16.5653 16.3472 16.5033 16.4246 16.4252C16.5027 16.3477 16.5647 16.2555 16.607 16.154C16.6493 16.0524 16.6711 15.9435 16.6711 15.8335C16.6711 15.7235 16.6493 15.6146 16.607 15.513C16.5647 15.4115 16.5027 15.3193 16.4246 15.2418L11.1746 10.0002Z"
                  fill="#E87588"
                />
              </svg>
            </Link>
            <img src={PayPalText} alt="PayPal Text" className={style.pPText} />
            <div className={style.pPDescription}>
              <svg width="30" height="30" viewBox="0 0 20 20" fill="none">
                <path
                  d="M10.0001 1.66699C8.35191 1.66699 6.74074 2.15573 5.37033 3.07141C3.99992 3.98709 2.93182 5.28858 2.30109 6.8113C1.67036 8.33401 1.50533 10.0096 1.82687 11.6261C2.14842 13.2426 2.94209 14.7274 4.10753 15.8929C5.27297 17.0583 6.75782 17.852 8.37433 18.1735C9.99084 18.4951 11.6664 18.3301 13.1891 17.6993C14.7118 17.0686 16.0133 16.0005 16.929 14.6301C17.8447 13.2597 18.3334 11.6485 18.3334 10.0003C18.331 7.79093 17.4522 5.67273 15.89 4.11045C14.3277 2.54817 12.2095 1.66942 10.0001 1.66699ZM10.0001 16.667C8.68154 16.667 7.39261 16.276 6.29628 15.5435C5.19996 14.8109 4.34547 13.7697 3.84089 12.5515C3.3363 11.3334 3.20428 9.99293 3.46152 8.69972C3.71875 7.40652 4.35369 6.21863 5.28604 5.28628C6.21839 4.35393 7.40628 3.71899 8.69948 3.46176C9.99269 3.20452 11.3331 3.33654 12.5513 3.84113C13.7695 4.34571 14.8107 5.2002 15.5432 6.29652C16.2758 7.39285 16.6668 8.68178 16.6668 10.0003C16.6645 11.7678 15.9615 13.4622 14.7117 14.7119C13.4619 15.9617 11.7675 16.6648 10.0001 16.667ZM10.0001 9.58366C9.77907 9.58366 9.56711 9.67146 9.41083 9.82774C9.25455 9.98402 9.16675 10.196 9.16675 10.417V12.917C9.16675 13.138 9.25455 13.35 9.41083 13.5062C9.56711 13.6625 9.77907 13.7503 10.0001 13.7503C10.2211 13.7503 10.4331 13.6625 10.5893 13.5062C10.7456 13.35 10.8334 13.138 10.8334 12.917V10.417C10.8334 10.196 10.7456 9.98402 10.5893 9.82774C10.4331 9.67146 10.2211 9.58366 10.0001 9.58366ZM10.0001 6.25033C9.79406 6.25033 9.59267 6.31142 9.42137 6.42588C9.25007 6.54034 9.11655 6.70302 9.03771 6.89336C8.95887 7.0837 8.93824 7.29315 8.97843 7.49521C9.01863 7.69728 9.11784 7.88288 9.26352 8.02856C9.4092 8.17424 9.5948 8.27345 9.79687 8.31364C9.99893 8.35384 10.2084 8.33321 10.3987 8.25437C10.5891 8.17553 10.7517 8.04201 10.8662 7.87071C10.9807 7.69941 11.0418 7.49801 11.0418 7.29199C11.0418 7.01573 10.932 6.75077 10.7367 6.55542C10.5413 6.36007 10.2764 6.25033 10.0001 6.25033Z"
                  fill="white"
                />
              </svg>
              <h4>
                PayPal withdrawals are processed multiple times a day by our
                staff. Please make sure that a fee of $0.30 + 2.9% might occur.
              </h4>
            </div>
            {message.length ? (
              <div className={style.errorContainer}>
                <p className={style.message}>{message}</p>
              </div>
            ) : null}
            <div className={style.ammountSelected}>
              <div
                className={`${style.select} ${
                  selected == 5000 ? style.selected : ""
                }`}
                onClick={() => {
                  setSelected(5000);
                }}
              >
                <img
                  src={PayPalLogo}
                  alt="PayPal"
                  className={style.paypalImage}
                />
                <p className={style.ammount}>5$</p>
              </div>
              <div
                className={`${style.select} ${
                  selected == 10000 ? style.selected : ""
                }`}
                onClick={() => {
                  setSelected(10000);
                }}
              >
                <img
                  src={PayPalLogo}
                  alt="PayPal"
                  className={style.paypalImage}
                />
                <p className={style.ammount}>10$</p>
              </div>
              <div
                className={`${style.select} ${
                  selected == 25000 ? style.selected : ""
                }`}
                onClick={() => {
                  setSelected(25000);
                }}
              >
                <img
                  src={PayPalLogo}
                  alt="PayPal"
                  className={style.paypalImage}
                />
                <p className={style.ammount}>25$</p>
              </div>
              <div
                className={`${style.select} ${
                  selected == 50000 ? style.selected : ""
                }`}
                onClick={() => {
                  setSelected(50000);
                }}
              >
                <img
                  src={PayPalLogo}
                  alt="PayPal"
                  className={style.paypalImage}
                />
                <p className={style.ammount}>50$</p>
              </div>
            </div>
            <div className={style.adressContainer}>
              <form
                method="METHOD"
                id="FORMID"
                className={style.inputContainer}
                onSubmit={(event) => handleSubmit(event)}
              >
                <input
                  className={style.input}
                  type="text"
                  placeholder="Enter your PayPal Address..."
                  onChange={(event) => {
                    setEmailAdress(event.target.value);
                  }}
                />
                {/* <button className={style.adressbutton}>Save Address</button> */}
              </form>
            </div>
            <div className={style.flexButtonContainer}>
              <div className={style.calculations}>
                <div className={style.fee}>
                  <h4>Fee:</h4>
                  <h4>{fee}</h4>
                </div>
                <div className={style.priceCoin}>
                  <h4>Coin Price:</h4>
                  <h4>{coinprice}</h4>
                </div>
              </div>
              <div className={style.withdrawButton}>
                <button
                  form="FORMID"
                  type="submit"
                  value="submit"
                  className="button-secondary"
                >
                  Withdraw
                </button>
              </div>
            </div>
          </div>
          <img src={CloudLeft} alt="Cloud Left" className={style.cloudL} />
          <img src={CloudRight} alt="Cloud Right" className={style.cloudR} />
          <img src={CloudBot} alt="Cloud Bottom" className={style.cloudBot} />
        </div>
      </div>
    </div>
  );
}
