import { UPDATE_USER_BALANCE } from "../actions/userBalance";

export default (prevState = { update: true }, action) => {
  switch (action.type) {
    case UPDATE_USER_BALANCE:
      return { ...prevState, update: action.update };
    default:
      return prevState;
  }
};
