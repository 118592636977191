import React, { useEffect } from "react";

import mixpanel from "mixpanel-browser";

import ReactGA from "react-ga4";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useSearchParams, useHistory } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { clearCampaigns, updateCampaigns } from "./store/actions/campaigns";
import { Provider } from "react-redux";

import API from "./api/";
import { useSelector } from "react-redux";
import OneSignal from "react-onesignal";

import Home from "./pages/Home";
import Earn from "./pages/Earn";
import Contact from "./pages/Contact";
import EarningGuides from "./pages/EarningGuides";
import FAQ from "./pages/FAQ";
import Onboarding from "./pages/Onboarding";
import PaypalGuides from "./pages/PaypalGuides";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import Profile from "./pages/Profile";
import Leaders from "./pages/Leaders";
import HandleLogin from "./pages/HandleLogin";
import AllBlogCategories from "./pages/AllBlogCategories";
import BlogMain from "./pages/BlogMain";
import BlogArticle from "./pages/BlogArticle";
import BlogArticle2 from "./pages/BlogArticle2";
import BlogArticle3 from "./pages/BlogArticle3";
import BlogArticle4 from "./pages/BlogArticle4";
import BlogArticle5 from "./pages/BlogArticle5";
import BlogArticle7 from "./pages/BlogArticle7";
import BlogArticle8 from "./pages/BlogArticle8";
import BlogArticle9 from "./pages/BlogArticle9";
import BlogArticle11 from "./pages/BlogArticle11";
import BlogArticle12 from "./pages/BlogArticle12";
import BlogArticle13 from "./pages/BlogArticle13";
import BlogArticle14 from "./pages/BlogArticle14";
import BlogArticle15 from "./pages/BlogArticle15";
import Ayet from "./pages/Ayet";
import Advertisers from "./pages/Advertisers";
import Advertisers2 from "./pages/Advertisers2";
import Handler from "./pages/Handler";
import NotFound from "./pages/NotFound";
import Andrew from "./pages/Andrew";

import Withdraw from "./pages/Withdraw";
import Navbar from "./components/Navbar";
import Chat from "./components/Chat";
import LastEarnings from "./components/LastEarnings";
import Footer from "./components/Footer";
import PopupMenager from "./popups";
import BottomNav from "./components/BottomNav";
import FetchOffers from "./components/FetchOffers";

import { store, persistor } from "./store";

import style from "./App.module.scss";
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import { Login, Logout } from "./store/actions/auth";
import { updateTracker, updateClickID } from "./store/actions/tracker";
import { updateLeaders, clearLeaders } from "./store/actions/temporary";
import BlogArticle6 from "./pages/BlogArticle6";
import BlogArticle10 from "./pages/BlogArticle10";
import OfferWalliFrame from "./pages/OfferWalliFrame/OfferWalliFrame";
import Campaign from "./pages/Campaign";

Amplify.configure(awsconfig);

function App() {
  const Client = API.getInstance();
  const routerController = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  let ref = urlParams.get("ref");
  let clickId = urlParams.get("clickid");
  if (ref && clickId) {
    store.dispatch(updateClickID(clickId));
  } else if (ref === "adbtc.to") {
    store.dispatch(updateTracker(ref));
    window.location =
      "https://commodified.g2afse.com/click?pid=551&offer_id=6085937";
  } else if (ref === "google") {
    store.dispatch(updateTracker(ref));
    window.location =
      "https://commodified.g2afse.com/click?pid=556&offer_id=6085969";
  } else if (ref === "onesignal") {
    store.dispatch(updateTracker(ref));
    window.location =
      "https://commodified.g2afse.com/click?pid=4674&offer_id=6098622";
  }

  useEffect(() => {
    if ("avatar" in localStorage) {
    } else {
      const random10 = Math.floor(Math.random() * 9) + 1;
      localStorage.setItem("avatar", random10);
    }
  }, []);

  useEffect(() => {
    ReactGA.initialize("G-PPVNYT4HH1");
    ReactGA.send(window.location.pathname + window.location.search);
    OneSignal.init({ appId: "7c26836f-906f-4548-9d48-97b9430d7557" });

    //mixpanel.init("7a77a622e4e10271c67ce26546bb1ad5", {
    //  debug: false,
    //  track_pageview: true,
    //  persistence: "localStorage",
    //});

    // Set this to a unique identifier for the user performing the event.
    //mixpanel.identify("USER_ID");
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        {
          <Router>
            <Switch>
              <Route path="/1pger" exact>
                <Advertisers />
              </Route>
              <Route path="/2pger" exact>
                <Advertisers2 />
              </Route>
              <Route path="/offer-wall" exact>
                <OfferWalliFrame />
              </Route>
              <Route path="/campaign" exact>
                <Campaign />
              </Route>
              <div className={style.container}>
                <FetchOffers />
                <div className={style.navbarContainer}>
                  <Navbar />
                  <LastEarnings />
                </div>
                <div className={style.contentWrapper}>
                  <div className={style.earningsContainer}></div>
                  <div className={style.row}>
                    <div className={style.contentContainer}>
                      <Switch>
                        <Route path="/" exact>
                          <Onboarding />
                        </Route>
                        <Route path="/withdraw" exact>
                          <Withdraw />
                        </Route>
                        <Route path="/handler" exact>
                          <Handler />
                        </Route>
                        <Route path="/handlelogin" exact>
                          <HandleLogin />
                        </Route>
                        <Route path="/earn" exact>
                          <Earn />
                        </Route>
                        <Route path="/contact" exact>
                          <Contact />
                        </Route>
                        <Route path="/earning-guide" exact>
                          <EarningGuides />
                        </Route>
                        <Route path="/blog" exact>
                          <AllBlogCategories />
                        </Route>
                        <Route path="/blog-category/:category" exact>
                          <BlogMain />
                        </Route>
                        <Route path="/article/:id" exact>
                          <BlogArticle />
                        </Route>
                        <Route path="/article2" exact>
                          <BlogArticle2 />
                        </Route>
                        <Route path="/article3" exact>
                          <BlogArticle3 />
                        </Route>
                        <Route path="/article4" exact>
                          <BlogArticle4 />
                        </Route>
                        <Route path="/article5" exact>
                          <BlogArticle5 />
                        </Route>
                        <Route path="/article6" exact>
                          <BlogArticle6 />
                        </Route>
                        <Route path="/article7" exact>
                          <BlogArticle7 />
                        </Route>
                        <Route path="/article8" exact>
                          <BlogArticle8 />
                        </Route>
                        <Route path="/article9" exact>
                          <BlogArticle9 />
                        </Route>
                        <Route path="/article10" exact>
                          <BlogArticle10 />
                        </Route>
                        <Route path="/article11" exact>
                          <BlogArticle11 />
                        </Route>
                        <Route path="/article12" exact>
                          <BlogArticle12 />
                        </Route>
                        <Route path="/article13" exact>
                          <BlogArticle13 />
                        </Route>
                        <Route path="/article14" exact>
                          <BlogArticle14 />
                        </Route>
                        <Route path="/article15" exact>
                          <BlogArticle15 />
                        </Route>
                        <Route path="/faq" exact>
                          <FAQ />
                        </Route>
                        <Route path="/paypal-guides" exact>
                          <PaypalGuides />
                        </Route>
                        <Route path="/privacy-policy" exact>
                          <PrivacyPolicy />
                        </Route>
                        <Route path="/terms-of-service" exact>
                          <TermsOfService />
                        </Route>
                        <Route path="/profile" exact>
                          <Profile />
                        </Route>
                        <Route path="/leaders" exact>
                          <Leaders />
                        </Route>
                        <Route path="/ads.txt" exact>
                          <Ayet />
                        </Route>
                        <Route path="/impact-Site-verification" exact>
                          <Andrew />
                        </Route>
                        <Route path="/*" exact>
                          <NotFound />
                        </Route>
                      </Switch>
                      <div className={style.footerContainer}>
                        <Footer />
                      </div>
                    </div>
                    <div className={style.chatContainer}>
                      <Chat />
                    </div>
                  </div>
                  <div className={style.bottomNavContainer}>
                    <BottomNav />
                  </div>
                  <div className={style.popupContainer}>
                    <PopupMenager />
                  </div>
                </div>
              </div>
            </Switch>
          </Router>
        }
      </PersistGate>
    </Provider>
  );
}

export default App;
