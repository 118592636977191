import React from "react";
import { useRef } from "react";
import style from "./style.module.scss";

import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

import header from "../../assets/Advertisers/header.svg";
import face from "../../assets/Advertisers/face.svg";
import popcorn from "../../assets/Advertisers/popcorn.svg";
import flag from "../../assets/Advertisers/flag.svg";
import ufo from "../../assets/Advertisers/ufo.svg";
import nonGaming from "../../assets/Advertisers/nonGaming.png";
import gaming from "../../assets/Advertisers/gaming.png";
import surveys from "../../assets/Advertisers/surveys.png";
import web3 from "../../assets/Advertisers/web3.png";
import rubik from "../../assets/Advertisers/rubik.svg";
import planet from "../../assets/Advertisers/planet.svg";
import crystals from "../../assets/Advertisers/crystals.svg";
import kef from "../../assets/Advertisers/kef.svg";

import jonathan from "../../assets/Advertisers/Team/jonathan.svg";
import liran from "../../assets/Advertisers/Team/liran.svg";
import nimrod from "../../assets/Advertisers/Team/nimrod.svg";
import armando from "../../assets/Advertisers/Team/armando.svg";
import gil from "../../assets/Advertisers/Team/gil.svg";

import daniel from "../../assets/Advertisers/Advisor/daniel.svg";
import tomer from "../../assets/Advertisers/Advisor/tomer.svg";
import tess from "../../assets/Advertisers/Advisor/tess.svg";
import ido from "../../assets/Advertisers/Advisor/ido.svg";

export default function Advertisers() {
  const tableData = [
    {
      title: "Apps",
      description:
        "We partner with teams ranging from gaming studios to lifestyle developers, promoting products on your terms",
      subDescription:
        "if you know your most loyal users reached level 21 we can get more people to go through the funnel and test for their intent",
    },
    {
      title: "Surveys",
      description:
        "From market research to real world commerce, brands are looking to learn about the opinions of a growingly fragmented world of people and sub groups ",
      subDescription:
        "We are connected to a young {18-24}, Male {65%}, english speaking audience, {US, UK, CA - 85%}, that have shown interest with tech, gadgets and gaming",
    },
    {
      title: "Contest winners",
      description:
        "We’re running daily, weekly and monthly contests through our  products {App/Surveys/CTV/ Content} knighting a knight every day and  a king coronation every month.",
      subDescription:
        "Crypto and gift card giveaways for the hard work everyone is putting in",
    },
    {
      title: "CTV channels",
      description:
        "With the majority of western viewers becoming cord cutters and the value of CTV users is through the roof with ad spend Jumping by a whopping 82.4% In 2022. No one has been able so far, to scale with acquiring users TO their CTV channels ",
      subDescription:
        "Kef.gg is bringing a cross platform, performance mindset to user acquisition with rewarded funnels to grow your channel base on all streaming platforms",
    },
    {
      title: "Content blogging",
      description:
        "We offer our users the chance to monetize their blogs with us, choosing pre-approved keywords and topics while a team of rewarded curators are assisting our partners with SEO and organic traction to their sites, apps and products",
    },
  ];

  return (
    <div className={style.backgroundContainer}>
      <div className={style.headerContainer}>
        <img src={header} alt="Image" className={style.header} />
        <div className={style.welcomeContainer}>
          <div className={style.welcome}>WELCOME TO</div>
          <img src={kef} alt="Image" className={style.kef} />
        </div>
      </div>
      <div className={style.sectionContainer}>
        <p className={style.sectionTitle}>Mission </p>
        <p className={style.sectionSubTitle}>
          Kef.gg is the leading platform for getting users paid online
        </p>
        <p className={style.sectionParagraph}>
          With the human attention span being the most unique commodity on earth
          We are including everyone for their role in the attention economy* -
          which states, Attention is a resource—a person has only so much of
          it." Companies aim at attaining user’s motivation for their well
          intended and high valued products and services{" "}
        </p>
        <p className={style.sectionParagraph}>
          We think there are better ways of holding these value conversations.
        </p>
        <p className={style.sectionParagraph}>
          We believe that targeted ads and excessive data mining have not lead
          to better ‘quality of life’ changes, that would suffice giving 3rd
          parties extensive power over our private information (not our keys,
          not our data) We offer a better way to start a 2 way conversation
          between brands and their users.
        </p>
      </div>
      <div className={style.sectionContainer}>
        <p className={style.sectionTitle}>Vision </p>

        <p className={style.sectionParagraph}>
          Imagine a world where money acts as a mere transfer of value when you
          need spending money, you hop online, complete pleasant tasks and get
          paid immediately and instantly.
        </p>
        <p className={style.sectionParagraph}>
          Brands start interacting with you for your actions, thoughts and
          opinions while you get rewarded for it with no delay .
        </p>
        <p className={style.sectionParagraph}>
          You choose how and where to withdraw with the options ranging from
          Cryptographic currencies to gift cards to meta-verse tokens Currency
          is no longer bound to a geography and the ability to exchange with
          ease is the new ‘reserve currency’ status.
        </p>
      </div>
      <div className={style.ourProductSection}>
        <h2 className={style.ourProductTitle}>Our Products</h2>
        <img src={face} alt="Image" className={style.face} />
        <img src={popcorn} alt="Image" className={style.popcorn} />
        <div className={style.cardsContainer}>
          {tableData.map((data, index) => (
            <div className={style.card} key={index}>
              <h2 className={style.cardTitle}>{data.title}</h2>
              <p className={style.cardDescription}>{data.description}</p>
              {data?.subDescription && (
                <p className={style.cardDescription}>{data.subDescription}</p>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={style.roadmapContainer}>
        <h2 className={style.roadMapTitle}>!ROADMAP!</h2>
        <Swiper
          slidesPerView={1} // Set this to 6 for vertical show
          freeMode={true}
          modules={[Pagination, Navigation]}
          className={style.roadMapSwiper}
          navigation={true}
          initialSlide={2}
          // direction={"vertical"}
          breakpoints={{
            1100: {
              slidesPerView: 6.1,
              spaceBetween: 20,
            },
          }}
        >
          <SwiperSlide className={style.roadMap}>
            <h2 className={style.title}>Q2 22</h2>
            <div className={style.bigCircle}>
              <img src={flag} alt="Image" className={style.flag} />
            </div>
            <div className={style.roadProgress} />
            <ul className={style.itemsContainer}>
              <li className={style.item}>Working MVP - full user cycle</li>
              <li className={style.item}>sign up</li>
              <li className={style.item}>wallet withdrawal </li>
              <li className={style.item}>UX/UI tweeks</li>
            </ul>
          </SwiperSlide>
          <SwiperSlide className={style.roadMap}>
            <h2 className={style.title}>Q3 22</h2>
            <div className={style.bigCircle}>
              <img src={flag} alt="Image" className={style.flag} />
            </div>
            <div className={style.roadProgressCurrent}>
              <div className={style.fill} />
            </div>
            <ul className={style.itemsContainer}>
              <li className={style.item}>Site functionality</li>
              <li className={style.item}>Android app</li>
              <li className={style.item}>Advertising partners</li>
              <li className={style.item}>Increased media buying budgets</li>
            </ul>
          </SwiperSlide>
          <SwiperSlide className={style.roadMap}>
            <h2 className={style.title}>Q4 22</h2>
            <div className={style.currCircle}>
              <img src={ufo} alt="Image" className={style.ufo} />
            </div>
            <div className={style.roadProgressCurrent}></div>
            <ul className={style.itemsContainer}>
              <li className={style.item}>API for 3rd party developers</li>
              <li className={style.item}>Chat</li>
              <li className={style.item}>Support</li>
              <li className={style.item}>Daily contests</li>
              <li className={style.item}>Crypto wallet integrations</li>
              <li className={style.item}>Community building</li>
            </ul>
          </SwiperSlide>
          <SwiperSlide className={style.roadMap}>
            <h2 className={style.title}>Q1 23</h2>
            <div className={style.currCircle}>
              <img src={ufo} alt="Image" className={style.ufo} />
            </div>
            <div className={style.roadProgressCurrent}></div>
            <ul className={style.itemsContainer}>
              <li className={style.item}>Mobile SDK</li>
              <li className={style.item}>Add web3 advertisers,</li>
              <li className={style.item}>Admin dashboard,</li>
              <li className={style.item}>H5 Games</li>
            </ul>
          </SwiperSlide>
          <SwiperSlide className={style.roadMap}>
            <h2 className={style.title}>Q2 23</h2>
            <div className={style.currCircle}>
              <img src={ufo} alt="Image" className={style.ufo} />
            </div>
            <div className={style.roadProgressCurrent}></div>
            <ul className={style.itemsContainer}>
              <li className={style.item}>Becoming a DAO</li>
              <li className={style.item}>Token</li>
              <li className={style.item}>NFT collection</li>
              <li className={style.item}>improving contests,</li>
              <li className={style.item}>giveaways and refferal</li>
            </ul>
          </SwiperSlide>
          <SwiperSlide className={style.roadMap}>
            <h2 className={style.title}>Q3 23</h2>
            <div className={style.currCircle}>
              <img src={ufo} alt="Image" className={style.ufo} />
            </div>
            <ul className={style.itemsContainer}>
              <li className={style.item}>
                Multiple new UI’s and domains to rinse and repeat on new
                audiences
              </li>
            </ul>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className={style.companiesSection}>
        <p className={style.companiesTitle}>
          Growth companies actively running open web user acquisition campaigns
        </p>
        <div className={style.imageContainer}>
          <img src={gaming} alt="Image" className={style.gaming} />
          <div className={style.middleContainer}>
            <img src={surveys} alt="Image" className={style.middle} />
            <img src={web3} alt="Image" className={style.middle} />
          </div>
          <img src={nonGaming} alt="Image" className={style.gaming} />
        </div>
      </div>
      <div className={style.challengeContainer}>
        <div className={style.challengeTitle}>What do we challenge</div>
        <p className={style.challengeSubTitle}>
          Kef.gg is bridging the gap between web2 mechanics and web3 products
          Orchestrating ‘user motivations’ and onboarding users to new digital
          frontiers.
        </p>
        <p className={style.challengeSubTitle}>Because we found that</p>
        <ul className={style.challengeSubTitle}>
          <li className={style.challengeList}>
            {`Users are not reimbursed for their role in the attention economy {67% connected to the internet globally, much less are earning an income}`}
          </li>
          <li className={style.challengeList}>
            {`Brands are paying too many intermediaries {CAC is generally increasing with more demand for user actions}`}
          </li>
          <li className={style.challengeList}>
            {`Web3 is non mainstream yet {NFT adoption, Gamefi active users are low}`}
          </li>
          <li className={style.challengeList}>
            {`Crypto wallets as an identifier {vague user level data, attribution issues}`}
          </li>
        </ul>
        <p className={style.challengeSubTitle}>
          We communicate directly with our users and are able to
        </p>
        <ul className={style.challengeSubTitle}>
          <li className={style.challengeList}>
            {`Cross match opt in user data {Wallets, Emails, Surveys}`}
          </li>
          <li className={style.challengeList}>
            {`Deliver down the funnel user actions, beating your KPI’s`}
          </li>
          <li className={style.challengeList}>
            {`Indepth feedback about your apps {Product QA, Research panels}`}
          </li>
          <li className={style.challengeList}>
            {`Adding a layer of utility to your UA efforts {Gamification, Contests}`}
          </li>
        </ul>
      </div>
      <div className={style.userContainer}>
        <div className={style.teamContainer}>
          <h2 className={style.title}>DA TEAM!</h2>
          <div className={style.user}>
            <div className={style.userDescription}>
              <div className={style.container}>
                <h3 className={style.userName}>Jonathan Storch</h3>
                <h2 className={style.userRank}>CEO</h2>
              </div>
              <img src={jonathan} alt="Image" className={style.userImage} />
            </div>
            <p className={style.userParagraph}>
              Entrepreneur, Performance marketer, Music prodcuer, PC gamer
            </p>
          </div>
          <div className={style.user}>
            <div className={style.userDescription}>
              <div className={style.container}>
                <h3 className={style.userName}>Liran Ochin</h3>
                <h2 className={style.userRank}>CTO</h2>
              </div>
              <img src={liran} alt="Image" className={style.userImage} />
            </div>
            <p className={style.userParagraph}>
              MAMRAM course instructor, SAP ERP, bitcoin maximalist
            </p>
          </div>
          <div className={style.user}>
            <div className={style.userDescription}>
              <div className={style.container}>
                <h3 className={style.userName}>Nimrod Carmi</h3>
                <h2 className={style.userRank}>UX Lead Designer</h2>
              </div>
              <img src={nimrod} alt="Image" className={style.userImage} />
            </div>
            <p className={style.userParagraph}>
              Master of arts from städelschule Frankfurt ‘create school'
              graduate tel aviv
            </p>
          </div>
          <div className={style.user}>
            <div className={style.userDescription}>
              <div className={style.container}>
                <h3 className={style.userName}>Armando Gjoni</h3>
                <h2 className={style.userRank}>Front End</h2>
              </div>
              <img src={armando} alt="Image" className={style.userImage} />
            </div>
            <p className={style.userParagraph}>
              B.Sc. Computer Science from FanS.Noli, Google skeptic
            </p>
          </div>
          <div className={style.user}>
            <div className={style.userDescription}>
              <div className={style.container}>
                <h3 className={style.userName}>Gil Twill</h3>
                <h2 className={style.userRank}>System architect</h2>
              </div>
              <img src={gil} alt="Image" className={style.userImage} />
            </div>
            <p className={style.userParagraph}>
              MAMRAM graduate, Israeli P"M office, cloud solution architect, MMA
              fighter
            </p>
          </div>
        </div>
        <div className={style.advisorContainer}>
          <h2 className={style.title}>DA ADVISORS!</h2>
          <div className={style.user}>
            <div className={style.userDescription}>
              <div className={style.container}>
                <h3 className={style.userName2}>Tomer Warschauer Nuni</h3>
                <h2 className={style.userRank2}>The crypto influencer</h2>
              </div>
              <img src={tomer} alt="Image" className={style.userImage} />
            </div>
            <p className={style.userParagraph}>
              {`Tomer is the CMO of kryptomon, an NFT Play-and-Earn blockchain game, (10mm round led by NFX)
Seed investor {Cookie3, DucksVegas, Utopian Game Labs} and public speaker `}
            </p>
          </div>
          <div className={style.user}>
            <div className={style.userDescription}>
              <div className={style.container}>
                <h3 className={style.userName}>Daniel Rechter</h3>
                <h2 className={style.userRank}>The founder</h2>
              </div>
              <img src={daniel} alt="Image" className={style.userImage} />
            </div>
            <p className={style.userParagraph}>
              Daniel is a Serial gaming founder, Big Blue Parrot was acquired by
              Playtika, Playright Games exit was to Qiiwi, created a Japanese
              rhythm game which was sold to Eisys
            </p>
          </div>
          <div className={style.user}>
            <div className={style.userDescription}>
              <div className={style.container}>
                <h3 className={style.userName}>Tess r. suchoff</h3>
                <h2 className={style.userRank}>Data geek</h2>
              </div>
              <img src={tess} alt="Image" className={style.userImage} />
            </div>
            <p className={style.userParagraph}>
              Tess is a Director of Strategic Data Partnerships @ Apptopia and
              an advisor to Kindred while being a research team leader at
              Harvard Uni
            </p>
          </div>
          <div className={style.user}>
            <div className={style.userDescription}>
              <div className={style.container}>
                <h3 className={style.userName}>Ido Kirshenboim</h3>
                <h2 className={style.userRank}>Open web master</h2>
              </div>
              <img src={ido} alt="Image" className={style.userImage} />
            </div>
            <p className={style.userParagraph}>
              Ido is a leading open web marketer mastering discovery and
              attribution processes @ Monday.com
            </p>
          </div>
        </div>
        <img src={rubik} alt="Image" className={style.rubik} />
        <img src={planet} alt="Image" className={style.planet} />
      </div>
      <div className={style.contactSection}>
        <div className={style.contactContainer}>
          <h3 className={style.power}>
            Powering the evolution - one attention span at a time
          </h3>
          <div className={style.centering}>
            <h2 className={style.thankYou}>Thank you</h2>
            <a href="mailto:js@commodifiedimage.com" className={style.contact}>
              Click To Contact
            </a>
          </div>
        </div>
        <img src={crystals} alt="Image" className={style.crystals} />
      </div>
    </div>
  );
}
