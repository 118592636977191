import React from "react";

import style from "./style.module.scss";

import stars from "../../assets/DailyTab/stars.svg";
import lighting from "../../assets/DailyTab/lightning.svg";
import starsmid from "../../assets/DailyTab/starsmid.svg";
import eye from "../../assets/DailyTab/eye.svg";
import DesktopImg from "../../assets/DailyTab/Desktop.svg";

export default function MonthlyTab() {
  const monthCoins = "100";
  const totalUsers = "23440";
  return (
    <div className={style.backgroundContainer}>
      <div className={style.container}>
        <div className={style.endContainer}>
          <div className={style.theEndContainer}>
            <div className={style.endTitle}>END IN</div>
            <div className={style.countDown}>
              <h4 className={style.timeChar}>D</h4>
              <h4 className={style.timeNum}>1</h4>
              <h4 className={style.timeNum}>1</h4>
              <h4 className={style.timeChar}>H</h4>
              <h4 className={style.timeNum}>1</h4>
              <h4 className={style.timeNum}>1</h4>
              <h4 className={style.timeChar}>M</h4>
              <h4 className={style.timeNum}>1</h4>
              <h4 className={style.timeNum}>1</h4>
              <h4 className={style.timeChar}>S</h4>
              <h4 className={style.timeNum}>1</h4>
              <h4 className={style.timeNum}>1</h4>
            </div>
            <h5 className={style.userEarned}>
              You earned{" "}
              <span className={style.colorRed}>
                {monthCoins} coins this month{" "}
              </span>{" "}
              and ranked
              <span className={style.colorRed}> out of {totalUsers} users</span>
            </h5>
          </div>
          <div className={style.topUsers}>
            <div className={style.users} id={style.top}>
              <h5 className={style.userRank}>1st</h5>
              <img
                className={style.avatar}
                src="https://staging.kef.gg/api/static/user_avatars/3.svg"
                alt="Avatar"
              />
              <h5 className={style.userName}>kokocrazy88</h5>
              <div className={style.coinsContainer}>
                <div className={style.coinsAmmount}>
                  <div className={style.inline}>
                    <p className={style.ammount}>34,200</p>
                    <svg
                      className={style.coinSvg}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <circle cx="8" cy="8" r="8" fill="#FFD400" />
                      <circle
                        cx="8"
                        cy="8"
                        r="7.5"
                        stroke="black"
                        stroke-opacity="0.05"
                      />
                      <circle cx="8" cy="8" r="7" fill="#FFD400" />
                      <circle
                        cx="8"
                        cy="8"
                        r="6.5"
                        stroke="#F58220"
                        stroke-opacity="0.6"
                      />
                      <rect
                        opacity="0.6"
                        x="6.5"
                        y="4.5"
                        width="3"
                        height="7"
                        stroke="#F58220"
                      />
                      <line
                        x1="7.5"
                        y1="11"
                        x2="7.5"
                        y2="5"
                        stroke="#F58220"
                        stroke-opacity="0.6"
                      />
                    </svg>
                  </div>
                  <p className={style.titleText}>Coins</p>
                </div>
                <div className={style.coinsAmmount}>
                  <div className={style.inline}>
                    <p className={style.ammount}>34,200</p>
                    <svg
                      className={style.coinSvg}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <circle cx="8" cy="8" r="8" fill="#FFD400" />
                      <circle
                        cx="8"
                        cy="8"
                        r="7.5"
                        stroke="black"
                        stroke-opacity="0.05"
                      />
                      <circle cx="8" cy="8" r="7" fill="#FFD400" />
                      <circle
                        cx="8"
                        cy="8"
                        r="6.5"
                        stroke="#F58220"
                        stroke-opacity="0.6"
                      />
                      <rect
                        opacity="0.6"
                        x="6.5"
                        y="4.5"
                        width="3"
                        height="7"
                        stroke="#F58220"
                      />
                      <line
                        x1="7.5"
                        y1="11"
                        x2="7.5"
                        y2="5"
                        stroke="#F58220"
                        stroke-opacity="0.6"
                      />
                    </svg>
                  </div>
                  <p className={style.titleText}>Bonus</p>
                </div>
              </div>
            </div>
            <div className={style.users2}>
              <h5 className={style.userRank}>2nd</h5>
              <img
                className={style.avatar}
                src="https://staging.kef.gg/api/static/user_avatars/3.svg"
                alt="Avatar"
              />
              <h5 className={style.userName}>kokocrazy88</h5>
              <div className={style.coinsContainer}>
                <div className={style.coinsAmmount}>
                  <div className={style.inline}>
                    <p className={style.ammount}>34,200</p>
                    <svg
                      className={style.coinSvg}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <circle cx="8" cy="8" r="8" fill="#FFD400" />
                      <circle
                        cx="8"
                        cy="8"
                        r="7.5"
                        stroke="black"
                        stroke-opacity="0.05"
                      />
                      <circle cx="8" cy="8" r="7" fill="#FFD400" />
                      <circle
                        cx="8"
                        cy="8"
                        r="6.5"
                        stroke="#F58220"
                        stroke-opacity="0.6"
                      />
                      <rect
                        opacity="0.6"
                        x="6.5"
                        y="4.5"
                        width="3"
                        height="7"
                        stroke="#F58220"
                      />
                      <line
                        x1="7.5"
                        y1="11"
                        x2="7.5"
                        y2="5"
                        stroke="#F58220"
                        stroke-opacity="0.6"
                      />
                    </svg>
                  </div>
                  <p className={style.titleText}>Coins</p>
                </div>
                <div className={style.coinsAmmount}>
                  <div className={style.inline}>
                    <p className={style.ammount}>34,200</p>
                    <svg
                      className={style.coinSvg}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <circle cx="8" cy="8" r="8" fill="#FFD400" />
                      <circle
                        cx="8"
                        cy="8"
                        r="7.5"
                        stroke="black"
                        stroke-opacity="0.05"
                      />
                      <circle cx="8" cy="8" r="7" fill="#FFD400" />
                      <circle
                        cx="8"
                        cy="8"
                        r="6.5"
                        stroke="#F58220"
                        stroke-opacity="0.6"
                      />
                      <rect
                        opacity="0.6"
                        x="6.5"
                        y="4.5"
                        width="3"
                        height="7"
                        stroke="#F58220"
                      />
                      <line
                        x1="7.5"
                        y1="11"
                        x2="7.5"
                        y2="5"
                        stroke="#F58220"
                        stroke-opacity="0.6"
                      />
                    </svg>
                  </div>
                  <p className={style.titleText}>Bonus</p>
                </div>
              </div>
            </div>
            <div className={style.users}>
              <h5 className={style.userRank}>3rd</h5>
              <img
                className={style.avatar}
                src="https://staging.kef.gg/api/static/user_avatars/3.svg"
                alt="Avatar"
              />
              <h5 className={style.userName}>kokocrazy88</h5>
              <div className={style.coinsContainer}>
                <div className={style.coinsAmmount}>
                  <div className={style.inline}>
                    <p className={style.ammount}>34,200</p>
                    <svg
                      className={style.coinSvg}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <circle cx="8" cy="8" r="8" fill="#FFD400" />
                      <circle
                        cx="8"
                        cy="8"
                        r="7.5"
                        stroke="black"
                        stroke-opacity="0.05"
                      />
                      <circle cx="8" cy="8" r="7" fill="#FFD400" />
                      <circle
                        cx="8"
                        cy="8"
                        r="6.5"
                        stroke="#F58220"
                        stroke-opacity="0.6"
                      />
                      <rect
                        opacity="0.6"
                        x="6.5"
                        y="4.5"
                        width="3"
                        height="7"
                        stroke="#F58220"
                      />
                      <line
                        x1="7.5"
                        y1="11"
                        x2="7.5"
                        y2="5"
                        stroke="#F58220"
                        stroke-opacity="0.6"
                      />
                    </svg>
                  </div>
                  <p className={style.titleText}>Coins</p>
                </div>
                <div className={style.coinsAmmount}>
                  <div className={style.inline}>
                    <p className={style.ammount}>34,200</p>
                    <svg
                      className={style.coinSvg}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <circle cx="8" cy="8" r="8" fill="#FFD400" />
                      <circle
                        cx="8"
                        cy="8"
                        r="7.5"
                        stroke="black"
                        stroke-opacity="0.05"
                      />
                      <circle cx="8" cy="8" r="7" fill="#FFD400" />
                      <circle
                        cx="8"
                        cy="8"
                        r="6.5"
                        stroke="#F58220"
                        stroke-opacity="0.6"
                      />
                      <rect
                        opacity="0.6"
                        x="6.5"
                        y="4.5"
                        width="3"
                        height="7"
                        stroke="#F58220"
                      />
                      <line
                        x1="7.5"
                        y1="11"
                        x2="7.5"
                        y2="5"
                        stroke="#F58220"
                        stroke-opacity="0.6"
                      />
                    </svg>
                  </div>
                  <p className={style.titleText}>Bonus</p>
                </div>
              </div>
            </div>
          </div>
          <div className={style.topTenContainer}>
            <div className={style.topUser}>
              <div className={style.topNameContainer}>
                <p className={style.numberTen}>4.</p>
                <h4 className={style.name}>titicaca_0241</h4>
              </div>
              <div className={style.tenAmmount}>
                <p className={style.ammount}>34,200</p>
                <svg
                  className={style.coinSvg}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle cx="8" cy="8" r="8" fill="#FFD400" />
                  <circle
                    cx="8"
                    cy="8"
                    r="7.5"
                    stroke="black"
                    stroke-opacity="0.05"
                  />
                  <circle cx="8" cy="8" r="7" fill="#FFD400" />
                  <circle
                    cx="8"
                    cy="8"
                    r="6.5"
                    stroke="#F58220"
                    stroke-opacity="0.6"
                  />
                  <rect
                    opacity="0.6"
                    x="6.5"
                    y="4.5"
                    width="3"
                    height="7"
                    stroke="#F58220"
                  />
                  <line
                    x1="7.5"
                    y1="11"
                    x2="7.5"
                    y2="5"
                    stroke="#F58220"
                    stroke-opacity="0.6"
                  />
                </svg>
              </div>
            </div>
            <div className={style.topUser}>
              <div className={style.topNameContainer}>
                <p className={style.numberTen}>5.</p>
                <h4 className={style.name}>titicaca_0241</h4>
              </div>
              <div className={style.tenAmmount}>
                <p className={style.ammount}>34,200</p>
                <svg
                  className={style.coinSvg}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle cx="8" cy="8" r="8" fill="#FFD400" />
                  <circle
                    cx="8"
                    cy="8"
                    r="7.5"
                    stroke="black"
                    stroke-opacity="0.05"
                  />
                  <circle cx="8" cy="8" r="7" fill="#FFD400" />
                  <circle
                    cx="8"
                    cy="8"
                    r="6.5"
                    stroke="#F58220"
                    stroke-opacity="0.6"
                  />
                  <rect
                    opacity="0.6"
                    x="6.5"
                    y="4.5"
                    width="3"
                    height="7"
                    stroke="#F58220"
                  />
                  <line
                    x1="7.5"
                    y1="11"
                    x2="7.5"
                    y2="5"
                    stroke="#F58220"
                    stroke-opacity="0.6"
                  />
                </svg>
              </div>
            </div>
            <div className={style.topUser}>
              <div className={style.topNameContainer}>
                <p className={style.numberTen}>6.</p>
                <h4 className={style.name}>titicaca_0241</h4>
              </div>
              <div className={style.tenAmmount}>
                <p className={style.ammount}>34,200</p>
                <svg
                  className={style.coinSvg}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle cx="8" cy="8" r="8" fill="#FFD400" />
                  <circle
                    cx="8"
                    cy="8"
                    r="7.5"
                    stroke="black"
                    stroke-opacity="0.05"
                  />
                  <circle cx="8" cy="8" r="7" fill="#FFD400" />
                  <circle
                    cx="8"
                    cy="8"
                    r="6.5"
                    stroke="#F58220"
                    stroke-opacity="0.6"
                  />
                  <rect
                    opacity="0.6"
                    x="6.5"
                    y="4.5"
                    width="3"
                    height="7"
                    stroke="#F58220"
                  />
                  <line
                    x1="7.5"
                    y1="11"
                    x2="7.5"
                    y2="5"
                    stroke="#F58220"
                    stroke-opacity="0.6"
                  />
                </svg>
              </div>
            </div>
            <div className={style.topUser}>
              <div className={style.topNameContainer}>
                <p className={style.numberTen}>7.</p>
                <h4 className={style.name}>titicaca_0241</h4>
              </div>
              <div className={style.tenAmmount}>
                <p className={style.ammount}>34,200</p>
                <svg
                  className={style.coinSvg}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle cx="8" cy="8" r="8" fill="#FFD400" />
                  <circle
                    cx="8"
                    cy="8"
                    r="7.5"
                    stroke="black"
                    stroke-opacity="0.05"
                  />
                  <circle cx="8" cy="8" r="7" fill="#FFD400" />
                  <circle
                    cx="8"
                    cy="8"
                    r="6.5"
                    stroke="#F58220"
                    stroke-opacity="0.6"
                  />
                  <rect
                    opacity="0.6"
                    x="6.5"
                    y="4.5"
                    width="3"
                    height="7"
                    stroke="#F58220"
                  />
                  <line
                    x1="7.5"
                    y1="11"
                    x2="7.5"
                    y2="5"
                    stroke="#F58220"
                    stroke-opacity="0.6"
                  />
                </svg>
              </div>
            </div>
            <div className={style.topUser}>
              <div className={style.topNameContainer}>
                <p className={style.numberTen}>8.</p>
                <h4 className={style.name}>titicaca_0241</h4>
              </div>
              <div className={style.tenAmmount}>
                <p className={style.ammount}>34,200</p>
                <svg
                  className={style.coinSvg}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle cx="8" cy="8" r="8" fill="#FFD400" />
                  <circle
                    cx="8"
                    cy="8"
                    r="7.5"
                    stroke="black"
                    stroke-opacity="0.05"
                  />
                  <circle cx="8" cy="8" r="7" fill="#FFD400" />
                  <circle
                    cx="8"
                    cy="8"
                    r="6.5"
                    stroke="#F58220"
                    stroke-opacity="0.6"
                  />
                  <rect
                    opacity="0.6"
                    x="6.5"
                    y="4.5"
                    width="3"
                    height="7"
                    stroke="#F58220"
                  />
                  <line
                    x1="7.5"
                    y1="11"
                    x2="7.5"
                    y2="5"
                    stroke="#F58220"
                    stroke-opacity="0.6"
                  />
                </svg>
              </div>
            </div>
            <div className={style.topUser}>
              <div className={style.topNameContainer}>
                <p className={style.numberTen}>9.</p>
                <h4 className={style.name}>titicaca_0241</h4>
              </div>
              <div className={style.tenAmmount}>
                <p className={style.ammount}>34,200</p>
                <svg
                  className={style.coinSvg}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle cx="8" cy="8" r="8" fill="#FFD400" />
                  <circle
                    cx="8"
                    cy="8"
                    r="7.5"
                    stroke="black"
                    stroke-opacity="0.05"
                  />
                  <circle cx="8" cy="8" r="7" fill="#FFD400" />
                  <circle
                    cx="8"
                    cy="8"
                    r="6.5"
                    stroke="#F58220"
                    stroke-opacity="0.6"
                  />
                  <rect
                    opacity="0.6"
                    x="6.5"
                    y="4.5"
                    width="3"
                    height="7"
                    stroke="#F58220"
                  />
                  <line
                    x1="7.5"
                    y1="11"
                    x2="7.5"
                    y2="5"
                    stroke="#F58220"
                    stroke-opacity="0.6"
                  />
                </svg>
              </div>
            </div>
            <div className={style.topUser}>
              <div className={style.topNameContainer}>
                <p className={style.numberTen}>10.</p>
                <h4 className={style.name}>titicaca_0241</h4>
              </div>
              <div className={style.tenAmmount}>
                <p className={style.ammount}>34,200</p>
                <svg
                  className={style.coinSvg}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle cx="8" cy="8" r="8" fill="#FFD400" />
                  <circle
                    cx="8"
                    cy="8"
                    r="7.5"
                    stroke="black"
                    stroke-opacity="0.05"
                  />
                  <circle cx="8" cy="8" r="7" fill="#FFD400" />
                  <circle
                    cx="8"
                    cy="8"
                    r="6.5"
                    stroke="#F58220"
                    stroke-opacity="0.6"
                  />
                  <rect
                    opacity="0.6"
                    x="6.5"
                    y="4.5"
                    width="3"
                    height="7"
                    stroke="#F58220"
                  />
                  <line
                    x1="7.5"
                    y1="11"
                    x2="7.5"
                    y2="5"
                    stroke="#F58220"
                    stroke-opacity="0.6"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={stars} alt="" className={style.stars} />
      <img src={lighting} alt="" className={style.lighting} />
      <img src={starsmid} alt="" className={style.starsmid} />
      <img src={eye} alt="" className={style.eye} />
      <img src={DesktopImg} alt="" className={style.DesktopImg} />
    </div>
  );
}
