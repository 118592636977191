import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import axios from "axios";
import { updateFeaturedOffers } from "../../store/actions/featuredOffers";
import { store } from "../../store";
import API from "../../api/";
import { useLocation } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { updateLeaders, clearLeaders } from "../../store/actions/temporary";
import { clearCampaigns, updateCampaigns } from "../../store/actions/campaigns";
export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (
    userAgent.match(/iPad/i) ||
    userAgent.match(/iPhone/i) ||
    userAgent.match(/iPod/i)
  ) {
    return "ios";
  } else if (userAgent.match(/Android/i)) {
    return "android";
  } else {
    return "desktop";
  }
}

export default function FetchOffers() {
  const Client = API.getInstance();
  const campaigns = useSelector((state) => state.campaignsReducer.campaigns);
  const logedIn = useSelector((state) => state.authReducer.authenticated);
  const featuredOffersSelector = useSelector(
    (state) => state.featuredOffersReducer.featuredOffers
  );
  const user = useSelector((state) => state.authReducer.user);
  const [totalOffers, setTotalOffers] = useState([]);
  const [temporaryFeaturedOffers, setTemporaryFeaturedOffers] = useState([]);
  const [fetchCompleted, setFetchCompleted] = useState(false);
  const [userCountry, setUserCountry] = useState(null);
  const token = useSelector(
    (state) => state.authReducer?.user?.signInUserSession?.accessToken?.jwtToken
  );
  const location = useLocation();

  {
    /*
    const fetchData = async () => {
    if (logedIn) {
      const token = user.signInUserSession.accessToken.jwtToken;
      await Promise.all(
        campaigns.map(async (campaign, index) => {
          console.log(campaign);
          if (campaign.offer_wall_url !== null) {
            const response = await axios.get(campaign.offer_wall_url, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                geo_code: userCountry,
                platform: getMobileOperatingSystem(),
              },
            });
            let offers = campaigns[index];
            offers["offers"] = response.data.offers;
            console.log(response);
            response.data.offers.map((offersContent) => {
              setTotalOffers((oldArray) => [...oldArray, offersContent]);
            });

            if (
              campaign.offer_wall_url != null &&
              campaign.offer_wall_url.includes("earn/kef/offer-wall")
            ) {
              if (
                response.data.offers.length != 0 &&
                response.data.offers.length > 8
              ) {
                store.dispatch(updateFeaturedOffers(response.data.offers));
                if (campaign.offer_wall_url.includes("superplay")) {
                  let newArray = featuredOffersSelector?.concat(
                    response.data.offers
                  );
                  store.dispatch(updateFeaturedOffers(newArray));
                }
              } else if (
                response.data.offers.lenth != 0 &&
                response.data.offers.length <= 8
              ) {
                const response2 = await Client.getFeaturedOffers(
                  getMobileOperatingSystem()
                );
                let newArray = response.data.offers?.concat(response2?.data);
                store.dispatch(updateFeaturedOffers(newArray));
                if (campaign.offer_wall_url.includes("superplay")) {
                  let newArray = response.data.offers?.concat(
                    featuredOffersSelector
                  );
                  store.dispatch(updateFeaturedOffers(newArray));
                }
              } else {
                const response2 = await Client.getFeaturedOffers(
                  getMobileOperatingSystem()
                );
                store.dispatch(updateFeaturedOffers(response2.data));
              }
            }
          }
        })
      );
    }
  };
  }

  {
    /* useEffect(async () => {
    if (!fetchCompleted) {
      await axios
        .get("https://ipapi.co/json/")
        .then((response) => {
          setUserCountry(response.data.country);
          setFetchCompleted(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [logedIn, campaigns, fetchCompleted]);*/
  }

  useEffect(() => {
    // if (fetchCompleted && userCountry !== null) {
    //   fetchData(); // Call the fetchData function
    // }
  }, [logedIn, campaigns, fetchCompleted]);

  useEffect(async () => {
    const response = await Client.getCampaigns();
    store.dispatch(updateCampaigns(response.data));
    const leaders = await Client.getLeaderBoard();
    store.dispatch(clearLeaders());
    store.dispatch(updateLeaders(leaders.data));
    // setData(response.data);
    //setIsLoading(false);
  }, [logedIn]);

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        if (!userCountry) {
          const response = await axios.get("https://ipapi.co/json/");
          setUserCountry(response.data.country);
        }

        if (logedIn && userCountry) {
          const response2 = await Client.getFeaturedOffers(
            userCountry,
            getMobileOperatingSystem()
          );
          store.dispatch(updateFeaturedOffers(response2.data));
        }
      } catch (error) {
        console.error("Error fetching offers:", error);
      }
    };

    fetchOffers();
  }, [logedIn, userCountry]);

  /*
  // DISABLE MIXPANEL
     useEffect(() => {
    // Track page view with Mixpanel
    mixpanel.track("Page view", {
      Page: location.pathname,
    });
  }, [location]);
   */

  return null;
}
