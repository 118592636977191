import React from "react";

import ayet from "../Ayet/ayet.txt";

export default function Ayet() {
  return (
    <div>
      <object data={ayet} width="300" height="200">
        Placeholder Text
      </object>
      ;
    </div>
  );
}
