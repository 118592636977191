import React, { useState } from "react";
import Google from "../../assets/Login/Google-Icon.png";
import cactus from "../../assets/OfferWall/cactus.svg";
import glass from "../../assets/OfferWall/glassess.svg";
import rotate from "../../assets/OfferWall/lines.svg";
import Offer from "./Offer/offer";
import Loading from "../../components/LoadingScreen";
import style from "./style.module.scss";
import kefLogo from "../../assets/logo.svg";
import { Auth } from "aws-amplify";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import API from "../../api";
import axios from "axios";
import { useRef } from "react";
import QRCodeCanvas from "qrcode.react";

export default function OfferWalliFrame() {
  const router = useLocation();
  const logedIn = useSelector((state) => state.authReducer.authenticated);
  const [filter, setFilter] = useState("all");
  const [offers, setOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expanded, setExpanded] = useState(null);
  const [qrCode, setQrCode] = useState(null);
  const Client = API.getInstance();
  const wrapperRef = useRef(null);
  const routerController = useHistory();

  const queryParameters = new URLSearchParams(window.location.search);
  const publisherUserId = queryParameters.get("subId") ?? 0;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const publisherId = queryParameters.get("publisherId") ?? 0;
    let country;
    await axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        country = response.data.country;
      })
      .catch((error) => {
        console.log(error);
      });
    const response = await Client.getIframeOffers(publisherId, country);
    setOffers(response.data.offers);
    setIsLoading(false);
  }, []);

  const filteredOffers = offers.filter((offer) => {
    if (offer.categories) {
      return offer.categories.some((category) => category.title == filter);
    }
    return false;
  });

  const handleUpdateQrUrl = (url) => {
    setQrCode(url);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        qrCode &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        setQrCode(null);
      }
    };

    if (qrCode) {
      document.addEventListener("click", handleClickOutside, false);
    }

    return () => {
      if (qrCode) {
        document.removeEventListener("click", handleClickOutside, false);
      }
    };
  }, [qrCode]);

  return (
    <div className={style.wrapper}>
      <div className={style.background}>
        <div className={style.container}>
          <div className={style.popupHeader}>
            <img src={kefLogo} alt="" className={style.kefLogo} />
          </div>
          <div className={style.category}>
            <button
              className={`${style.button} ${
                filter === "all" ? style.active : ""
              }`}
              onClick={() => {
                setFilter("all");
              }}
            >
              All
            </button>
            <button
              className={`${style.button} ${
                filter === "discover" ? style.active : ""
              }`}
              onClick={() => {
                setFilter("discover");
              }}
            >
              Discover
            </button>
            <button
              className={`${style.button} ${
                filter === '"games"' ? style.active : ""
              }`}
              onClick={() => {
                setFilter(`"games"`);
              }}
            >
              Games
            </button>
            <button
              className={`${style.button} ${
                filter === "Purchase" ? style.active : ""
              }`}
              onClick={() => {
                setFilter("Purchase");
              }}
            >
              Register
            </button>
          </div>
          <div className={style.totalContainer}>
            {isLoading ? (
              <div>
                <div className={style.glassesContainer}>
                  <img src={glass} alt="glass" className={style.glass} />
                  <img
                    src={rotate}
                    alt="rotate"
                    className={style.rotaterLeft}
                  />
                  <img
                    src={rotate}
                    alt="rotate"
                    className={style.rotaterRight}
                  />
                </div>
                <div className={style.loadingScreen}>
                  <Loading />
                </div>
              </div>
            ) : offers.length != 0 ? (
              filter != "all" ? (
                filteredOffers.map((offer) => (
                  <div
                    className={`${style.offerContainer} ${style.active}`}
                    key={offer.offer_id}
                  >
                    <div className={style.offer}>
                      <Offer
                        data={offer}
                        subUser={publisherUserId}
                        expanded={expanded}
                        handleClick={(id) => setExpanded(id)}
                        handleQrUrl={handleUpdateQrUrl}
                      />
                    </div>
                  </div>
                ))
              ) : (
                offers.map((offer) => (
                  <div
                    className={`${style.offerContainer} ${style.active}`}
                    key={offer.offer_id}
                  >
                    <div className={style.offer}>
                      <Offer
                        data={offer}
                        expanded={expanded}
                        handleClick={(id) => setExpanded(id)}
                        handleQrUrl={handleUpdateQrUrl}
                      />
                    </div>
                  </div>
                ))
              )
            ) : (
              <div className={style.unsupportedContainer}>
                <div className={style.imageContainer}>
                  <img src={cactus} alt="Image" className={style.cactus} />
                </div>
                <p className={style.countryTitle}>
                  No offer available at the moment!
                </p>
                <p className={style.countryDescription}>
                  Unfortunately, nothing comes up in your location.
                </p>
                <p className={style.countryTitle}>Please do try again later!</p>
              </div>
            )}
          </div>
        </div>
        {qrCode != null ? (
          <div className={style.background}>
            <div className={style.popupContainer}>
              <div className={style.loginPopup} ref={wrapperRef}>
                <div className={style.titleContainer}>
                  <p className={style.title}>QR Code</p>
                  <Link
                    className={style.closeButton}
                    to={router.pathname}
                    onClick={() => {
                      setQrCode(null);
                    }}
                  >
                    <svg width="20" height="20" viewBox="0 0 20 20">
                      <path
                        d="M11.1746 10.0002L16.4246 4.75849C16.5815 4.60157 16.6697 4.38874 16.6697 4.16682C16.6697 3.9449 16.5815 3.73207 16.4246 3.57515C16.2677 3.41823 16.0549 3.33008 15.833 3.33008C15.611 3.33008 15.3982 3.41823 15.2413 3.57515L9.99962 8.82515L4.75796 3.57515C4.60104 3.41823 4.38821 3.33008 4.16629 3.33008C3.94437 3.33008 3.73154 3.41823 3.57462 3.57515C3.4177 3.73207 3.32955 3.9449 3.32955 4.16682C3.32955 4.38874 3.4177 4.60157 3.57462 4.75849L8.82462 10.0002L3.57462 15.2418C3.49652 15.3193 3.43452 15.4115 3.39221 15.513C3.34991 15.6146 3.32812 15.7235 3.32812 15.8335C3.32812 15.9435 3.34991 16.0524 3.39221 16.154C3.43452 16.2555 3.49652 16.3477 3.57462 16.4252C3.65209 16.5033 3.74426 16.5653 3.84581 16.6076C3.94736 16.6499 4.05628 16.6717 4.16629 16.6717C4.2763 16.6717 4.38522 16.6499 4.48677 16.6076C4.58832 16.5653 4.68049 16.5033 4.75796 16.4252L9.99962 11.1752L15.2413 16.4252C15.3188 16.5033 15.4109 16.5653 15.5125 16.6076C15.614 16.6499 15.7229 16.6717 15.833 16.6717C15.943 16.6717 16.0519 16.6499 16.1534 16.6076C16.255 16.5653 16.3472 16.5033 16.4246 16.4252C16.5027 16.3477 16.5647 16.2555 16.607 16.154C16.6493 16.0524 16.6711 15.9435 16.6711 15.8335C16.6711 15.7235 16.6493 15.6146 16.607 15.513C16.5647 15.4115 16.5027 15.3193 16.4246 15.2418L11.1746 10.0002Z"
                        fill="#E87588"
                      />
                    </svg>
                  </Link>
                </div>
                <p className={style.qrCodeText}>
                  Please Scan QR Code To continue completing offer on your
                  device
                </p>
                <div className={style.qrCode}>
                  <QRCodeCanvas
                    id="qrCode"
                    value={qrCode}
                    size={160}
                    fgColor={"#ffffff"}
                    bgColor={"transparent"}
                    level={"H"}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
