import React from "react";

import { useRef } from "react";
import style from "./style.module.scss";

import Eyes from "../../assets/BlogArticle/eyes.svg";
import Image from "../../assets/BlogArticle/Rectangle.png";
import Brain from "../../assets/BlogArticle/brain.svg";
import blogmain from "../../assets/BlogMain/gaming4.png";

export default function BlogArticle6() {
  return (
    <div className={style.backgroundContainer}>
      <div className={style.dateContainer}>
        <p className={style.timeDate}>28/02/2023</p>
        <p className={style.timeline}>4 Minutes Read</p>
      </div>
      <div className={style.title}>
        Kef.gg - the leading community to make flexible money online
      </div>
      <div className={style.mainPicContainer}>
        <img src={blogmain} alt="" className={style.blogPhoto} />
        <img src={Eyes} alt="" className={style.eyes} />
        <img src={Brain} alt="" className={style.brain} />
      </div>
      <div className={style.subContent}>
        <p>
          As a young person in today's society, finding ways to make extra
          income can be a constant struggle. Traditional methods of earning
          money often require a significant investment of time, money, and
          effort. However, with the rise of the internet, there are now more
          opportunities than ever to make money without having to invest a
          significant amount of money upfront. One website that stands out in
          this regard is Kef.gg, the fastest growing website to make money
          online from anywhere.
        </p>
        <div className={style.subtitle}></div>
        <p>
          Kef.gg is a website that offers a variety of tasks that users can
          complete to earn money. These tasks can range from taking online
          surveys, to participating in cashback programs, to signing up for free
          trials. The beauty of Kef.gg is that all of these tasks are verified
          and tested, ensuring that users are getting paid for legitimate work.
        </p>
        <div className={style.subtitle}></div>
        <p>
          One of the biggest advantages of Kef.gg is that it allows users to
          make money from anywhere. The internet has made it possible to earn
          money from the comfort of your own home, and Kef.gg takes advantage of
          this by offering a wide range of tasks that can be completed online.
          This means that users can make money while they're at home, while
          they're on the go, or even while they're on vacation.
        </p>
        <div className={style.subtitle}></div>
        <p>
          Another advantage of Kef.gg is that it allows users to have more
          flexibility in their earning potential. Traditional methods of earning
          money, such as getting a part-time job or starting a business, often
          require a significant commitment of time and effort. With Kef.gg,
          users can earn extra money in their free time, allowing them to focus
          on other important things in their lives, such as their studies or
          hobbies.
        </p>
        <div className={style.subtitle}></div>
        <p>
          Kef.gg also allows users to have a variety of options for withdrawing
          their money. Users can receive their payments through PayPal, Bitcoin,
          VISA, and many other platforms. This means that users can choose the
          platform that works best for them and they can receive their money in
          a manner that they prefer.
        </p>
        <div className={style.subtitle}></div>
        <p>
          Kef.gg is an ideal website for young people looking to make extra
          money without having to invest a significant amount of money upfront.
          With a wide range of verified and tested tasks, the ability to make
          money from anywhere, and a variety of withdrawal options, Kef.gg
          offers young people the opportunity to take control of their financial
          situation and earn money in a way that fits their lifestyle. So, if
          you're a young person looking to make some extra money, consider
          exploring Kef.gg and all the opportunities it has to offer.
        </p>
        <div className={style.subtitle}></div>
        <p>
          Find it here <a href="https://kef.gg">Kef.gg</a>
        </p>
      </div>
    </div>
  );
}
