import React from "react";

import style from "./style.module.scss";

import Eyes from "../../assets/BlogArticle/eyes.svg";
import Brain from "../../assets/BlogArticle/brain.svg";
import blog13 from "./blog13.jpg";

export default function BlogArticle13() {
  return (
    <div className={style.backgroundContainer}>
      <div className={style.dateContainer}>
        <p className={style.timeDate}>04/06/2023</p>
        <p className={style.timeline}>3 Minutes Read</p>
      </div>
      <div className={style.title}>
        Unleash the Power of the Daily Rewards Calendar in Dice Dreams! / By Don
        E Lemon
      </div>
      <div className={style.mainPicContainer}>
        <img src={blog13} alt="" className={style.blogPhoto} />
        <img src={Eyes} alt="" className={style.eyes} />
        <img src={Brain} alt="" className={style.brain} />
      </div>
      <div className={style.subContent}>
        <p>
          Listen up, folks! We've got an insane feature in Dice Dreams that's
          gonna blow your mind. It's called the Daily Rewards Calendar, and it's
          your ticket to free rolls, coins, and a whole lot more. Let's dive in!
        </p>
        <div className={style.subtitle}></div>
        <p>
          Listen up, folks! We've got an insane feature in Dice Dreams that's
          gonna blow your mind. It's called the Daily Rewards Calendar, and it's
          your ticket to free rolls, coins, and a whole lot more. Let's dive in!
        </p>
        <div className={style.subtitle}></div>
        <p>
          Oh, and here's a pro tip: The Daily Rewards Calendar resets at 00:00
          according to your local time. So don't snooze on those rewards!
        </p>

        <div className={style.subtitle}></div>
        <p>
          Now, how do you get your hands on those sweet rolls and coins from the
          Daily Rewards Calendar? It's simple, my friend. Just open up the game
          every single day and claim those juicy rewards. They're tailored to
          each day, and they reset every week. So make sure you never miss a
          day, especially if you want to snag that big prize on the 7th day.
          It's gonna be epic!
        </p>
        <div className={style.subtitle}>That's all there is to it!</div>
        <p>
          The Daily Rewards Calendar is your ultimate source of free rolls,
          coins, and more in Dice Dreams. So get out there, claim those rewards,
          and unleash the power of daily greatness!
        </p>
        <div className={style.subtitle}>Part 2: Discovering Kef.gg</div>
        <p>
          Let us know if you something has been updated or you need any
          assistance!
        </p>
      </div>
    </div>
  );
}
