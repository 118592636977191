import { useState } from "react";

import style from "./style.module.scss";

export default function Acordion(props) {
  const { title, children } = props;
  const [active, setActive] = useState(false);
  return (
    <div className={style.questionContainer}>
      <div
        className={style.titleContainer}
        onClick={() => {
          setActive(!active);
        }}
      >
        <p className={style.istitle}>{title}</p>
        {!active ? (
          <svg width="23" height="22" viewBox="0 0 23 22" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.092 0C5.03251 0 0.120117 4.925 0.120117 11C0.120117 17.075 5.03251 22 11.092 22C17.1514 22 22.0638 17.075 22.0638 11C22.0638 4.925 17.1514 0 11.092 0ZM7.80739 8.793C7.61927 8.61084 7.36731 8.51005 7.10579 8.51233C6.84426 8.5146 6.59409 8.61977 6.40916 8.80518C6.22423 8.99059 6.11933 9.2414 6.11705 9.5036C6.11478 9.7658 6.21532 10.0184 6.39701 10.207L10.3868 14.207C10.5738 14.3945 10.8275 14.4998 11.092 14.4998C11.3564 14.4998 11.6101 14.3945 11.7972 14.207L15.7869 10.207C15.9686 10.0184 16.0691 9.7658 16.0669 9.5036C16.0646 9.2414 15.9597 8.99059 15.7748 8.80518C15.5898 8.61977 15.3397 8.5146 15.0781 8.51233C14.8166 8.51005 14.5647 8.61084 14.3765 8.793L11.092 12.086L7.80739 8.793Z"
              fill="#E87588"
            />
          </svg>
        ) : (
          <svg width="23" height="22" viewBox="0 0 23 22" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.9696 22C18.029 22 22.9414 17.075 22.9414 11C22.9414 4.925 18.029 -4.29455e-07 11.9696 -9.59189e-07C5.91012 -1.48892e-06 0.997722 4.925 0.997721 11C0.997721 17.075 5.91011 22 11.9696 22ZM15.2541 13.207C15.4423 13.3892 15.6942 13.49 15.9557 13.4877C16.2173 13.4854 16.4674 13.3802 16.6524 13.1948C16.8373 13.0094 16.9422 12.7586 16.9445 12.4964C16.9467 12.2342 16.8462 11.9816 16.6645 11.793L12.6748 7.793C12.4877 7.60553 12.234 7.50021 11.9696 7.50021C11.7051 7.50021 11.4514 7.60553 11.2644 7.793L7.27461 11.793C7.09292 11.9816 6.99238 12.2342 6.99466 12.4964C6.99693 12.7586 7.10183 13.0094 7.28676 13.1948C7.4717 13.3802 7.72187 13.4854 7.98339 13.4877C8.24492 13.49 8.49687 13.3892 8.68499 13.207L11.9696 9.914L15.2541 13.207Z"
              fill="#E94560"
            />
          </svg>
        )}
      </div>
      <div className={`${style.childrenWrapper} ${active ? style.active : ""}`}>
        {children}
      </div>
    </div>
  );
}
