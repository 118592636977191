import axios from "axios";
// import { store } from "store";
import { Auth } from "aws-amplify";
import { store } from "../store";

var BASE_URL = "https://kef.gg/api/";

if (process.env.REACT_APP_STATUS === "PRODUCTION") {
  var BASE_URL = "https://kef.gg/api/";
} else {
  var BASE_URL = "https://staging.kef.gg/api/";

  //var BASE_URL = "http://localhost/api/";
}

export default class Client {
  api = null;
  static instance;
  //   async refresh() {
  //     try {
  //       const response = await axios.post(
  //         `${BASE_URL}/auth/refreshToken`,
  //         {},
  //         {
  //           headers: {
  //             Authorization: `Bearer ${
  //               store.getState().sessionStorage.authReducer.refreshToken
  //             }`,
  //           },
  //         }
  //       );
  //       store.dispatch(
  //         storeToken(
  //           response.data.token,
  //           response.data.refreshToken,
  //           store.getState().sessionStorage.authReducer.kycStatus,
  //           store.getState().sessionStorage.authReducer.userName,
  //           store.getState().sessionStorage.authReducer.email
  //         )
  //       );
  //       return response;
  //     } catch (error) {
  //       switch (error.response.status) {
  //         case 401:
  //           store.dispatch(deleteToken());
  //           store.dispatch(
  //             pushActionMessage(
  //               "Your session has expired!",
  //               error.response.status
  //             )
  //           );
  //           break;
  //         case 404:
  //           store.dispatch(deleteToken());
  //           store.dispatch(
  //             pushActionMessage(
  //               "There is a problem with your internet connection!",
  //               error.response.status
  //             )
  //           );
  //           throw error;
  //           break;
  //         case 500:
  //           store.dispatch(deleteToken());
  //           store.dispatch(
  //             pushActionMessage(
  //               "Oops something went wrong!",
  //               error.response.status
  //             )
  //           );
  //           throw error;
  //           break;
  //       }
  //     }
  //   }

  static getInstance() {
    if (!Client.instance) {
      Client.instance = new Client();
    }
    return Client.instance;
  }

  constructor() {
    if (Client.instance) {
      throw Error("Client instance already exists");
    }
    this.api = axios.create({
      baseURL: BASE_URL,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    });
    this.api.interceptors.request.use(async (request) => {
      const auth = store.getState().authReducer.authenticated;
      if (auth) {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.accessToken.jwtToken;
        request.headers.Authorization = `Bearer ${token}`;
      }
      return request;
    });
    // this.api.interceptors.response.use(
    //   (response) => {
    //     return response;
    //   },
    //   async (error) => {
    //     const request = error.config;
    //     if (error.response.status === 401) {
    //       let response;
    //       try {
    //         response = await this.refresh();
    //         request.headers.Authorization = `Bearer ${response.data.token}`;
    //         const newRequset = axios(request);
    //         return newRequset;
    //       } catch (refreshError) {
    //         return Promise.reject(refreshError);
    //       }
    //     }
    //     return Promise.reject(error);
    //   }
    // );
  }
  getLastEarnings = () => this.api.get("last-earnings");

  getCampaigns = () => this.api.get("/earn/advertisers-list");
  checkEmail = (attEmail) =>
    this.api.get("/auth/check-email", {
      params: { email: attEmail },
    });
  getOnboardingATM = () => this.api.get("/onboarding/average-time-payout");
  getAvgRevenue = () => this.api.get("/onboarding/average-revenue-yesterday");
  getTotalRevenue = () => this.api.get("/onboarding/total-revenue");

  getProfileGeneral = () => this.api.get("/profile/general");
  getProfileEarnings = () => this.api.get("/profile/earnings");
  getUserBalance = () => this.api.get("/profile/balance");
  getTrackConversion = (clickID) =>
    this.api.get("/ref-conversion", {
      params: { click_id: clickID },
    });
  updateCustomCountry = (attCountry) =>
    this.api.get("/auth/update-affise-country", {
      params: { country: attCountry },
    });
  requestBitcoinWithdraw = (amount, adress) =>
    this.api.get("/withdraw/bitcoin", {
      params: { kef_coins_amount: amount, addr: adress },
    });
  requestEthereumWithdraw = (amount, adress) =>
    this.api.get("/withdraw/ethereum", {
      params: { kef_coins_amount: amount, addr: adress },
    });
  requestPaypalWithdraw = (amount, adress) =>
    this.api.get("/withdraw/ethereum", {
      params: { kef_coins_amount: amount, addr: adress },
    });
  setReferrer = (referrerUsername) =>
    this.api.get("/profile/set-referrer", {
      params: { referrer_username: referrerUsername },
    });
  getLeaderBoard = () => this.api.get("/leaders/");
  checkReedemCode = (code) =>
    this.api.get("/redeem/code", {
      params: { str_code: code },
    });
  getFeaturedOffers = (geoCode, platform) =>
    this.api.get("/earn/featured-list", {
      params: { geo_code: geoCode, platfrom: platform },
    });
  getRegularUser = (id, amount) =>
    this.api.get("/regular-user", {
      params: { id: id, amount: amount },
    });

  getSurveyQuestion = () => this.api.get("/question/get-question");
  setSurveyAnswer = (questionNumber, answer) => {
    return this.api.put("/question/update-answer", null, {
      params: {
        question_num: questionNumber,
        new_answer: answer,
      },
    });
  };
  getIframeOffers = (affiseId, geoCode) =>
    this.api.get("/earn/kef/iframe", {
      params: {
        affise_id: affiseId,
        geo_code: geoCode,
      },
    });
  getGcow = () => this.api.get("/giftcard/gcow-withdraw", {});
  getGcowWithdrawBalance = () =>
    this.api.get("/giftcard/gcow-withdraw-balance", {});
  getAllBlogs = (publicationId) =>
    this.api.get("/blogs/blogs-list", {
      params: {
        publicationId,
      },
    });
  getSingleBlog = (publicationId, postId) =>
    this.api.get("blogs/blog", {
      params: {
        publicationId,
        postId,
      },
    });
  getAffiseUrl = (affise_offer) =>
    this.api.post("/earn/create-offer-on-click", {
      offer_id: affise_offer.offer_id,
      advertiser: affise_offer.advertiser,
      icon: affise_offer.icon,
      title: affise_offer.title,
      description: affise_offer.description,
      short_description: affise_offer.short_description,
      instructions: affise_offer.instructions,
      amount: affise_offer.amount,
      url: affise_offer.url,
      conversion_type: affise_offer.conversion_type,
      os: affise_offer.os,
      categories: affise_offer.categories,
      store_url: affise_offer.store_url !== null ? affise_offer.store_url : "",
    });
  getUserCerteficates = () => this.api.get("dots-withdraw/certeficates");
  getUserDotsAllocations = () => this.api.get("dots-withdraw/dots-allocations");
  requestTreesWithdraw = (type, amount) =>
    this.api.get("/dots-withdraw/trees-withdraw", {
      params: { type: type, quantity: amount },
    });
  requestEmailTemplate = (offerId, address) =>
    this.api.get("email/offer-template-to-email", {
      params: {
        offer_id: offerId,
        addr: address,
      },
    });
}
