import {
  CLEAR_LEADERS,
  UPDATE_LEADERS,
  UPDATE_PROFILE,
  CLEAR_PROFILE,
} from "../actions/temporary";

export default (prevState = { leaders: [], profile: [] }, action) => {
  switch (action.type) {
    case UPDATE_LEADERS:
      return { ...prevState, leaders: action.leaders };
    case CLEAR_LEADERS: {
      return { ...prevState, leaders: [] };
    }
    case UPDATE_PROFILE:
      return { ...prevState, profile: action.profile };
    case CLEAR_PROFILE: {
      return { ...prevState, profile: [] };
    }
    default:
      return prevState;
  }
};
