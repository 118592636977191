import React from "react";
import Footer from "../../components/Footer";
import style from "./style.module.scss";

export default function Andrew() {
  return (
    <div className={style.faq}>
      <h1>Impact-Site-Verification: -1498147372</h1>
    </div>
  );
}
