import React from "react";

import { useRef } from "react";
import style from "./style.module.scss";

import Eyes from "../../assets/BlogArticle/eyes.svg";
import Image from "../../assets/BlogArticle/Rectangle.png";
import Brain from "../../assets/BlogArticle/brain.svg";
import blogmain from "../../assets/BlogMain/gaming4.png";

export default function BlogArticle9() {
  return (
    <div className={style.backgroundContainer}>
      <div className={style.dateContainer}>
        <p className={style.timeDate}>28/02/2023</p>
        <p className={style.timeline}>4 Minutes Read</p>
      </div>
      <div className={style.title}>
        CSR 2 Realistic Drag Racing: The Ultimate Mobile Racing Experience
      </div>
      <div className={style.mainPicContainer}>
        <img
          src="https://play-lh.googleusercontent.com/b260V5sPJ66Bn9NYhlKISABE6ZiCrEFxXs0993lOLgzFoHHz2FexnrtXbJal95LhkEo"
          alt=""
          className={style.blogPhoto}
        />
        <img src={Eyes} alt="" className={style.eyes} />
        <img src={Brain} alt="" className={style.brain} />
      </div>
      <div className={style.subContent}>
        <p>
          Are you a fan of high-speed racing games? Look no further than CSR 2
          Realistic Drag Racing. This mobile app is the ultimate drag racing
          experience, offering stunning graphics, realistic car customization,
          and intense multiplayer competition. Whether you're a casual gamer or
          a diehard racing enthusiast, CSR 2 is the perfect way to satisfy your
          need for speed.
        </p>
        <div className={style.subtitle}></div>
        <p>
          One of the best features of CSR 2 is its stunning graphics. The game
          uses cutting-edge technology to create realistic 3D environments and
          incredibly detailed cars. From the sleek curves of a Ferrari to the
          rugged lines of a Dodge Charger, every car is rendered with stunning
          accuracy. And the tracks themselves are just as impressive, with
          sun-kissed beaches, neon-lit city streets, and winding mountain roads
          that will take your breath away. But CSR 2 isn't just about looks. The
          game also offers a deep and engaging customization system that allows
          you to tune your car to perfection.
        </p>
        <div className={style.subtitle}></div>
        <p>
          With a huge selection of parts and upgrades, you can fine-tune every
          aspect of your ride, from the engine to the suspension to the tires.
          And with a robust paint and decal system, you can make your car truly
          unique.
        </p>
        <div className={style.subtitle}></div>
        <p>
          Try it out <a href="http://bit.ly/3nzkcNu">iOS</a>{" "}
          <a href="http://bit.ly/3lJDEH7">Android</a>
        </p>
        <div className={style.subtitle}></div>
        <p>
          Of course, the real heart of CSR 2 is the drag racing itself. The game
          offers a variety of race modes, from single-player cups to multiplayer
          tournaments. And with intuitive controls and realistic physics, every
          race feels intense and exciting. You'll have to master the art of
          shifting gears and launching off the line if you want to come out on
          top.
        </p>
        <div className={style.subtitle}></div>
        <p>
          But perhaps the best thing about CSR 2 is its community. With millions
          of players around the world, there's always someone to race against.
          And the game's social features make it easy to connect with other
          players, share your customizations, and show off your best times.
          Whether you're looking for friendly competition or intense rivalries,
          CSR 2 has you covered.
        </p>
        <div className={style.subtitle}></div>
        <p>
          Overall, CSR 2 Realistic Drag Racing is a must-play mobile app for
          anyone who loves racing games. With stunning graphics, deep
          customization, and intense competition, it's the ultimate way to
          satisfy your need for speed. So download the app today and get ready
          to burn some rubber!
        </p>
        <div className={style.subtitle}></div>
        <p>
          Try it out <a href="http://bit.ly/3nzkcNu">iOS</a>
          <a href="http://bit.ly/3lJDEH7">Android</a>
        </p>
        <div className={style.subtitle}></div>
        <iframe
          width="853"
          height="480"
          src="https://www.youtube.com/embed/2ZKNIQcfgpI"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div>
  );
}
