import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import style from "./style.module.scss";

import Monthly from "../../components/MonthlyTab";
import Daily from "../../components/DailyTab";
import EventGA from "../../components/EventGA";
import topStars from "../../assets/Leaders/star.svg";

function Switch({ active }) {
  switch (active) {
    case "monthly":
      return <Monthly />;
    case "daily":
      return <Daily />;
    default:
      return <Daily />;
  }
}

export default function Leaders() {
  const router = useLocation();
  const queryParams = new URLSearchParams(useLocation().search);
  const active = queryParams.get("active") || "daily";
  return (
    <div className={style.background}>
      <EventGA category={"PageView"} action={"(Page) Leaderboard"} />
      <div className={style.container}>
        <div className={style.totContainer}>
          <div className={style.leaderContainer}>
            <div className={style.leaderTitle}>LEADERS</div>
            <div className={style.leaderDescription}>
              Tasks You Complete Counts, Your opinions matter, these are the
              best!
            </div>
          </div>
          {/*<div className={style.buttonsContainer}>
            <Link
              className={`button ${
                active === "daily" ? "button-secondary" : ""
              }`}
              to={`${router.pathname}?active=daily`}
            >
              Daily
            </Link>
            <Link
              className={`button ${
                active === "monthly" ? "button-secondary" : ""
              }`}
              to={`${router.pathname}?active=monthly`}

            >
              Monthly
            </Link>
          </div>*/}
        </div>
        <div className={style.switchContainer}>
          <Switch active={active} />
        </div>
      </div>
      <img src={topStars} alt="topStars" className={style.topStars} />
    </div>
  );
}
