import React from "react";
import style from "./style.module.scss";

import payPalLogo from "../../assets/Withdraw/PayPal.png";
import bitcoinLogo from "../../assets/Withdraw/BitcoinLogo.png";
import BnBLogo from "../../assets/Withdraw/BnBLogo.png";
import USDCoin from "../../assets/Withdraw/USDCoin.png";
import ethereumLogo from "../../assets/Withdraw/EthereumLogo.png";
import dotsEcoLogo from "../../assets/Withdraw/DotsEcoLogo.png";
import LogIn from "../../components/LogInScreen";
import lighting from "./lighting.svg";
import metamask from "./metamask.svg";

import { Link, useHistory, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { useState } from "react";

export default function Withdraw() {
  const router = useLocation();
  const history = useHistory();
  const logedIn = useSelector((state) => state.authReducer.authenticated);
  const [clicked, setClicked] = useState(false);
  function setShadowColor(event) {
    const image = event.target;
    const aspectRatio = image.width / image.height;
    const canvas = document.createElement("canvas");
    canvas.height = 100;
    canvas.width = 100 * aspectRatio;
    const context = canvas.getContext("2d");
    context.drawImage(image, 0, 0, canvas.height, canvas.width);
    const details = context.getImageData(0, 0, canvas.width, canvas.height);
    const result = { r: 0, g: 0, b: 0 };
    let validPixles = 0;
    for (let i = 0; i < details.data.length; i += 8) {
      if (details.data[i + 3] !== 0) {
        validPixles++;
        result.r += details.data[i];
        result.g += details.data[i + 1];
        result.b += details.data[i + 2];
      }
    }
    result.r = Math.floor(result.r / validPixles);
    result.g = Math.floor(result.g / validPixles);
    result.b = Math.floor(result.b / validPixles);
    image.parentElement.style.boxShadow = `0px 0px 20px rgba(${result.r}, ${result.g}, ${result.b}, 0.3)`;
  }
  return (
    <div>
      <div className={style.allcontent}>
        <div className={style.subContainer}>
          <div className={style.title}>PAY OUT</div>
          <div className={style.subTitle}>
            Select how you would like us to pay you.
          </div>

          {/* <div className={style.subTitle}>
            Cash withdrawals are starting at $5.00 for PayPal and $0.10 for
            cryptocurrency.
          </div> */}
        </div>
        <div
          className={style.paymentContainer}
          onClick={() => setClicked(true)}
        >
          {/* <Link className={style.paymentLink} to="/?popup=paypal">
          <img
            className={style.linkImage}
            src={payPalLogo}
            alt="PayPal"
            onLoad={(event) => setShadowColor(event)}
          />
        </Link> */}

          {/*
          <div className={style.paymentLink}>
            <img
              className={style.linkImage}
              src={liteCoinLogo}
              alt="LiteCoin"
              onLoad={(event) => setShadowColor(event)}
            />
          </div>
          <div className={style.paymentLink}>
            <img
              className={style.linkImage}
              src={bitcoinLogo}
              alt="Bitcoin"
              onLoad={(event) => setShadowColor(event)}
            />
          </div> */}
          <Link
            className={style.paymentLink}
            to={`${router.pathname}?popup=bitcoin`}
          >
            <img
              className={style.linkImage}
              src={bitcoinLogo}
              alt="Bitcoin"
              onLoad={(event) => setShadowColor(event)}
            />
            <img className={style.lighting} src={lighting} alt="Image" />
          </Link>
          <Link
            className={style.paymentLink}
            to={`${router.pathname}?popup=ethereum`}
          >
            <img
              className={style.linkImage}
              src={ethereumLogo}
              alt="Ethereum"
              onLoad={(event) => setShadowColor(event)}
            />
            <img className={style.lighting} src={metamask} alt="Image" />
          </Link>
          <Link
            className={style.paymentLink}
            to={`${router.pathname}?popup=paypal`}
          >
            <img
              className={style.linkImage}
              src={payPalLogo}
              alt="Paypal"
              onLoad={(event) => setShadowColor(event)}
            />
          </Link>
          <Link
            className={style.paymentLink}
            to={`${router.pathname}?popup=dots`}
          >
            <img
              className={style.linkImage}
              src={dotsEcoLogo}
              alt="DotsEco"
              onLoad={(event) => setShadowColor(event)}
            />
          </Link>
          <Link
            className={style.paymentLink}
            /*to={`${router.pathname}?popup=ethereum`}*/
            to={router.pathname}
          >
            <img
              className={style.linkImageBlur}
              src={BnBLogo}
              alt="BnB Coin"
              onLoad={(event) => setShadowColor(event)}
            />
          </Link>
          <Link
            className={style.paymentLink}
            /*to={`${router.pathname}?popup=litecoin`}*/
            to={router.pathname}
          >
            <img
              className={style.linkImageBlur}
              src={USDCoin}
              alt="USDC Coin"
              onLoad={(event) => setShadowColor(event)}
            />
          </Link>
          {/* <div
            className={style.paymentLink}
            onClick={() => {
              history.push(`${router.pathname}?popup=withdraw-gcow`);
            }}
          >
            Gcow Logo Here!
          </div> */}
        </div>
      </div>
      {logedIn ? null : (
        <React.Fragment>
          <LogIn />
        </React.Fragment>
      )}
    </div>
  );
}
