import React from "react";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import style from "./style.module.scss";

export default function MetaMask({ handleAddress, handleIsConnected }) {
  const [haveMetamask, sethaveMetamask] = useState(true);
  const [accountAddress, setAccountAddress] = useState("");
  const [accountBalance, setAccountBalance] = useState("");
  const [isConnected, setIsConnected] = useState(false);

  const { ethereum } = window;
  const provider =
    window.ethereum != null
      ? new ethers.providers.Web3Provider(window.ethereum)
      : ethers.providers.getDefaultProvider();

  useEffect(() => {
    const { ethereum } = window;
    const checkMetamaskAvailability = async () => {
      if (!ethereum) {
        sethaveMetamask(false);
      }
      sethaveMetamask(true);
    };
    checkMetamaskAvailability();
  }, []);

  const connectWallet = async () => {
    try {
      if (!ethereum) {
        sethaveMetamask(false);
      }
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      let balance = await provider.getBalance(accounts[0]);
      let bal = ethers.utils.formatEther(balance);
      setAccountAddress(accounts[0]);
      setAccountBalance(bal);
      setIsConnected(true);
    } catch (error) {
      setIsConnected(false);
    }
    handleIsConnected(isConnected);
    handleAddress(accountBalance || null);
  };

  useEffect(() => {
    handleIsConnected(isConnected);
    handleAddress(accountAddress || null);
  }, [isConnected, accountAddress]);

  return (
    <div className={style.app}>
      <header className={style.header}>
        {haveMetamask ? (
          <div className={style.header}>
            {isConnected && <div className={style.card}></div>}
            {isConnected ? (
              <p className={style.info}> Connected Successfully to MetaMask</p>
            ) : (
              <button className={style.btn} onClick={connectWallet}>
                Connect to MetaMask!
              </button>
            )}
          </div>
        ) : (
          <div>
            <h1>Please Install MataMask</h1>
          </div>
        )}
      </header>
    </div>
  );
}
