import { LOGIN, LOGOUT } from "../actions/auth";

export default (prevState = { authenticated: false, user: null }, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...prevState, authenticated: true, user: action.user };
    case LOGOUT:
      return { ...prevState, authenticated: false, user: null };
    default:
      return prevState;
  }
};
