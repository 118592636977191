import React from "react";

import { useRef } from "react";
import style from "./style.module.scss";

import Eyes from "../../assets/BlogArticle/eyes.svg";
import Image from "../../assets/BlogArticle/Rectangle.png";
import Brain from "../../assets/BlogArticle/brain.svg";
import blogmain from "../../assets/BlogMain/gaming4.png";
import blog12 from "./blog12.jpg";

export default function BlogArticle12() {
  return (
    <div className={style.backgroundContainer}>
      <div className={style.dateContainer}>
        <p className={style.timeDate}>04/06/2023</p>
        <p className={style.timeline}>4 Minutes Read</p>
      </div>
      <div className={style.title}>
        Dice Dreams Stickers Guide: Everything You Need to Know / By Don E Lemon
      </div>
      <div className={style.mainPicContainer}>
        <img src={blog12} alt="" className={style.blogPhoto} />
        <img src={Eyes} alt="" className={style.eyes} />
        <img src={Brain} alt="" className={style.brain} />
      </div>
      <div className={style.subContent}>
        <p>
          This comprehensive guide provides valuable information about sticker
          packs, sticker trading, golden stickers, and sticker set rewards in
          Dice Dreams.
        </p>
        <div className={style.subtitle}>
          Understanding Stickers in Dice Dreams
        </div>
        <p>
          Stickers are collectible items that allow you to complete sticker sets
          and earn free rolls and rewards. To obtain free dice rewards in the
          game, you must collect sticker packs and assemble sticker sets.
        </p>
        <div className={style.subtitle}>Exploring Dice Dreams Sticker Sets</div>
        <p>
          A sticker set is a compilation of stickers based on a specific theme.
          Each set offers a gift reward that can be obtained by collecting all
          the stickers within that set. The rarer the sticker set, the greater
          the number of dice rewards it contains.
        </p>

        <div className={style.subtitle}></div>
        <p>
          Dice Dreams features numerous sticker sets, with each set comprising 9
          unique stickers. This presents an excellent opportunity to earn more
          dice in the game.
        </p>
        <div className={style.subtitle}>
          Obtaining Free Stickers in Dice Dreams
        </div>
        <p>
          You can acquire stickers in Dice Dreams through sticker packs and
          sticker trading. Sticker Packs Within the game, sticker packs contain
          a certain number of stickers and are available in three different
          types:
        </p>
        <div className={style.subtitle}></div>
        <ul>
          <li>Common Pack: Contains 2 stickers</li>
          <li>Golden Pack: Contains 4 stickers</li>
          <li>Dream Pack: Contains 8 stickers</li>
          <li>
            Guaranteed Set Sticker Pack: Contains 4 stickers from a specific set
          </li>
        </ul>
        <p>
          Sticker packs can be obtained by winning them in events, discovering
          them during the Treasure Hunt, or purchasing them with in-game coins.
        </p>
        <div className={style.subtitle}>Dice Dreams Sticker Trading</div>
        <p>
          Sticker trading enables you to exchange stickers with friends. You can
          trade common stickers at any time, with a daily limit of 10 stickers.
        </p>
        <div className={style.subtitle}></div>
        <p>
          For sticker trading, there is a dedicated Dice Dreams sticker trading
          group on Facebook. This group serves as a platform for trading
          stickers and finding new friends who play the Dice Dreams game.
        </p>
        <div className={style.subtitle}></div>
        <p>
          Golden Trade - During the Golden Trade event in Dice Dreams, you can
          exclusively trade golden stickers. The golden stickers eligible for
          trading are indicated in the Golden Trade popup. Please note that the
          stickers available for trading may change with each event.
        </p>
        <div className={style.subtitle}></div>
        <p>
          That's all there is to it! The Dice Dreams sticker set offers an
          excellent opportunity to earn free rolls.
        </p>
        <div className={style.subtitle}></div>
        <p>
          This guide provides you with all the necessary information about
          stickers and their features. Next, be sure to check out the Dice
          Dreams free rolls and coins page to learn more about additional ways
          to obtain free rewards.
        </p>
      </div>
    </div>
  );
}
