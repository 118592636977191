import React from "react";
import style from "./style.module.scss";

import ufo from "../../assets/EarningGuide/ufo.svg";
import earth from "../../assets/EarningGuide/earth.svg";

export default function EarningGuides() {
  return (
    <div className={style.earningGuides}>
      <div className={style.content}>
        <div className={style.bcgFig}>
          <div className={style.ufo}>
            <img className={style.ufoImg} src={ufo} alt="ufo" />
          </div>
          <div className={style.earth}>
            <img className={style.earthImg} src={earth} alt="earth" />
          </div>
        </div>
        <div className={style.title}>
          <h2>earning guide</h2>
          <p>
            If you are interested in getting paid to make a video for our
            YouTube channel, please contact us in <a href="#">support.</a>
          </p>
        </div>
        <div className={style.videos}>
          <div className={style.single}>
            <h3>earn free cash installing an app! (norton)</h3>
            <video
              src="https://www.w3schools.com/html/mov_bbb.mp4"
              type="video/mp4"
              poster="https://www.color-hex.com/palettes/13780.png"
              controls
            />
          </div>
          <div className={style.single}>
            <h3>earn free crypto installing a google chrome extension!</h3>
            <video
              src="https://www.w3schools.com/html/mov_bbb.mp4"
              type="video/mp4"
              poster="https://www.color-hex.com/palettes/13780.png"
              controls
            />
          </div>
          <div className={style.single}>
            <h3>ultimate spelling quiz answers.</h3>
            <video
              src="https://www.w3schools.com/html/mov_bbb.mp4"
              type="video/mp4"
              poster="https://www.color-hex.com/palettes/13780.png"
              controls
            />
          </div>
          <div className={style.single}>
            <h3>ultimate spot the difference quiz answers</h3>
            <video
              src="https://www.w3schools.com/html/mov_bbb.mp4"
              type="video/mp4"
              poster="https://www.color-hex.com/palettes/13780.png"
              controls
            />
          </div>
        </div>
      </div>
    </div>
  );
}
