import React from "react";

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import style from "./style.module.scss";
import API from "../../api";
import moment from "moment";

import Eyes from "../../assets/BlogArticle/eyes.svg";
import Image from "../../assets/BlogArticle/Rectangle.png";
import Brain from "../../assets/BlogArticle/brain.svg";
import blogmain from "../../assets/BlogMain/blogimage1.jpg";

export default function BlogArticle() {
  const Client = API.getInstance();
  const { id } = useParams();
  const [blogData, setBlogData] = useState(null);
  useEffect(async () => {
    const response = await Client.getSingleBlog(
      "pub_af1816e8-b6db-423d-882b-f97c77ae4019",
      id
    );
    console.log(response.data);
    setBlogData(response.data.data);
  }, [id]);

  return (
    <div className={style.backgroundContainer}>
      {blogData !== null && (
        <>
          <div className={style.dateContainer}>
            <p className={style.timeDate}>{`Published Date: ${moment
              .unix(blogData.publish_date)
              .format("MM/DD/YYYY")}`}</p>
            {/* <p className={style.timeline}>4 Minutes Read</p>*/}
          </div>
          <div className={style.title}>{blogData.title}</div>
          <div className={style.mainPicContainer}>
            <img
              src={blogData.thumbnail_url}
              alt=""
              className={style.blogPhoto}
            />
            <img src={Eyes} alt="" className={style.eyes} />
            <img src={Brain} alt="" className={style.brain} />
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: blogData.content.free.web }}
          />
        </>
      )}
    </div>
  );
}
