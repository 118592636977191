import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import style from "./style.module.scss";

import Login from "./Views/Auth/login";
import Register from "./Views/Auth/register";
import ForgotPassword from "./Views/Auth/forgotPassword";
import ConfirmAccount from "./Views/Auth/confirmAccount";
import ResendCode from "./Views/Auth/resendCode";
import ResetPassword from "./Views/Auth/resetPassword";
import Paypal from "./Views/Withdraw/paypal";
import Bitcoin from "./Views/Withdraw/bitcoin";
import Ethereum from "./Views/Withdraw/ethereum";
import LiteCoin from "./Views/Withdraw/liteCoin";
import Offers from "./Views/Offers/OfferWall";
import UserDetails from "./Views/User/details";
import Referal from "./Views/Referal";
import PromoCode from "./Views/PromoCode";
import CustomizedSurvey from "./Views/Surveys/customized-survey";
import Notification from "./Views/Notification";
import FeaturedOffer from "./Views/FeaturedOfferPopup";
import VerificationSent from "./Views/VerificationSent";
import Survey from "./Views/Survey";
import QuestionAndAnswer from "./Views/QuestinAndAnswer";
import QuestionAndAnswerConfirm from "./Views/QuestionAndAnswerConfirm";
import Gcow from "./Views/Withdraw/Gcow/gcow";
import Dots from "./Views/Withdraw/Dots/dots";
import PaymentNotification from "./Views/PaymentNotification";
import TreeCerteficates from "./Views/TreeCerteficates/treeCerteficates";
import NoOfferWallOffers from "./Views/NoOfferWallOffers";

function PopupSwitch(props) {
  switch (props.activePopup) {
    case "login":
      return <Login />;
    case "register":
      return <Register />;
    case "forgot-password":
      return <ForgotPassword />;
    case "confirm-account":
      return <ConfirmAccount />;
    case "resend-code":
      return <ResendCode />;
    case "reset-password":
      return <ResetPassword />;
    case "paypal":
      return <Paypal />;
    case "bitcoin":
      return <Bitcoin />;
    case "ethereum":
      return <Ethereum />;
    case "litecoin":
      return <LiteCoin />;
    case "dots":
      return <Dots />;
    case "tree-certeficates":
      return <TreeCerteficates />;
    case "offer-wall":
      return <Offers />;
    case "user-details":
      return <UserDetails />;
    case "promo-code":
      return <PromoCode />;
    case "referal":
      return <Referal />;
    case "customized-survey":
      return <CustomizedSurvey />;
    case "notification":
      return <Notification />;
    case "featured-offer":
      return <FeaturedOffer />;
    case "verification-sent":
      return <VerificationSent />;
    case "survey":
      return <Survey />;
    case "question-and-answer":
      return <QuestionAndAnswer />;
    case "question-and-answer-confirm":
      return <QuestionAndAnswerConfirm />;
    case "withdraw-gcow":
      return <Gcow />;
    case "payment-confirmation":
      return <PaymentNotification />;
    case "no-offer-wall-offers":
      return <NoOfferWallOffers />;
    default:
      return <div>Not found</div>;
  }
}

export default function PopupMenager() {
  const queryParams = new URLSearchParams(useLocation().search);
  const activePopup = queryParams.get("popup");
  const [active, setActive] = useState(Boolean(activePopup));
  useEffect(() => {
    setActive(Boolean(activePopup));
  });
  return active ? (
    <div className={style.container}>
      <div className={style.popupWraper}>
        <PopupSwitch activePopup={activePopup} />
      </div>
    </div>
  ) : null;
}
