import React, { useState } from "react";

import Select from "../Select";
import EventGA from "../../components/EventGA";

import style from "./style.module.scss";

export default function WithdrawTab() {
  const [active, setActive] = useState("earnings");
  const [filter, setFilter] = useState("offers");
  const [selected, setSelected] = useState("");
  return (
    <div className={style.container}>
      <EventGA category={"PageView"} action={"(Page) Earn"} />
      <div className={style.tabContainer}>
        <button
          className={`${style.tab} ${
            active === "earnings" ? style.active : ""
          }`}
          onClick={() => setActive("earnings")}
        >
          Earnings
        </button>
        <button
          className={`${style.tab} ${active === "offers" ? style.active : ""}`}
          onClick={() => setActive("offers")}
        >
          Hold offers
        </button>
        <button
          className={`${style.tab} ${
            active === "chargebacks" ? style.active : ""
          }`}
          onClick={() => setActive("chargebacks")}
        >
          Chargebacks
        </button>
      </div>
      <div className={style.buttonsContainer}>
        <button
          className={`button ${filter === "offers" ? "button-secondary" : ""}`}
          onClick={() => setFilter("offers")}
        >
          Offers
        </button>
        <button
          className={`button ${filter === "videos" ? "button-secondary" : ""}`}
          onClick={() => setFilter("videos")}
        >
          Videos
        </button>
      </div>
      <div className={style.inputContainer}>
        <Select
          placeholder="Offer walls"
          value={selected}
          setValue={setSelected}
          options={[
            { value: "01", label: "January" },
            { value: "02", label: "February" },
            { value: "03", label: "March" },
            { value: "04", label: "April" },
            { value: "05", label: "May" },
            { value: "06", label: "June" },
            { value: "07", label: "July" },
            { value: "08", label: "August" },
            { value: "09", label: "September" },
            { value: "10", label: "October" },
            { value: "11", label: "November" },
            { value: "12", label: "December" },
          ]}
        />
      </div>
      <div className={style.tableContainer}>
        <div className={style.tableHead}>
          <p className={style.label}>Name</p>
          <p className={style.label}>Coins</p>
          <p className={style.label}>Bonus</p>
          <p className={style.label}>Wall</p>
          <p className={style.label}>Date</p>
        </div>
        <div className={style.tableContent}>
          <div className={style.tableItem}>
            <p className={style.value}>Daily bonus</p>
            <p className={style.value}>
              10
              <img
                src="/assets/svgs/coin.svg"
                alt="coin"
                className={style.icon}
              />
            </p>
            <p className={style.value}> - </p>
            <p className={style.value}>KEF.GG</p>
            <p className={style.value}>10 days ago</p>
          </div>
          <div className={style.tableItem}>
            <p className={style.value}>Daily bonus</p>
            <p className={style.value}>
              10
              <img
                src="/assets/svgs/coin.svg"
                alt="coin"
                className={style.icon}
              />
            </p>
            <p className={style.value}> - </p>
            <p className={style.value}>KEF.GG</p>
            <p className={style.value}>10 days ago</p>
          </div>
          <div className={style.tableItem}>
            <p className={style.value}>Daily bonus</p>
            <p className={style.value}>
              10
              <img
                src="/assets/svgs/coin.svg"
                alt="coin"
                className={style.icon}
              />
            </p>
            <p className={style.value}> - </p>
            <p className={style.value}>KEF.GG</p>
            <p className={style.value}>10 days ago</p>
          </div>
          <div className={style.tableItem}>
            <p className={style.value}>Daily bonus</p>
            <p className={style.value}>
              10
              <img
                src="/assets/svgs/coin.svg"
                alt="coin"
                className={style.icon}
              />
            </p>
            <p className={style.value}> - </p>
            <p className={style.value}>KEF.GG</p>
            <p className={style.value}>10 days ago</p>
          </div>
        </div>
      </div>
    </div>
  );
}
