import React, { useState, useEffect } from "react";

import style from "./style.module.scss";

export default function Checkbox(props) {
  const { children, value, setValue } = props;
  const [id, setId] = useState();
  useEffect(() => {
    setId(
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    );
  }, []);
  return (
    <div className={style.container}>
      <input
        className={style.input}
        id={id}
        checked={value}
        onChange={(event) => setValue(event.target.checked)}
        type="checkbox"
      />
      <label className={style.customInput} htmlFor={id}>
        <div className={style.indicator} />
      </label>
      <label className={style.label} htmlFor={id}>
        {children}
      </label>
    </div>
  );
}
