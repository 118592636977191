import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";

import style from "./style.module.scss";

export default function Select(props) {
  const { label, placeholder, value, setValue, options } = props;
  const [focus, setFocus] = useState(false);
  const [activeLabel, setActiveLabel] = useState("");
  const inputRef = useRef();
  useEffect(() => {
    document.addEventListener("click", (event) => {
      try {
        if (inputRef.current.contains(event.target)) {
          setFocus(true);
        } else {
          setFocus(false);
        }
      } catch (error) {
        console.log(error);
      }
    });
  }, []);
  useEffect(() => {
    setFocus(false);
  }, [value]);
  return (
    <div className={style.container}>
      <p className={style.label}>{label}</p>
      <div
        className={`${style.input} ${focus ? style.focus : ""}`}
        ref={inputRef}
      >
        <p className={style.placeholder}>{value ? activeLabel : placeholder}</p>
        <div className={style.selectWrapper}>
          <div className={style.selectContainer}>
            {options.map(({ value, label }) => (
              <div className={style.option} key={value}>
                <p
                  className={style.optionLabel}
                  onClick={() => {
                    setValue(value);
                    setActiveLabel(label);
                  }}
                >
                  {label}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
