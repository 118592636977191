import React from "react";

import { useRef } from "react";
import style from "./style.module.scss";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Loading from "../../components/LoadingScreen";
import { useEffect } from "react";

export default function Handler() {
  const history = useHistory();
  const logedIn = useSelector((state) => state.authReducer.authenticated);

  useEffect(() => {
    if (logedIn) {
      history.push("/earn");
    }
  }, [logedIn]);
  return <div className={style.backgroundContainer}></div>;
}
