export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const Login = (user) => {
  return {
    type: LOGIN,
    user: user,
  };
};

export const Logout = () => {
  return {
    type: LOGOUT,
  };
};
