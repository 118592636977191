import React from "react";

import { useRef } from "react";
import style from "./style.module.scss";

import Eyes from "../../assets/BlogArticle/eyes.svg";
import Image from "../../assets/BlogArticle/Rectangle.png";
import Brain from "../../assets/BlogArticle/brain.svg";
import blogmain2 from "../../assets/BlogMain/blogimage2.jpg";

export default function BlogArticle2() {
  return (
    <div className={style.backgroundContainer}>
      <div className={style.dateContainer}>
        <p className={style.timeDate}>18/05/2022</p>
        <p className={style.timeline}>6 Minutes Read</p>
      </div>
      <div className={style.title}>Top 6 Play to Earn Cash Playing Apps</div>
      <div className={style.mainPicContainer}>
        <img src={blogmain2} alt="" className={style.blogPhoto} />
        <img src={Eyes} alt="" className={style.eyes} />
        <img src={Brain} alt="" className={style.brain} />
      </div>
      <div className={style.subContent}>
        <p>
          Are you interested in earning real money in your spare time playing
          games? The newest wave of NFT and blockchain games allows you to earn
          cryptocurrency as you play that you can sell for real cash. We’ve
          rounded up 6 of the best play-to-earn games we could find to get you
          started:
        </p>
        <div className={style.subtitle}>1. Axie Infinity</div>
        <p>
          Axie Infinity is a popular blockchain game made in 2021. It’s a
          monster collecting and breeding game where players train ‘axies’ in
          the World of Lunacia. It’s a massive hit internationally, with many
          players lifting themselves out of poverty through their skill at the
          game. As a collecting game, the value of individual Axies can go up or
          down based on supply and demand. Nevertheless, it’s insanely fun, with
          many great core gaming mechanics and a solid development team that
          keeps it competitive and balanced. You can play just the Adventure,
          Quest mode, or PvP.
          <div className={style.subtitle}>2. The Sandbox</div>
          <p>
            The Sandbox is an open-world building game, similar to Minecraft and
            Roblox. Land is the most important resource here, as each piece in
            the world is an NFT with real-world value, similar to the
            Metaverse’s proposed model. You can also make and spend money on
            SAND, which is for playing minigames within the game. For users who
            make their own games, these can be monetized. Really, it's more of a
            creator platform than anything else, letting other players try out
            your games and for you to earn money making them. It’s an expansive
            game platform with lots of potential. In addition, many major brands
            are bringing franchises such as The Walking Dead to partner with the
            game.
          </p>
          <div className={style.subtitle}>3. Kef.gg</div>
          <p>
            Kef.gg is a new platform where you can play indie games to earn
            crypto with lots of other crypto reward paths available, such as
            surveys, app installation, and more. You earn crypto by completing
            tasks on the site and there’s no need to spend money to get started.
            It uses gamification to turn offers into quests to get you earning
            the maximum amount of real cash fast. Getting started on your side
            hustle has never been easier, simpler, or more fun. There’s a wide
            variety of always changing indie games on the site, so Kef is best
            for people looking to try out a lot of different kinds of gameplay.
            You’re not locked down into any one type of game. Also, if you don’t
            like the offers one week, you can always come the next and find new
            options available. I like that the tasks available on the site are
            frequently updated and you can keep track of your earnings compared
            to others on their intuitive leaderboard. It’s engaging to compete
            for the highest daily and monthly score. So why not start earning
            that crypto yourself? There are already people earning $1000/month
            on the platform and it is super simple to try it out.
          </p>
          <div className={style.subtitle}>4. Gods Unchained</div>
          <p>
            Gods Unchained is a play-to-earn game for fans of Hearthstone or
            Magic Arena. A former Magic Arena Head Developer created it. It’s
            free to play to start, and then as you collect and earn cards, they
            are valued with crypto. Two players face off with cards that use the
            God of Death, Deception, Light, Magic, Nature, or War and use their
            spells and creatures to reduce their enemy’s health to zero. Gods
            Unchained pivoted into crypto in 2019, but trading prices proved too
            expensive. By 2021, they came up with a new marketplace, and now the
            game is thriving and worth checking out. Skill is the name of the
            game here, over and above the value of the cards.
          </p>
          <div className={style.subtitle}>5. DeFi Kingdoms</div>
          <p>
            DeFi Kingdoms is a chill game entwined with NFTs from its inception.
            It has beautiful medieval fantasy pixel art that looks straight out
            of role-playing games from the 90s. Think Stardew Valley. You can
            play DeFi kingdoms as a retro RPG, grinding and developing your
            character. While you’re adventuring, you can accumulate JEWEL tokens
            which can be converted to crypto. You can buy and sell NFT heroes
            plus plots of land to build your own kingdom. In addition, there are
            multiple ways to stake and player tournaments using your hero.
          </p>
          <div className={style.subtitle}>6. Splinterlands</div>
          <p>
            Another great play-to-earn trading card game, Splinterlands is more
            like Hearthstone than anything else for fans of the WoW-inspired
            game. Each card is an NFT, making it easy to buy and sell. Unlike
            Gods Unchained, there is an upfront cost to get started, but the
            actual game is quite good. It uses the Hive blockchain and works
            right in a browser. Compared to Hearthstone, it's more of a cross
            between an auto-battler, as you set up your cards to create a battle
            line-up against your opponents’ line-up.
          </p>
        </p>
      </div>
    </div>
  );
}
