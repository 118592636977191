import React, { useState, useRef, useEffect } from "react";

import box from "../../../assets/PromoCode/box.png";
import zshape from "../../../assets/PromoCode/zshape.png";
import kefLogo from "../../../assets/logo.svg";

import style from "./style.module.scss";
import Input from "../../../components/Input/index";
import API from "../../../api";
import { Link, useLocation, useHistory } from "react-router-dom";

export default function Featuredoffer() {
  const [redeemCode, setRedeemCode] = useState("");
  const wrapperRef = useRef(null);
  const router = useLocation();
  const routerController = useHistory();
  const Client = API.getInstance();

  const hasData = Boolean(
    routerController.location.state
      ? routerController.location.state.data
      : false
  );

  const [data, setData] = useState(
    hasData ? routerController.location.state.data : "NaN"
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      routerController.push(router.pathname);
    }
  };

  function handleLinkClick(event) {
    if (data.url.includes("tracking.kef.gg")) {
      const link = document.createElement("a");
      link.href = data.url;
      link.target = "_blank";
      const event = new MouseEvent("click");
      link.dispatchEvent(event);
    } else {
      Client.getAffiseUrl(data)
        .then((url) => {
          const link = document.createElement("a");
          link.href = url.data;
          link.target = "_blank";
          const event = new MouseEvent("click");
          link.dispatchEvent(event);
        })
        .catch((error) => {
          console.error("Error fetching URL:", error);
        });
    }
  }

  return (
    <div className={style.backgroundContainer}>
      <div className={style.wrapper} ref={wrapperRef}>
        <div className={style.content}>
          <div className={style.titleContainer}>
            <p className={style.title}>Featured Offer!</p>
            <Link className={style.closeButton} to={router.pathname}>
              <svg width="20" height="20" viewBox="0 0 20 20">
                <path
                  d="M11.1746 10.0002L16.4246 4.75849C16.5815 4.60157 16.6697 4.38874 16.6697 4.16682C16.6697 3.9449 16.5815 3.73207 16.4246 3.57515C16.2677 3.41823 16.0549 3.33008 15.833 3.33008C15.611 3.33008 15.3982 3.41823 15.2413 3.57515L9.99962 8.82515L4.75796 3.57515C4.60104 3.41823 4.38821 3.33008 4.16629 3.33008C3.94437 3.33008 3.73154 3.41823 3.57462 3.57515C3.4177 3.73207 3.32955 3.9449 3.32955 4.16682C3.32955 4.38874 3.4177 4.60157 3.57462 4.75849L8.82462 10.0002L3.57462 15.2418C3.49652 15.3193 3.43452 15.4115 3.39221 15.513C3.34991 15.6146 3.32812 15.7235 3.32812 15.8335C3.32812 15.9435 3.34991 16.0524 3.39221 16.154C3.43452 16.2555 3.49652 16.3477 3.57462 16.4252C3.65209 16.5033 3.74426 16.5653 3.84581 16.6076C3.94736 16.6499 4.05628 16.6717 4.16629 16.6717C4.2763 16.6717 4.38522 16.6499 4.48677 16.6076C4.58832 16.5653 4.68049 16.5033 4.75796 16.4252L9.99962 11.1752L15.2413 16.4252C15.3188 16.5033 15.4109 16.5653 15.5125 16.6076C15.614 16.6499 15.7229 16.6717 15.833 16.6717C15.943 16.6717 16.0519 16.6499 16.1534 16.6076C16.255 16.5653 16.3472 16.5033 16.4246 16.4252C16.5027 16.3477 16.5647 16.2555 16.607 16.154C16.6493 16.0524 16.6711 15.9435 16.6711 15.8335C16.6711 15.7235 16.6493 15.6146 16.607 15.513C16.5647 15.4115 16.5027 15.3193 16.4246 15.2418L11.1746 10.0002Z"
                  fill="#E87588"
                />
              </svg>
            </Link>
          </div>
          <div className={style.offerContainer}>
            <div className={style.headerContainer}>
              <img src={data.icon} alt="Image" className={style.icon} />
              <div className={style.explaination}>
                <p className={style.title}>{data.title}</p>
                {data && data?.description && data?.description != null && (
                  <p
                    className={style.description}
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  ></p>
                )}
              </div>
            </div>
            <p className={style.instructions}>{data.instructions}</p>
            <a onClick={handleLinkClick}>
              <div className={style.buttonContainer}>
                <p className={style.buttonText}>
                  {parseFloat(data.amount).toFixed(2)}
                </p>
                <svg
                  className={style.coinsEarnedIcon}
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <g clipPath="url(#clip0)">
                    <path
                      d="M9.9896 9.43309C11.9462 7.00561 12.0331 3.82942 10.1838 2.33887C8.33449 0.848319 5.24923 1.60785 3.29267 4.03533C1.33611 6.4628 1.24916 9.639 3.09847 11.1295C4.94778 12.6201 8.03304 11.8606 9.9896 9.43309Z"
                      fill="#FCEA2B"
                    />
                    <path
                      d="M10.1384 2.33891C9.40425 1.76694 8.47866 1.49831 7.55233 1.58835C8.26692 1.63202 8.95087 1.89401 9.51175 2.33891C11.3609 3.82952 11.2742 7.00558 9.3177 9.43302C8.12381 10.9143 6.50992 11.773 5.0127 11.8799C6.67131 12.0042 8.58133 11.1237 9.9442 9.43302C11.9007 7.00558 11.9878 3.82933 10.1384 2.33891Z"
                      fill="white"
                    />
                    <path
                      d="M10.7626 2.54376C10.6652 2.46526 10.5641 2.39142 10.4597 2.32248C10.4527 2.31911 10.4456 2.31619 10.4383 2.31373C10.4048 2.29717 10.3673 2.2903 10.3302 2.2939C10.292 2.29779 10.2561 2.3138 10.2277 2.3396C10.2213 2.34369 10.2152 2.34817 10.2094 2.35301C10.2057 2.3569 10.2046 2.36215 10.2012 2.36623C10.1979 2.37032 10.1933 2.37285 10.1902 2.37732C10.1869 2.38426 10.184 2.39141 10.1816 2.39871C10.1648 2.43252 10.1579 2.47042 10.1618 2.50798C10.1634 2.52009 10.1662 2.532 10.1701 2.54357C10.1772 2.56794 10.1898 2.59031 10.2071 2.6089C10.2112 2.61547 10.2158 2.62177 10.2207 2.62776C11.8303 4.11254 11.6625 7.04865 9.83861 9.31179C7.95211 11.6519 4.98333 12.3996 3.22089 10.9788C3.2095 10.9712 3.1973 10.9648 3.18453 10.9599L3.17383 10.9543C3.15528 10.9464 3.13559 10.9415 3.1155 10.9399C3.11025 10.9395 3.10519 10.9387 3.09994 10.9385C3.05843 10.9375 3.01785 10.9509 2.98522 10.9766C2.97865 10.98 2.97228 10.9838 2.96617 10.9879C2.96228 10.9912 2.96092 10.9959 2.95761 10.9996C2.9545 11.0029 2.95022 11.0044 2.9473 11.0079C2.94394 11.0137 2.94089 11.0196 2.93817 11.0256C2.92867 11.0392 2.92101 11.054 2.91542 11.0696C2.91352 11.0758 2.91197 11.0821 2.91075 11.0884C2.9061 11.1073 2.9044 11.1268 2.90569 11.1462C2.90608 11.1501 2.90686 11.1536 2.90744 11.1575C2.91017 11.1786 2.91649 11.1991 2.92611 11.2181C2.92767 11.2212 2.92961 11.224 2.93136 11.2269C2.93707 11.2395 2.94418 11.2514 2.95255 11.2625C3.08952 11.4137 3.23814 11.554 3.39705 11.6821C4.14007 12.2698 5.06335 12.5827 6.01058 12.5678C7.68086 12.5678 9.48919 11.6967 10.7799 10.0954C12.8107 7.57599 12.8027 4.18818 10.7624 2.54376H10.7626Z"
                      fill="#F1B31C"
                    />
                    <path
                      d="M4.01562 8.69543C4.02982 8.65032 4.13424 8.26493 4.14804 8.21982C5.29876 8.24043 5.35554 8.20212 5.52646 7.63493C5.84924 6.56393 5.94335 6.37221 6.26632 5.30121C6.37968 4.92554 6.35965 4.84348 5.82104 4.74082L5.49301 4.68035C5.50526 4.64068 5.57876 4.27687 5.59101 4.2374C6.38515 4.23777 7.17847 4.18653 7.96596 4.08398L6.89671 7.77843C6.72988 8.35515 6.75049 8.40143 7.85532 8.61493C7.84249 8.66043 7.73865 9.04621 7.72601 9.0919L4.01562 8.69543Z"
                      fill="#F1B31C"
                    />
                    <path
                      d="M10.3522 2.48438C10.451 2.54932 10.5471 2.61945 10.6405 2.69476C12.6005 4.27443 12.5951 7.53313 10.6287 9.97282C8.66226 12.4125 5.47901 13.1102 3.51901 11.5305C3.36804 11.4089 3.22683 11.2756 3.09668 11.1319"
                      stroke="white"
                      strokeWidth="0.444444"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M9.9896 9.43407C11.9462 7.00659 12.0331 3.8304 10.1838 2.33985C8.33449 0.849296 5.24923 1.60882 3.29267 4.0363C1.33611 6.46378 1.24916 9.63997 3.09847 11.1305C4.94778 12.6211 8.03304 11.8615 9.9896 9.43407Z"
                      stroke="white"
                      strokeWidth="0.444444"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M9.98926 9.43359L10.6282 9.97318"
                      stroke="white"
                      strokeWidth="0.444444"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M9.31738 10.1621L9.88049 10.7674"
                      stroke="white"
                      strokeWidth="0.444444"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M8.60547 10.7539L9.06786 11.3999"
                      stroke="white"
                      strokeWidth="0.444444"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M7.8252 11.2744L8.1892 11.9246"
                      stroke="white"
                      strokeWidth="0.444444"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M10.5068 8.70605L11.1853 9.17544"
                      stroke="white"
                      strokeWidth="0.444444"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M4.01562 8.69543C4.02982 8.65032 4.13424 8.26493 4.14804 8.21982C5.29876 8.24043 5.35554 8.20212 5.52646 7.63493C5.84924 6.56393 5.94335 6.37221 6.26632 5.30121C6.37968 4.92554 6.35965 4.84348 5.82104 4.74082L5.49301 4.68035C5.50526 4.64068 5.57876 4.27687 5.59101 4.2374C6.38515 4.23777 7.17847 4.18653 7.96596 4.08398L6.89671 7.77843C6.72988 8.35515 6.75049 8.40143 7.85532 8.61493C7.84249 8.66043 7.73865 9.04621 7.72601 9.0919L4.01562 8.69543Z"
                      stroke="white"
                      strokeWidth="0.444444"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <circle cx="7" cy="7" r="7" fill="#FFD400" />
                    <circle
                      cx="7"
                      cy="7"
                      r="6.5"
                      stroke="black"
                      strokeOpacity="0.05"
                    />
                    <circle cx="7" cy="7" r="6.125" fill="#FFD400" />
                    <circle
                      cx="7"
                      cy="7"
                      r="5.625"
                      stroke="#F58220"
                      strokeOpacity="0.6"
                    />
                    <rect
                      opacity="0.6"
                      x="5.75"
                      y="4"
                      width="2.5"
                      height="6"
                      stroke="#F58220"
                    />
                    <line
                      x1="6.5"
                      y1="9.625"
                      x2="6.5"
                      y2="4.375"
                      stroke="#F58220"
                      strokeOpacity="0.6"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
