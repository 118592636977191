import React from "react";
import style from "./style.module.scss";

import ufo from "../../assets/PaypalGuides/ufo.svg";
import lightning from "../../assets/PaypalGuides/lightning.svg";
import earth from "../../assets/PaypalGuides/earth.svg";

export default function PaypalGuides() {
  return (
    <div className={style.earningGuides}>
      <div className={style.content}>
        <div className={style.bcgFig}>
          <div className={style.ufo}>
            <img src={ufo} alt="Ufo Image" />
            <img src={lightning} alt="Light Image" />
          </div>
          <div className={style.earth}>
            <img src={earth} alt="Earth Image" />
          </div>
        </div>
        <div className={style.title}>
          <h2>paypal guide</h2>
          <p>
            Direct PayPal withdrawals are unavailable on the site. We understand
            this is a very popular withdrawal method, so we have made this guide
            to walk you through cashing out to PayPal using Coinbase. <br />
            <br /> Note: You must have at least 11,000 coins ($11.00 USD) to
            convert from BTC to your local currency using this method. We
            recommend saving your coins on the site and cashing out in one go to
            avoid price fluctuations.
          </p>
        </div>
        <div className={style.videos}>
          <div className={style.single}>
            <h3>
              Step 1 - Register a Coinbase account and withdraw your coins
            </h3>
            <video
              src="https://www.w3schools.com/html/mov_bbb.mp4"
              type="video/mp4"
              poster="https://www.color-hex.com/palettes/13780.png"
              controls
            />
          </div>
          <div className={style.single}>
            <h3>Step 2 - Withdraw your coins</h3>
            <video
              src="https://www.w3schools.com/html/mov_bbb.mp4"
              type="video/mp4"
              poster="https://www.color-hex.com/palettes/13780.png"
              controls
            />
          </div>
        </div>
      </div>
    </div>
  );
}
