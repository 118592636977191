import React, { useState, useRef, useEffect } from "react";
import style from "./style.module.scss";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

export default function NoOfferWallOffers() {
  const wrapperRef = useRef(null);
  const router = useLocation();
  const user = useSelector((state) => state.authReducer.user);

  const routerController = useHistory();
  const userID = user.attributes["custom:affise_pid"];
  const [country, setCountry] = useState("");

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      routerController.push(router.pathname);
    }
  };

  useEffect(() => {
    (async () => {
      axios
        .get("https://ipapi.co/json/")
        .then((response) => {
          let data = response.data;
          setCountry(data.country);
        })
        .catch((error) => {
          console.log(error);
        });
    })();
  }, []);

  useEffect(() => {
    const handleRouterReplace = () => {
      routerController.replace(`${router.pathname}?popup=offer-wall`, {
        url: null,
        iframe: `https://wall.adgaterewards.com/oK2TrA/${userID}`,
        image: `https://kef.gg/api/static/advertisers_images/AdGate.png`,
        captcha: false,
        country: country,
        fallback: true,
      });
    };

    const timer = setTimeout(handleRouterReplace, 3000);

    // Cleanup timeout if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={style.backgroundContainer}>
      <div className={style.wrapper} ref={wrapperRef}>
        <div className={style.content}>
          <div className={style.titleContainer}>
            <p className={style.title}>No Offers Available!</p>
            <Link className={style.closeButton} to={router.pathname}>
              <svg width="20" height="20" viewBox="0 0 20 20">
                <path
                  d="M11.1746 10.0002L16.4246 4.75849C16.5815 4.60157 16.6697 4.38874 16.6697 4.16682C16.6697 3.9449 16.5815 3.73207 16.4246 3.57515C16.2677 3.41823 16.0549 3.33008 15.833 3.33008C15.611 3.33008 15.3982 3.41823 15.2413 3.57515L9.99962 8.82515L4.75796 3.57515C4.60104 3.41823 4.38821 3.33008 4.16629 3.33008C3.94437 3.33008 3.73154 3.41823 3.57462 3.57515C3.4177 3.73207 3.32955 3.9449 3.32955 4.16682C3.32955 4.38874 3.4177 4.60157 3.57462 4.75849L8.82462 10.0002L3.57462 15.2418C3.49652 15.3193 3.43452 15.4115 3.39221 15.513C3.34991 15.6146 3.32812 15.7235 3.32812 15.8335C3.32812 15.9435 3.34991 16.0524 3.39221 16.154C3.43452 16.2555 3.49652 16.3477 3.57462 16.4252C3.65209 16.5033 3.74426 16.5653 3.84581 16.6076C3.94736 16.6499 4.05628 16.6717 4.16629 16.6717C4.2763 16.6717 4.38522 16.6499 4.48677 16.6076C4.58832 16.5653 4.68049 16.5033 4.75796 16.4252L9.99962 11.1752L15.2413 16.4252C15.3188 16.5033 15.4109 16.5653 15.5125 16.6076C15.614 16.6499 15.7229 16.6717 15.833 16.6717C15.943 16.6717 16.0519 16.6499 16.1534 16.6076C16.255 16.5653 16.3472 16.5033 16.4246 16.4252C16.5027 16.3477 16.5647 16.2555 16.607 16.154C16.6493 16.0524 16.6711 15.9435 16.6711 15.8335C16.6711 15.7235 16.6493 15.6146 16.607 15.513C16.5647 15.4115 16.5027 15.3193 16.4246 15.2418L11.1746 10.0002Z"
                  fill="#E87588"
                />
              </svg>
            </Link>
          </div>
          <p className={style.description}>
            Not available? no worries, Quick-start these to earn immediately
          </p>
          <div className={style.buttons}>
            <div className={style.buttonContainer}>
              <p className={style.buttonText}>Redirecting...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
