import { UPDATE_CHAT } from "../actions/chatActive";

export default (prevState = { boolean: false }, action) => {
  switch (action.type) {
    case UPDATE_CHAT:
      return { ...prevState, boolean: action.boolean };
    default:
      return prevState;
  }
};
