import React from "react";

import style from "./style.module.scss";

export default function LevelBar(props) {
  const { experience, curentLevel, achievements } = props;
  function convertToPercentage(value) {
    return (value / (curentLevel.end - curentLevel.start)) * 100;
  }
  return (
    <div className={style.wrapper}>
      <div
        className={style.hoverIndicator}
        style={{ left: `${convertToPercentage(experience)}%` }}
      ></div>
      <div className={style.container}>
        <div
          className={style.indicator}
          style={{ width: `${convertToPercentage(experience)}%` }}
        />
      </div>
      {achievements.map((achievement) => (
        <div
          className={style.item}
          style={{ left: `${convertToPercentage(achievement.position)}%` }}
        >
          <p className={style.level}>
            <span className={style.levelLabel}>
              {" "}
              Level:{achievement.price} x
            </span>
            <span className={style.multiplier}>{achievement.multiplier}</span>
          </p>
          <svg className={style.icon} viewBox="0 0 320 512">
            <path
              fill="var(--secondary-color)"
              d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"
            />
          </svg>
          <p className={style.value}>
            {achievement.price}
            <img
              src="/assets/svgs/coin.svg"
              alt="Coin"
              className={style.coin}
            />
          </p>
        </div>
      ))}
    </div>
  );
}
