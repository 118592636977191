import React from "react";

import { useRef } from "react";
import style from "./style.module.scss";

import Eyes from "../../assets/BlogArticle/eyes.svg";
import Image from "../../assets/BlogArticle/Rectangle.png";
import Brain from "../../assets/BlogArticle/brain.svg";
import blogmain from "../../assets/BlogMain/gaming4.png";
import googleBlog from "./googleBlog.jpg";

export default function BlogArticle10() {
  return (
    <div className={style.backgroundContainer}>
      <div className={style.dateContainer}>
        <p className={style.timeDate}>28/04/2023</p>
        <p className={style.timeline}>3 Minutes Read</p>
      </div>
      <div className={style.title}>
        Kef.gg VS Google / when you are the product
      </div>
      <div className={style.mainPicContainer}>
        <img src={googleBlog} alt="" className={style.blogPhoto} />
        <img src={Eyes} alt="" className={style.eyes} />
        <img src={Brain} alt="" className={style.brain} />
      </div>
      <div className={style.subContent}>
        <p>
          As I'm navigating my way through the world, I've always felt like
          something was off. I knew that companies like Google were making a lot
          of money, but I never really understood how they were doing it. It
          wasn't until I stumbled upon Kef.gg that I realized the true nature of
          the tech industry.
        </p>
        <div className={style.subtitle}></div>
        <p>
          At first, I was hesitant to believe that a startup like Kef.gg could
          compete with tech giants like Google. But as I dug deeper, I realized
          that Kef.gg had something that Google didn't: a business model that
          puts users first.
        </p>
        <div className={style.subtitle}></div>
        <p>
          While Google profits from selling users' attention to advertisers,
          Kef.gg is different. They recognize that users are valuable and
          deserve to be included in the value chain. By rewarding users with
          cryptocurrency for their attention and engagement, Kef.gg is creating
          a new kind of economy where everyone benefits.
        </p>

        <div className={style.subtitle}></div>
        <p>
          This realization was a turning point for me. I began to see that the
          tech industry doesn't have to be a zero-sum game. Instead, it's
          possible to create a sustainable business model that benefits both
          users and companies.
        </p>
        <div className={style.subtitle}>
          Part 1: The Problem with Google's Business Model
        </div>
        <p>
          The first part of my journey towards understanding the value of Kef.gg
          was realizing the problem with Google's business model. While Google
          provides a lot of useful services for free, they make their money by
          selling users' attention to advertisers. This means that users are the
          product, not the customer.
        </p>
        <div className={style.subtitle}>Part 2: Discovering Kef.gg</div>
        <p>
          The second part of my journey was discovering Kef.gg. At first, I was
          skeptical that a small startup could compete with tech giants like
          Google. But as I learned more about Kef.gg's business model, I began
          to see its potential.
        </p>
        <div className={style.subtitle}>
          Part 3: The Value of User Engagement
        </div>
        <p>
          The third part of my journey was recognizing the value of user
          engagement. While Google profits from users' attention, Kef.gg
          recognizes that users are valuable in their own right. By
          incentivizing users with cryptocurrency, kef.gg is creating a new kind
          of economy where everyone benefits.
        </p>
        <div className={style.subtitle}>Part 4: The Future of Tech</div>
        <p>
          The fourth and final part of my journey was realizing that the future
          of tech doesn't have to be a zero-sum game. By putting users first,
          companies like Kef.gg are creating a sustainable business model that
          benefits everyone.
        </p>
        <div className={style.subtitle}></div>
        <p>
          I'm excited to be a part of this new wave of tech startups that are
          changing the industry for the better.
        </p>
      </div>
    </div>
  );
}
