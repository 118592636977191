export const UPDATE_CLICKED = "UPDATE_CLICKED";
export const CLEAR_CLICKED = "CLEAR_CLICKED";

export const updateClicked = (clicked) => {
  return {
    type: UPDATE_CLICKED,
    clicked: clicked,
  };
};

export const clearClicked = () => {
  return {
    type: CLEAR_CLICKED,
  };
};
