import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { useSelector } from "react-redux";

import style from "./style.module.scss";
import { useEffect } from "react";

export default function Input(props) {
  const logedIn = useSelector((state) => state.authReducer.authenticated);
  const [value, setValue] = useState("");
  const [disabled, setDisabled] = useState(true);
  const ref = useRef(null);
  var Filter = require("bad-words"),
    filter = new Filter();
  filter.addWords("Scam", "scam", "SCAM", "CHEAT", "cheat", "Cheat");
  async function handleSubmit(event) {
    event.preventDefault();

    if (value != "") {
      props.handleResponse(filter.clean(value));
      setValue("");
      ref.current.value = "";
    }
  }

  useEffect(() => {
    logedIn ? setDisabled(false) : setDisabled(true);
  }, [logedIn]);
  return (
    <div className={style.container}>
      <form
        className={style.inputContainer}
        onSubmit={(event) => handleSubmit(event)}
      >
        <input
          className={style.input}
          type="text"
          placeholder={
            logedIn ? "Say something.." : "Please Sign In to use Chat"
          }
          onChange={(event) => setValue(event.target.value)}
          ref={ref}
          disabled={disabled}
        />
        <button className={`${"button-secondary"} ${style.chatButton}`}>
          Send
        </button>
      </form>
      <div className={style.iconsContainer}>
        <a
          className={style.iconContainer}
          href="https://twitter.com/kefgg1"
          target="_blank"
        >
          <svg viewBox="0 0 24 20">
            <path
              d="M24 2.43585C23.1169 2.83556 22.1679 3.1057 21.1719 3.22724C22.1887 2.60523 22.9693 1.62025 23.3369 0.446661C22.3704 1.0321 21.3129 1.44458 20.2103 1.66628C19.312 0.689532 18.0324 0.0791016 16.6162 0.0791016C13.8968 0.0791016 11.6921 2.32944 11.6921 5.10494C11.6921 5.49891 11.7357 5.88245 11.8196 6.25039C7.72744 6.04073 4.09931 4.03987 1.67072 0.999102C1.24697 1.7414 1.00416 2.60484 1.00416 3.5258C1.00416 5.26953 1.8735 6.80781 3.19462 7.70915C2.4127 7.68415 1.64798 7.46858 0.964313 7.08044C0.964031 7.10149 0.964031 7.12255 0.964031 7.14369C0.964031 9.57881 2.66128 11.6102 4.91372 12.0719C4.18864 12.2732 3.42809 12.3027 2.69016 12.158C3.31669 14.1548 5.13516 15.6078 7.28972 15.6485C5.60456 16.9964 3.48141 17.8 1.17459 17.8C0.777094 17.8 0.385219 17.7761 0 17.7297C2.17903 19.1558 4.76719 19.9878 7.54781 19.9878C16.6047 19.9878 21.5573 12.3293 21.5573 5.68781C21.5573 5.46982 21.5526 5.25307 21.543 5.03757C22.507 4.32626 23.339 3.44524 24 2.43585Z"
              fill="#E87588"
            />
          </svg>
        </a>
        <a
          className={style.iconContainer}
          href="https://discord.gg/Kd37dkr2a9"
          target="_blank"
        >
          <svg viewBox="0 0 26 20">
            <path
              d="M22.0244 1.66804C20.3193 0.878565 18.5155 0.317608 16.6605 0C16.4295 0.413367 16.1595 0.969347 15.9733 1.41166C13.9734 1.11407 11.9918 1.11407 10.0286 1.41166C9.84252 0.969447 9.56647 0.413367 9.33328 0C7.47651 0.317754 5.6711 0.88014 3.9652 1.67216C0.570571 6.74844 -0.349687 11.6985 0.110391 16.5785C2.36193 18.2423 4.5439 19.2531 6.6891 19.9145C7.22224 19.189 7.69352 18.4209 8.09808 17.6181C7.32786 17.3279 6.58533 16.9703 5.87934 16.5495C6.06513 16.4133 6.24654 16.2713 6.42331 16.1237C10.7013 18.1038 15.3496 18.1038 19.5767 16.1237C19.7542 16.2703 19.9356 16.4123 20.1205 16.5495C19.4134 16.9714 18.6695 17.3297 17.8977 17.6202C18.3046 18.4262 18.775 19.1951 19.3067 19.9165C21.454 19.2552 23.638 18.2444 25.8895 16.5785C26.4294 10.9214 24.9673 6.01668 22.0244 1.66794V1.66804ZM8.68095 13.5774C7.39669 13.5774 6.34349 12.391 6.34349 10.9462C6.34349 9.50151 7.37424 8.31306 8.68095 8.31306C9.98775 8.31306 11.0409 9.4994 11.0184 10.9462C11.0204 12.391 9.98775 13.5774 8.68095 13.5774ZM17.3189 13.5774C16.0347 13.5774 14.9816 12.391 14.9816 10.9462C14.9816 9.50151 16.0122 8.31306 17.3189 8.31306C18.6257 8.31306 19.6788 9.4994 19.6564 10.9462C19.6564 12.391 18.6257 13.5774 17.3189 13.5774Z"
              fill="#E87588"
            />
          </svg>
        </a>
        <a
          className={style.iconContainer}
          href="https://steamcommunity.com/groups/OGkef"
          target="_blank"
        >
          <svg viewBox="0 0 20 20">
            <path
              d="M9.98273 0C4.72031 0 0.409375 4.04726 0 9.19027L5.36906 11.4045C5.83788 11.0837 6.39467 10.9122 6.96484 10.9129C7.01813 10.9129 7.07078 10.9144 7.12266 10.9172L9.51062 7.46471V7.41637C9.51062 5.33892 11.2052 3.64834 13.2882 3.64834C15.3712 3.64834 17.0657 5.33892 17.0657 7.41637C17.0657 9.49375 15.3712 11.185 13.2882 11.185C13.2593 11.185 13.2312 11.1843 13.2024 11.1836L9.79672 13.6066C9.79891 13.6515 9.80031 13.6964 9.80031 13.7405C9.80031 15.3007 8.5282 16.5696 6.96484 16.5696C5.5925 16.5696 4.4443 15.5921 4.18484 14.2984L0.344531 12.7146C1.53383 16.9086 5.39711 19.9835 9.98273 19.9835C15.5154 19.9835 20 15.5095 20 9.99174C20 4.47336 15.5154 0 9.98273 0ZM6.2775 15.1608L5.04711 14.6536C5.26484 15.1066 5.6425 15.4856 6.14344 15.6937C7.22602 16.1439 8.47437 15.6317 8.92562 14.551C9.14409 14.0313 9.14538 13.4472 8.92922 12.9266C8.71297 12.4023 8.3043 11.9934 7.78031 11.7754C7.25922 11.5595 6.70133 11.5673 6.21117 11.7519L7.48266 12.2762C8.28125 12.6083 8.65891 13.5229 8.32594 14.3195C7.99367 15.1159 7.07609 15.4928 6.2775 15.1608ZM15.8054 7.41637C15.8054 6.0322 14.6759 4.90517 13.2884 4.90517C11.9002 4.90517 10.7708 6.0322 10.7708 7.41637C10.7708 8.80062 11.9002 9.92695 13.2884 9.92695C14.6759 9.92695 15.8054 8.80062 15.8054 7.41637ZM11.4015 7.41212C11.4015 6.37058 12.2484 5.52641 13.2921 5.52641C14.3365 5.52641 15.1834 6.37058 15.1834 7.41212C15.1834 8.45367 14.3365 9.29792 13.292 9.29792C12.2484 9.29792 11.4014 8.45367 11.4014 7.4122L11.4015 7.41212Z"
              fill="#E87588"
            />
          </svg>
        </a>
      </div>
    </div>
  );
}
