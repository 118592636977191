import React from "react";

import { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import API from "../../api";
import style from "./style.module.scss";
import moment from "moment";
import Loading from "../../components/LoadingScreen";
import Blogs from "../../assets/BlogsCategories/BlogsText.png";
import Guides from "../../assets/BlogsCategories/GuidesText.png";

import Cloud from "../../assets/BlogMain/cloud.svg";

import blogmain from "../../assets/BlogMain/blogimage1.jpg";
import blogmain2 from "../../assets/BlogMain/blogimage2.jpg";
import blogmain3 from "../../assets/BlogMain/blogimage3.jpg";
//import gaming1 from "../../assets/BlogMain/gaming1.png";
import gaming2 from "../../assets/BlogMain/gaming2.png";
import gaming3 from "../../assets/BlogMain/gaming3.png";
import gaming4 from "../../assets/BlogMain/gaming4.png";
import googleBlog from "../BlogArticle10/googleBlog.jpg";
import blog11 from "../BlogArticle11/blog11.png";
import blog12 from "../BlogArticle12/blog12.jpg";
import blog13 from "../BlogArticle13/blog13.jpg";
import blog14 from "../BlogArticle14/blog14.jpg";
import blog15 from "../BlogArticle15/blog15.jpg";

import EventGA from "../../components/EventGA";
//====== SWIPER IMPORTS ========//

import { Navigation, Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

import "swiper/swiper.scss";
import "swiper/modules/navigation/navigation.scss";
import "swiper/modules/pagination/pagination.scss";

export default function AllBlogCategories() {
  const Client = API.getInstance();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const history = useHistory();
  const [blogs, setBlogs] = useState([]);
  const lastBlog = blogs[blogs.length - 1];

  useEffect(async () => {
    const response = await Client.getAllBlogs(
      "pub_af1816e8-b6db-423d-882b-f97c77ae4019"
    );
    setBlogs(response.data);
  }, []);

  if (blogs.length === 0) {
    return (
      <div className={style.loadingWrapper}>
        <Loading />
      </div>
    );
  }

  return (
    <div className={style.backgroundContainer}>
      <div
        className={style.otherBlogsContainer}
        onClick={() => {
          history.push(`/blog-category/blogs`);
        }}
      >
        <div className={style.imageWrapper}>
          <p className={style.blogImage}>Blogs</p>
        </div>
        <div className={style.descriptionContainer}>
          <div className={style.dateContainer}></div>
          <p className={style.blogTitle}>{"Blogs"}</p>
          <div className={style.blogSubtitle}>
            {
              "Here you can find all blogs posted by our commuity about earning online."
            }
          </div>
        </div>
      </div>
      <div
        className={style.otherBlogsContainer}
        onClick={() => {
          history.push(`/blog-category/guides`);
        }}
      >
        <div className={style.imageWrapper}>
          <p className={style.blogImage}>Guides</p>
        </div>
        <div className={style.descriptionContainer}>
          <p className={style.blogTitle}>{"Guides"}</p>
          <div className={style.blogSubtitle}>
            {"Here you can find all guidelines how to earn money online."}
          </div>
        </div>
      </div>
      <div
        className={style.otherBlogsContainer}
        onClick={() => {
          history.push(`/blog-category/gaming`);
        }}
      >
        <div className={style.imageWrapper}>
          <p className={style.blogImage}>Games</p>
        </div>
        <div className={style.descriptionContainer}>
          <div className={style.dateContainer}></div>
          <p className={style.blogTitle}>{"Gaming"}</p>
          <div className={style.blogSubtitle}>
            {"Here you can find all gaming guides."}
          </div>
        </div>
      </div>
    </div>
  );
}
