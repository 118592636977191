export const UPDATE_LEADERS = "UPDATE_LEADERS";
export const CLEAR_LEADERS = "CLEAR_LEADERS";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";

export const updateLeaders = (leaders) => {
  return {
    type: UPDATE_LEADERS,
    leaders: leaders,
  };
};

export const clearLeaders = () => {
  return {
    type: CLEAR_LEADERS,
  };
};

export const updateProfile = (profile) => {
  return {
    type: UPDATE_PROFILE,
    profile: profile,
  };
};

export const clearProfile = () => {
  return {
    type: CLEAR_PROFILE,
  };
};
