import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import style from "./style.module.scss";
import API from "../../api/";
import stars from "../../assets/DailyTab/stars.svg";
import lighting from "../../assets/DailyTab/lightning.svg";
import starsmid from "../../assets/DailyTab/starsmid.svg";
import eye from "../../assets/DailyTab/eye.svg";
import DesktopImg from "../../assets/DailyTab/Desktop.svg";
import Loading from "../../components/LoadingScreen";

export default function DailyTab() {
  const monthCoins = "100";
  const totalUsers = "23440";
  const Client = API.getInstance();
  const [topThree, setTopThree] = useState([]);
  const [rest, setRest] = useState([]);
  const [countdownValues, setCountdownValues] = useState([]);
  const leaders = useSelector((state) => state.temporary.leaders);
  useEffect(() => {
    if (leaders !== undefined && leaders != 0) {
      setTopThree(leaders.top3);
      setRest(leaders.rest);
    }
  }, [leaders]);

  useEffect(() => {
    const updateCountdown = () => {
      const now = moment();
      const endOfMonth = moment().endOf("month");
      const duration = moment.duration(endOfMonth.diff(now));

      const days = padNumber(duration.days());
      const hours = padNumber(duration.hours());
      const minutes = padNumber(duration.minutes());
      const seconds = padNumber(duration.seconds());

      // Split all values into individual digits, even if less than 10
      const countdownArray = [
        ...days.split("").map(Number),
        ...hours.split("").map(Number),
        ...minutes.split("").map(Number),
        ...seconds.split("").map(Number),
      ];

      setCountdownValues(countdownArray);
    };

    updateCountdown(); // Initial update

    const intervalId = setInterval(updateCountdown, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, []);

  const padNumber = (number) => {
    return number.toString().padStart(2, "0");
  };

  return (
    <div className={style.backgroundContainer}>
      <div className={style.container}>
        {topThree.length === 0 && rest.length === 0 ? (
          <Loading />
        ) : (
          <div className={style.endContainer}>
            <div className={style.theEndContainer}>
              <div className={style.endTitle}>END IN</div>
              <div className={style.countDown}>
                <h4 className={style.timeChar}>D</h4>
                <h4 className={style.timeNum}>{countdownValues?.[0]}</h4>
                <h4 className={style.timeNum}>{countdownValues?.[1]}</h4>
                <h4 className={style.timeChar}>H</h4>
                <h4 className={style.timeNum}>{countdownValues?.[2]}</h4>
                <h4 className={style.timeNum}>{countdownValues?.[3]}</h4>
                <h4 className={style.timeChar}>M</h4>
                <h4 className={style.timeNum}>{countdownValues?.[4]}</h4>
                <h4 className={style.timeNum}>{countdownValues?.[5]}</h4>
                <h4 className={style.timeChar}>S</h4>
                <h4 className={style.timeNum}>{countdownValues?.[6]}</h4>
                <h4 className={style.timeNum}>{countdownValues?.[7]}</h4>
              </div>
              <h5 className={style.userEarned}>
                You earned{" "}
                <span className={style.colorRed}>
                  {monthCoins} coins this month{" "}
                </span>{" "}
                and ranked
                <span className={style.colorRed}>
                  {" "}
                  out of {totalUsers} users
                </span>
              </h5>
            </div>
            <div className={style.topUsers}>
              <div className={style.users} id={style.top}>
                <h5 className={style.userRank}>1st</h5>
                <img
                  className={style.avatar}
                  src="https://staging.kef.gg/api/static/user_avatars/3.svg"
                  alt="Avatar"
                />
                <h5 className={style.userName}>
                  {topThree[0]?.Username ?? "Loading..."}
                </h5>
                <div className={style.coinsContainer}>
                  <div className={style.coinsAmmount}>
                    <div className={style.inline}>
                      <p className={style.ammount}>
                        {topThree[0]?.coins_sum ?? "Loading..."}
                      </p>
                      <svg
                        className={style.coinSvg}
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <circle cx="8" cy="8" r="8" fill="#FFD400" />
                        <circle
                          cx="8"
                          cy="8"
                          r="7.5"
                          stroke="black"
                          stroke-opacity="0.05"
                        />
                        <circle cx="8" cy="8" r="7" fill="#FFD400" />
                        <circle
                          cx="8"
                          cy="8"
                          r="6.5"
                          stroke="#F58220"
                          stroke-opacity="0.6"
                        />
                        <rect
                          opacity="0.6"
                          x="6.5"
                          y="4.5"
                          width="3"
                          height="7"
                          stroke="#F58220"
                        />
                        <line
                          x1="7.5"
                          y1="11"
                          x2="7.5"
                          y2="5"
                          stroke="#F58220"
                          stroke-opacity="0.6"
                        />
                      </svg>
                    </div>
                    <p className={style.titleText}>Coins</p>
                  </div>
                  {/* <div className={style.coinsAmmount}>
                  <div className={style.inline}>
                    <p className={style.ammount}>34,200</p>
                    <svg
                      className={style.coinSvg}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <circle cx="8" cy="8" r="8" fill="#FFD400" />
                      <circle
                        cx="8"
                        cy="8"
                        r="7.5"
                        stroke="black"
                        stroke-opacity="0.05"
                      />
                      <circle cx="8" cy="8" r="7" fill="#FFD400" />
                      <circle
                        cx="8"
                        cy="8"
                        r="6.5"
                        stroke="#F58220"
                        stroke-opacity="0.6"
                      />
                      <rect
                        opacity="0.6"
                        x="6.5"
                        y="4.5"
                        width="3"
                        height="7"
                        stroke="#F58220"
                      />
                      <line
                        x1="7.5"
                        y1="11"
                        x2="7.5"
                        y2="5"
                        stroke="#F58220"
                        stroke-opacity="0.6"
                      />
                    </svg>
                  </div>
                  <p className={style.titleText}>Bonus</p>
                </div> */}
                </div>
              </div>
              <div className={style.users2}>
                <h5 className={style.userRank}>2nd</h5>
                <img
                  className={style.avatar}
                  src="https://staging.kef.gg/api/static/user_avatars/3.svg"
                  alt="Avatar"
                />
                <h5 className={style.userName}>
                  {topThree[1]?.Username ?? "Loading..."}
                </h5>
                <div className={style.coinsContainer}>
                  <div className={style.coinsAmmount}>
                    <div className={style.inline}>
                      <p className={style.ammount}>
                        {topThree[1]?.coins_sum ?? "Loading..."}
                      </p>
                      <svg
                        className={style.coinSvg}
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <circle cx="8" cy="8" r="8" fill="#FFD400" />
                        <circle
                          cx="8"
                          cy="8"
                          r="7.5"
                          stroke="black"
                          stroke-opacity="0.05"
                        />
                        <circle cx="8" cy="8" r="7" fill="#FFD400" />
                        <circle
                          cx="8"
                          cy="8"
                          r="6.5"
                          stroke="#F58220"
                          stroke-opacity="0.6"
                        />
                        <rect
                          opacity="0.6"
                          x="6.5"
                          y="4.5"
                          width="3"
                          height="7"
                          stroke="#F58220"
                        />
                        <line
                          x1="7.5"
                          y1="11"
                          x2="7.5"
                          y2="5"
                          stroke="#F58220"
                          stroke-opacity="0.6"
                        />
                      </svg>
                    </div>
                    <p className={style.titleText}>Coins</p>
                  </div>
                  {/* <div className={style.coinsAmmount}>
                  <div className={style.inline}>
                    <p className={style.ammount}>34,200</p>
                    <svg
                      className={style.coinSvg}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <circle cx="8" cy="8" r="8" fill="#FFD400" />
                      <circle
                        cx="8"
                        cy="8"
                        r="7.5"
                        stroke="black"
                        stroke-opacity="0.05"
                      />
                      <circle cx="8" cy="8" r="7" fill="#FFD400" />
                      <circle
                        cx="8"
                        cy="8"
                        r="6.5"
                        stroke="#F58220"
                        stroke-opacity="0.6"
                      />
                      <rect
                        opacity="0.6"
                        x="6.5"
                        y="4.5"
                        width="3"
                        height="7"
                        stroke="#F58220"
                      />
                      <line
                        x1="7.5"
                        y1="11"
                        x2="7.5"
                        y2="5"
                        stroke="#F58220"
                        stroke-opacity="0.6"
                      />
                    </svg>
                  </div>
                  <p className={style.titleText}>Bonus</p>
                </div> */}
                </div>
              </div>
              <div className={style.users}>
                <h5 className={style.userRank}>3rd</h5>
                <img
                  className={style.avatar}
                  src="https://staging.kef.gg/api/static/user_avatars/3.svg"
                  alt="Avatar"
                />
                <h5 className={style.userName}>
                  {topThree[2]?.Username ?? "Loading..."}
                </h5>
                <div className={style.coinsContainer}>
                  <div className={style.coinsAmmount}>
                    <div className={style.inline}>
                      <p className={style.ammount}>
                        {topThree[2]?.coins_sum ?? "Loading..."}
                      </p>
                      <svg
                        className={style.coinSvg}
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <circle cx="8" cy="8" r="8" fill="#FFD400" />
                        <circle
                          cx="8"
                          cy="8"
                          r="7.5"
                          stroke="black"
                          stroke-opacity="0.05"
                        />
                        <circle cx="8" cy="8" r="7" fill="#FFD400" />
                        <circle
                          cx="8"
                          cy="8"
                          r="6.5"
                          stroke="#F58220"
                          stroke-opacity="0.6"
                        />
                        <rect
                          opacity="0.6"
                          x="6.5"
                          y="4.5"
                          width="3"
                          height="7"
                          stroke="#F58220"
                        />
                        <line
                          x1="7.5"
                          y1="11"
                          x2="7.5"
                          y2="5"
                          stroke="#F58220"
                          stroke-opacity="0.6"
                        />
                      </svg>
                    </div>
                    <p className={style.titleText}>Coins</p>
                  </div>
                  {/* <div className={style.coinsAmmount}>
                  <div className={style.inline}>
                    <p className={style.ammount}>34,200</p>
                    <svg
                      className={style.coinSvg}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <circle cx="8" cy="8" r="8" fill="#FFD400" />
                      <circle
                        cx="8"
                        cy="8"
                        r="7.5"
                        stroke="black"
                        stroke-opacity="0.05"
                      />
                      <circle cx="8" cy="8" r="7" fill="#FFD400" />
                      <circle
                        cx="8"
                        cy="8"
                        r="6.5"
                        stroke="#F58220"
                        stroke-opacity="0.6"
                      />
                      <rect
                        opacity="0.6"
                        x="6.5"
                        y="4.5"
                        width="3"
                        height="7"
                        stroke="#F58220"
                      />
                      <line
                        x1="7.5"
                        y1="11"
                        x2="7.5"
                        y2="5"
                        stroke="#F58220"
                        stroke-opacity="0.6"
                      />
                    </svg>
                  </div>
                  <p className={style.titleText}>Bonus</p>
                </div> */}
                </div>
              </div>
            </div>
            <div className={style.topTenContainer}>
              {rest?.map((value, index) => (
                <div className={style.topUser}>
                  <div className={style.topNameContainer}>
                    <p className={style.numberTen}>{4 + index}. </p>
                    <h4 className={style.name}>{value.Username}</h4>
                  </div>
                  <div className={style.tenAmmount}>
                    <p className={style.ammount}>{value.coins_sum}</p>
                    <svg
                      className={style.coinSvg}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <circle cx="8" cy="8" r="8" fill="#FFD400" />
                      <circle
                        cx="8"
                        cy="8"
                        r="7.5"
                        stroke="black"
                        stroke-opacity="0.05"
                      />
                      <circle cx="8" cy="8" r="7" fill="#FFD400" />
                      <circle
                        cx="8"
                        cy="8"
                        r="6.5"
                        stroke="#F58220"
                        stroke-opacity="0.6"
                      />
                      <rect
                        opacity="0.6"
                        x="6.5"
                        y="4.5"
                        width="3"
                        height="7"
                        stroke="#F58220"
                      />
                      <line
                        x1="7.5"
                        y1="11"
                        x2="7.5"
                        y2="5"
                        stroke="#F58220"
                        stroke-opacity="0.6"
                      />
                    </svg>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <img src={stars} alt="" className={style.stars} />
      <img src={lighting} alt="" className={style.lighting} />
      <img src={starsmid} alt="" className={style.starsmid} />
      <img src={eye} alt="" className={style.eye} />
      <img src={DesktopImg} alt="" className={style.DesktopImg} />
    </div>
  );
}
