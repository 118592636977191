import moment from "moment";
import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import kefLogo from "../../../assets/logo.svg";

import style from "./style.module.scss";
import { useLocation } from "react-router-dom";

export default function CustomizedSurvey() {
  const router = useLocation();
  const wrapperRef = useRef(null);
  const routerHistory = useHistory();
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      routerHistory.push(router.pathname);
    }
  };

  return (
    <div className={style.backgroundContainer}>
      <div className={style.container} ref={wrapperRef}>
        <div className={style.titleContainer}>
          <img src={kefLogo} alt="" className={style.kefLogo} />
          <Link className={style.closeButton} to={router.pathname}>
            <svg width="20" height="20" viewBox="0 0 20 20">
              <path
                d="M11.1746 10.0002L16.4246 4.75849C16.5815 4.60157 16.6697 4.38874 16.6697 4.16682C16.6697 3.9449 16.5815 3.73207 16.4246 3.57515C16.2677 3.41823 16.0549 3.33008 15.833 3.33008C15.611 3.33008 15.3982 3.41823 15.2413 3.57515L9.99962 8.82515L4.75796 3.57515C4.60104 3.41823 4.38821 3.33008 4.16629 3.33008C3.94437 3.33008 3.73154 3.41823 3.57462 3.57515C3.4177 3.73207 3.32955 3.9449 3.32955 4.16682C3.32955 4.38874 3.4177 4.60157 3.57462 4.75849L8.82462 10.0002L3.57462 15.2418C3.49652 15.3193 3.43452 15.4115 3.39221 15.513C3.34991 15.6146 3.32812 15.7235 3.32812 15.8335C3.32812 15.9435 3.34991 16.0524 3.39221 16.154C3.43452 16.2555 3.49652 16.3477 3.57462 16.4252C3.65209 16.5033 3.74426 16.5653 3.84581 16.6076C3.94736 16.6499 4.05628 16.6717 4.16629 16.6717C4.2763 16.6717 4.38522 16.6499 4.48677 16.6076C4.58832 16.5653 4.68049 16.5033 4.75796 16.4252L9.99962 11.1752L15.2413 16.4252C15.3188 16.5033 15.4109 16.5653 15.5125 16.6076C15.614 16.6499 15.7229 16.6717 15.833 16.6717C15.943 16.6717 16.0519 16.6499 16.1534 16.6076C16.255 16.5653 16.3472 16.5033 16.4246 16.4252C16.5027 16.3477 16.5647 16.2555 16.607 16.154C16.6493 16.0524 16.6711 15.9435 16.6711 15.8335C16.6711 15.7235 16.6493 15.6146 16.607 15.513C16.5647 15.4115 16.5027 15.3193 16.4246 15.2418L11.1746 10.0002Z"
                fill="#E87588"
              />
            </svg>
          </Link>
        </div>
        <p className={style.title}>Customized surveys</p>
        <p className={style.text}>
          We can customize your survey based on your profile, ﻿When you complete
          more quests, you will be offered higher paying surveys.
        </p>
        <p className={style.title}>Profiler</p>
        <p className={style.text}>
          Verify your account by answering the following questions to gain
          access to higher payouts and customized surveys. Using this service
          constitutes your consent to the processing of your data in the manner
          outlined in this{" "}
          <Link className={style.link} to="/">
            Privacy Statement
          </Link>
        </p>
        <div className={style.warningContainer}>
          <p className={style.warningMessage}>
            Ensure all information is accurate, since the profile cannot be
            modified after it has been taken.
          </p>
        </div>
        <form className={style.formContainer}>
          <div className={`${style.inputContainer} ${style.double}`}>
            <Input
              label="Email"
              type="email"
              placeholder="E-mail Address"
              value={email}
              setValue={setEmail}
            />
          </div>
          <div className={style.inputContainer}>
            <Select
              label="Gender"
              placeholder="Gender"
              value={gender}
              setValue={setGender}
              options={[
                { value: "male", label: "Male" },
                { value: "female", label: "Female" },
                { value: "other", label: "Other" },
              ]}
            />
          </div>
          <p className={style.sectionTitle}>Birthday</p>
          <div className={style.inputContainer}>
            <Select
              placeholder="Year"
              value={year}
              setValue={setYear}
              options={Array.from(Array(50).keys()).map((index) => ({
                value: moment().year() - index,
                label: moment().year() - index,
              }))}
            />
          </div>
          <div className={style.inputContainer}>
            <Select
              placeholder="Month"
              value={month}
              setValue={setMonth}
              options={[
                { value: "01", label: "January" },
                { value: "02", label: "February" },
                { value: "03", label: "March" },
                { value: "04", label: "April" },
                { value: "05", label: "May" },
                { value: "06", label: "June" },
                { value: "07", label: "July" },
                { value: "08", label: "August" },
                { value: "09", label: "September" },
                { value: "10", label: "October" },
                { value: "11", label: "November" },
                { value: "12", label: "December" },
              ]}
            />
          </div>
          <div className={style.inputContainer}>
            <Select
              placeholder="Day"
              value={day}
              setValue={setDay}
              options={Array.from(
                Array(
                  moment(
                    `${year.length ? year : "0000"}-${
                      month.length ? month : "01"
                    }`,
                    "YYYY-MM"
                  ).daysInMonth()
                ).keys()
              ).map((index) => ({ value: index + 1, label: index + 1 }))}
            />
          </div>
        </form>
        <div className={style.inputwrapperfirst}>
          <div className={style.questionwrapper}>
            <div className={style.questionContainer}>
              <p className={style.sectionTitle}>
                Do you like to eat cilantro? (coriander)
              </p>

              <div className={style.inputsWrapper}>
                <div className={style.inputWrapper}>
                  <input
                    className={style.input}
                    type="radio"
                    id="yes"
                    name="question"
                    value="yes"
                  />
                  <div className={style.labelContainer}>
                    <label className={style.label} htmlFor="yes">
                      Yes
                    </label>
                  </div>
                </div>
                <div className={style.inputWrapper}>
                  <input
                    className={style.input}
                    type="radio"
                    id="no"
                    name="question"
                    value="no"
                  />
                  <div className={style.labelContainer}>
                    <label className={style.label} htmlFor="no">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style.submitwrapper}>
            <button className={`${style.submit} button-secondary`}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
