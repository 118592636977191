import React, { useEffect, useRef, useState } from "react";
import lodash from "lodash";

import style from "./style.module.scss";

const textX = 40;
const textY = 190;
const textSize = 10;

const horizontalDeviation = 50;
const verticalDeviation = 10;

const horizontalOffset = 50;
const verticalOffset = 40;

export default function Graph(props) {
  const { data } = props;
  const dotsContainer = useRef();
  const [closestElement, setClosestElement] = useState({});
  const userNoCoins = data.every((value) => value.value == 0);
  const actualMax = userNoCoins
    ? 5
    : Math.max.apply(
        Math,
        data.map((day) => day.value)
      );
  const max =
    Math.ceil(actualMax / Math.pow(10, actualMax.toString().length - 1)) *
    Math.pow(10, actualMax.toString().length - 1);
  const graphStep = max / 4;
  const verticalTexts = Array.from(Array(5).keys()).map((key) =>
    (key * graphStep).toFixed(0)
  );
  const horizontalTexts = data.map((day) => day.date.format("MM/DD"));
  const pointPositions = data.map(
    (day) => ((max - day.value) * 160) / max + 10
  );
  const pathString = `M ${pointPositions
    .map(
      (pointY, index) =>
        `${horizontalDeviation + index * horizontalOffset} ${pointY} ${
          index !== 6 ? "L" : ""
        }`
    )
    .join(" ")}`;
  const gradientPathString = `${pathString} V ${
    verticalDeviation + verticalOffset * 4
  } H ${horizontalDeviation} Z`;
  function renderDetails(event) {
    const frame = event.target.getBoundingClientRect();
    const actualX = event.clientX - frame.left;
    const virtualX = (actualX * 300) / frame.width;
    const closestPoint = lodash.minBy(
      data.map((day, index) => ({
        ...day,
        index,
        verticalPosition: pointPositions[index],
      })),
      (day) => Math.abs(day.index * horizontalOffset - virtualX)
    );
    if (closestElement.index !== closestPoint.index) {
      setClosestElement(closestPoint);
    }
  }
  useEffect(() => {}, [closestElement]);
  return (
    <div className={style.container}>
      <svg className={style.graph} viewBox="0 0 370 200">
        {/* Vertical Text */}
        <g className={style.textsContainer}>
          {verticalTexts.reverse().map((text, index) => (
            <text
              x={textX}
              y={verticalDeviation + index * verticalOffset}
              fontSize={textSize}
              alignmentBaseline="central"
              textAnchor="end"
              key={text}
            >
              {text}
            </text>
          ))}
        </g>
        {/* Horizontal Text */}
        <g className={style.textsContainer}>
          {horizontalTexts.map((text, index) => (
            <text
              x={horizontalDeviation + index * horizontalOffset}
              y={textY}
              fontSize={textSize}
              alignmentBaseline="central"
              textAnchor="middle"
              key={text}
            >
              {text}
            </text>
          ))}
        </g>
        {/* Graph */}
        <g className={style.gridContainer}>
          {/* Frame */}
          <g className={style.frame}>
            <rect
              x="50"
              y="10"
              width="300"
              height="160"
              onMouseMove={(event) => renderDetails(event)}
              onMouseLeave={() => setClosestElement({})}
            />
          </g>
          {/* Horizontal lines */}
          <line x1="50" y1="50" x2="350" y2="50" strokeDasharray="6, 3" />
          <line x1="50" y1="90" x2="350" y2="90" strokeDasharray="6, 3" />
          <line x1="50" y1="130" x2="350" y2="130" strokeDasharray="6, 3" />
          {/* Vertical lines */}
          <line x1="100" y1="10" x2="100" y2="170" />
          <line x1="150" y1="10" x2="150" y2="170" />
          <line x1="200" y1="10" x2="200" y2="170" />
          <line x1="250" y1="10" x2="250" y2="170" />
          <line x1="300" y1="10" x2="300" y2="170" />
          {/* Path */}
          <path d={pathString} />
          {/* Gradient path */}
          <defs>
            <linearGradient id="gradient" x1="0" x2="0.4" y1="0" y2="1">
              <stop offset="0%" stopColor="rgba(233, 69, 96, 0.9)" />
              <stop offset="100%" stopColor="rgba(0,0,0,0)" />
            </linearGradient>
          </defs>
          <path
            className={style.gradientPath}
            d={gradientPathString}
            fill="url(#gradient)"
          />
          {/* Dots */}
          <g className={style.pointsContainer} ref={dotsContainer}>
            {pointPositions.map((position, index) => (
              <circle
                cx={horizontalDeviation + index * horizontalOffset}
                cy={position}
                r={3}
                key={horizontalDeviation + index * horizontalOffset}
              />
            ))}
          </g>
          {!lodash.isEmpty(closestElement) ? (
            <foreignObject
              className={style.foreignObject}
              x={
                verticalDeviation - 20 + closestElement.index * horizontalOffset
              }
              y={closestElement.verticalPosition - 80}
              height="100"
              width="120"
            >
              <div className={style.detailsContainer}>
                {closestElement.date ? (
                  <p className={style.date}>
                    {closestElement.date.format("DD/MM/YYYY")}
                  </p>
                ) : null}

                {closestElement.value ? (
                  <p className={style.value}>{closestElement.value} coins</p>
                ) : (
                  <p className={style.value}>0 coins</p>
                )}
              </div>
            </foreignObject>
          ) : null}
        </g>
      </svg>
    </div>
  );
}
