import React from "react";

import { useRef } from "react";
import style from "./style.module.scss";

import Eyes from "../../assets/BlogArticle/eyes.svg";
import Image from "../../assets/BlogArticle/Rectangle.png";
import Brain from "../../assets/BlogArticle/brain.svg";
import blogmain from "../../assets/BlogMain/gaming2.png";

export default function BlogArticle5() {
  return (
    <div className={style.backgroundContainer}>
      <div className={style.dateContainer}>
        <p className={style.timeDate}>19/01/2022</p>
        <p className={style.timeline}>6 Minutes Read</p>
      </div>
      <div className={style.title}>
        Fortnite research recently published about focus among teens
      </div>
      <div className={style.mainPicContainer}>
        <img src={blogmain} alt="" className={style.blogPhoto} />
        <img src={Eyes} alt="" className={style.eyes} />
        <img src={Brain} alt="" className={style.brain} />
      </div>
      <div className={style.subContent}>
        <p>
          As a content creator, I am always on the lookout for new and
          interesting research that can provide valuable insights into the
          gaming community. Recently, a new study caught my attention that found
          that Fortnite players have a better chance of completing tasks and
          staying focused compared to players of other games. This study, which
          surveyed 3,000 users from the US and EU, found that Fortnite players
          had a higher level of cognitive flexibility, which is the ability to
          adapt to new tasks and switch between different tasks quickly and
          efficiently
        </p>
        <div className={style.subtitle}></div>
        <p>
          This research is significant because it suggests that Fortnite players
          have a real advantage when it comes to online tasks, such as those
          found on websites like kef.gg, a leading website to earn money online.
          Kef.gg allows players to stream their gameplay and earn money based on
          the number of viewers they have. This requires players to be able to
          multitask and switch between different tasks quickly and efficiently,
          which is where the cognitive flexibility of Fortnite players comes in
        </p>
        <div className={style.subtitle}></div>
        <p>
          Fortnite players are able to handle different tasks at once, such as
          switching between weapons, building structures, and communicating with
          their team. This ability to multitask and adapt to new situations
          quickly is crucial in the fast-paced world of online gaming, and it
          gives Fortnite players an edge when it comes to earning money on
          websites like kef.gg.
        </p>
        <div className={style.subtitle}></div>
        <p>
          This research also highlights the importance of video games in
          promoting cognitive flexibility. The study found that the more time
          players spent playing Fortnite, the higher their cognitive flexibility
          scores were. This suggests that playing video games can have a
          positive impact on cognitive abilities, and that Fortnite in
          particular may be an effective tool for improving cognitive
          flexibility
        </p>
        <div className={style.subtitle}></div>
        <p>
          It's worth noting that this study is not conclusive and further
          research is needed to understand the relationship between video games,
          cognitive flexibility and multitasking. However, it's a good
          indication that video games can have a positive impact on cognitive
          abilities, and that Fortnite players, in particular, may have an
          advantage when it comes to multitasking and completing tasks quickly
        </p>
        <iframe
          style={{ marginTop: "24px" }}
          width="853"
          height="480"
          src="https://www.youtube.com/embed/C7t1P_Zv9oE"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
        <p style={{ marginTop: "24px", marginBottom: "24px" }}>
          Top Fortnite streamers January 2023
          <ul>
            <li>Clix</li>
            <li>Ninja</li>
            <li>Mongraal</li>
            <li>MrSavage</li>
            <li>NickEh30.</li>
            <li>AnasEU</li>
            <li>Bugha</li>
            <li>SypherPK</li>
          </ul>
        </p>
        <p>
          In conclusion, as a content creator, this research is exciting because
          it highlights the potential benefits of video games, particularly
          Fortnite, in promoting cognitive flexibility and multitasking. This
          can give players a real advantage when it comes to earning money on
          websites like kef.gg, which requires players to be able to handle
          different tasks quickly and efficiently. The study is a good
          indication that video games can have a positive impact on cognitive
          abilities and that Fortnite players, in particular, may have an
          advantage in multitasking.
        </p>
      </div>
    </div>
  );
}
