export const UPDATE_CAMPAIGNS = "UPDATE_TASKS";
export const CLEAR_CAMPAIGNS = "CLEAR_TASKS";

export const updateCampaigns = (campaigns) => {
  return {
    type: UPDATE_CAMPAIGNS,
    campaigns: campaigns,
  };
};

export const clearCampaigns = () => {
  return {
    type: CLEAR_CAMPAIGNS,
  };
};
