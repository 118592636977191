import { UPDATE_LAST_EARNINGS } from "../actions/lastEarnings";
import { ADD_LAST_EARNING } from "../actions/lastEarnings";

export default (prevState = { users: [] }, action) => {
  switch (action.type) {
    case UPDATE_LAST_EARNINGS:
      return { ...prevState, users: action.lastUsers };
    case ADD_LAST_EARNING:
      return {
        ...prevState,
        users: prevState.users.concat(action.newItem),
      };
    default:
      return prevState;
  }
};
