import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";

import Graph from "../../components/Graph";
import ProgresBar from "../../components/ProgresBar";
import GeneralTab from "../../components/GeneralTab";
import Withdraw from "../../components/WithdrawTab";
import EarningsTab from "../../components/EarningsTab";
import LevelBar from "../../components/LevelBar";
import Notification from "../../popups/Views/Notification";
import { Wheel } from "react-custom-roulette";
import API from "../../api/";
import { useSelector } from "react-redux";

import sadFace from "../../assets/Profile/sadFace.png";
import xD from "../../assets/Profile/XD.png";
import Normal from "../../assets/Profile/Normal.png";
import Pinger from "../../assets/Profile/pinger.png";
import Loading from "../../components/LoadingScreen";

import style from "./style.module.scss";

const wheeldata = [
  { option: "1x", style: { backgroundColor: "#e94560", textColor: "white" } },
  { option: "2x", style: { backgroundColor: "#ffffff", textColor: "black" } },
  { option: "3x", style: { backgroundColor: "#e94560", textColor: "white" } },
  { option: "4x", style: { backgroundColor: "#ffffff", textColor: "black" } },
  { option: "5x", style: { backgroundColor: "#e94560", textColor: "white" } },
  { option: "1x", style: { backgroundColor: "#ffffff", textColor: "black" } },
  { option: "2x", style: { backgroundColor: "#e94560", textColor: "white" } },
  { option: "3x", style: { backgroundColor: "#ffffff", textColor: "black" } },
  { option: "4x", style: { backgroundColor: "#e94560", textColor: "white" } },
  { option: "5x", style: { backgroundColor: "#ffffff", textColor: "black" } },
  { option: "1x", style: { backgroundColor: "#e94560", textColor: "white" } },
  { option: "2x", style: { backgroundColor: "#ffffff", textColor: "black" } },
  { option: "3x", style: { backgroundColor: "#e94560", textColor: "white" } },
  { option: "4x", style: { backgroundColor: "#ffffff", textColor: "black" } },
  { option: "5x", style: { backgroundColor: "#e94560", textColor: "white" } },
  {
    option: "BONUS",
    style: { backgroundColor: "#FFE483", textColor: "#e94560" },
  },
];

function Switch({ active }) {
  switch (active) {
    case "general":
      return <GeneralTab />;
    case "earnings":
      return <EarningsTab />;
    case "withdraw":
      return <Withdraw />;
    default:
      return <GeneralTab />;
  }
}

export default function Profile() {
  const [confirm, setConfirm] = useState(false);
  const [roulette, setRoulette] = useState(false);
  const [mustSpin, setMustSpin] = useState(false);
  const [availableSpin, setAvailableSpin] = useState(true);
  const [buttonMessage, setButtonMessage] = useState("Spin");
  const [message, setMessage] = useState("");
  const [claimReward, setClaimReward] = useState(0);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [hover, setHover] = useState(false);
  const [userdata, setUserData] = useState([]);
  const [milestones, setMilestones] = useState();
  const [url, setUrl] = useState();
  const [timer, setTimer] = useState();
  const [claim, setClaim] = useState("Loading!");
  const [hours, setHours] = useState(20);
  const [minutes, setMinutes] = useState(20);
  const [seconds, setSeconds] = useState(20);
  const [lastEarnings, setLastEarnings] = useState([]);
  const router = useLocation();

  const user = useSelector((state) => state.authReducer.user);
  const queryParams = new URLSearchParams(useLocation().search);
  const active = queryParams.get("active") || "general";
  const Client = API.getInstance();
  const routerController = useHistory();
  const profile = useSelector((state) => state.temporary.profile);
  useEffect(() => {
    if (profile !== undefined && profile != 0) {
      setUserData(profile);
      setLastEarnings(profile.last_earnings);
      const Url = profile?.daily_reward_bar?.claim_url;
      const Timer = profile?.daily_reward_bar?.next_claim;
      setTimer(Timer);
      setUrl(Url);
    }
  }, [profile]);
  const data = [
    {
      date: moment(lastEarnings[0]?.date ?? 0, "YYYY-MM-DD"),
      value: lastEarnings[0]?.coins ?? 0,
    },
    {
      date: moment(lastEarnings[1]?.date ?? 0, "YYYY-MM-DD"),
      value: lastEarnings[1]?.coins ?? 0,
    },
    {
      date: moment(lastEarnings[2]?.date ?? 0, "YYYY-MM-DD"),
      value: lastEarnings[2]?.coins ?? 0,
    },
    {
      date: moment(lastEarnings[3]?.date ?? 0, "YYYY-MM-DD"),
      value: lastEarnings[3]?.coins ?? 0,
    },
    {
      date: moment(lastEarnings[4]?.date ?? 0, "YYYY-MM-DD"),
      value: lastEarnings[4]?.coins ?? 0,
    },
    {
      date: moment(lastEarnings[5]?.date ?? 0, "YYYY-MM-DD"),
      value: lastEarnings[5]?.coins ?? 0,
    },
    {
      date: moment(lastEarnings[6]?.date ?? 0, "YYYY-MM-DD"),
      value: lastEarnings[6]?.coins ?? 0,
    },
  ];

  useEffect(() => {
    if (timer == null && url) {
      setClaim("Claim!" ?? 0);
    } else if (url == null && timer) {
      msToTime(timer * 1000);
      setClaim("Next Claim: " + hours + ":" + minutes + ":" + seconds);
    } else {
      setClaim("Loading...");
    }
  }, [timer, url]);

  function msToTime(duration) {
    var seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return setHours(hours), setMinutes(minutes), setSeconds(seconds);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer && url === null)
        if (seconds == 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          if (seconds <= 10) {
            setSeconds("0" + (seconds - 1));
          } else {
            setSeconds(seconds - 1);
          }

          if (minutes == 0 && seconds == 0) {
            setHours(hours - 1);
            setMinutes(59);
          }
          if (hours == 0 && minutes == 0 && seconds == 0) {
            setClaim(
              "Claim: " + userdata?.daily_reward_bar?.next_level_amount ?? 0
            );
          } else {
            setClaim("Next Claim: " + hours + ":" + minutes + ":" + seconds);
          }
        }
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds, minutes, hours, claim]);

  async function claimDaily() {
    setButtonMessage("Please wait.");
    const token = user.signInUserSession.accessToken.jwtToken;
    const responose = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(responose);
    if (responose.data == true)
      /*routerController.push(`${router.pathname}?popup=notification`, {
      amount: userdata?.daily_reward_bar?.next_level_amount,
    });*/
      console.log(responose.data);
    setClaimReward(responose?.data?.claim_reward);
    handleSpinClick(responose?.data?.spin_choice);
    setAvailableSpin(false);
  }
  const name =
    useSelector(
      (state) =>
        state.authReducer.user?.attributes?.given_name ??
        state.authReducer.user?.username
    ) ?? "";

  const handleSpinClick = (prizeNumber) => {
    setButtonMessage("Spinning...");
    setPrizeNumber(prizeNumber);
    setMustSpin(true);
  };
  return (
    <>
      {userdata.length === 0 ? (
        <Loading />
      ) : (
        <div className={style.mainWrapper}>
          <div className={style.wrapper}>
            <div className={style.lightBackground}>
              <div className={style.background}>
                <div className={style.profileContainer}>
                  <div className={style.userContainer}>
                    <div className={style.avatarContainer}>
                      <img
                        className={style.avatar}
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQI3vvVZ-pOGsyhaNEm9s-tm96lh7OGxJrpPQ&usqp=CAU"
                        alt="Avatar"
                      />
                    </div>
                    <div className={style.content}>
                      <div className={style.nameContainer}>
                        <p className={style.level}>333</p>
                        <p className={style.name}>{name}</p>
                      </div>
                      <p className={style.xp}>
                        149 XP earned. 51 XP required for next level.
                      </p>
                    </div>
                  </div>
                  <div className={style.progresContainer}>
                    <ProgresBar value={40} />
                  </div>
                  <div className={style.cardsContainer}>
                    <div className={style.card}>
                      <p className={style.value}>
                        {userdata?.completed_offers}
                      </p>
                      <p className={style.label}>Complete Offers</p>
                    </div>
                    <div className={style.card}>
                      <p className={style.value}>{userdata?.coins_earned}</p>
                      <p className={style.label}>Coins Earned</p>
                    </div>
                    <div className={style.card}>
                      <p className={style.value}>{userdata?.users_referred}</p>
                      <p className={style.label}>User Referred</p>
                    </div>
                  </div>
                </div>
                <div className={style.graphContainer}>
                  <p className={style.label}>
                    Recent Earning (The Last 7 Days)
                  </p>
                  {lastEarnings?.length > 0 && <Graph data={data} />}
                </div>
              </div>
            </div>
            <div className={style.darkBackground}>
              <div className={style.buttonsContainer}>
                <Link
                  className={`button ${
                    active === "general" ? "button-secondary" : ""
                  }`}
                  to={`${router.pathname}?active=general`}
                >
                  General
                </Link>
                <Link
                  className={`button ${
                    active === "earnings" ? "button-secondary" : ""
                  }`}
                  to={`${router.pathname}?active=earnings`}
                >
                  Earnings
                </Link>
                {/* <Link
                  className={`button ${
                    active === "withdraw" ? "button-secondary" : ""
                  }`}
                  to={`${router.pathname}?active=withdraw`}
                >
                  Withdraw
                </Link> */}
              </div>
              <div className={style.switchContainer}>
                <Switch active={active} />
              </div>
            </div>
            <div className={style.lightBackground}>
              <div className={style.backgrnd}>
                <p className={style.title}>Rewards</p>
                <div className={style.flexRow}>
                  <p className={style.label}>Claim daily bonus</p>
                  <button
                    className={style.button}
                    onClick={() => {
                      if (timer == null && url) {
                        console.log("url is ready");
                        // claimDaily();
                        setRoulette(true);
                      } else if (url == null && timer) {
                        console.log("timer is ready");
                      } else {
                        console.log("Buggy");
                        console.log(timer);
                      }
                    }}
                  >
                    {claim}
                    {timer == null && url ? (
                      <img
                        src="/assets/svgs/coin.svg"
                        alt="Coin"
                        className={style.icon}
                      />
                    ) : null}
                  </button>
                </div>
                <div className={style.levelContainer}>
                  <LevelBar
                    experience={30}
                    curentLevel={{ start: 1, end: 50, level: 1 }}
                    achievements={[
                      {
                        position: 10,
                        price:
                          userdata?.daily_reward_bar?.milestones[0]?.amount ??
                          0,
                        multiplier:
                          userdata?.daily_reward_bar?.milestones[0]
                            ?.multiplier ?? 0,
                      },
                      {
                        position: 25,
                        price:
                          userdata?.daily_reward_bar?.milestones[1]?.amount ??
                          0,
                        multiplier:
                          userdata?.daily_reward_bar?.milestones[1]
                            ?.multiplier ?? 0,
                      },
                      {
                        position: 40,
                        price:
                          userdata?.daily_reward_bar?.milestones[2]?.amount ??
                          0,
                        multiplier:
                          userdata?.daily_reward_bar?.milestones[2]
                            ?.multiplier ?? 0,
                      },
                    ]}
                  />
                  <button
                    className={style.buttondesktop}
                    onClick={() => {
                      if (timer == null && url) {
                        //claimDaily();
                        setRoulette(true);
                      }
                    }}
                  >
                    {claim}
                    {timer == null && url ? (
                      <img
                        src="/assets/svgs/coin.svg"
                        alt="Coin"
                        className={style.icon}
                      />
                    ) : null}
                  </button>
                </div>
                {/* <div className={style.flexRow}>
              <p className={style.label}>Claim daily bonus</p>
              <button className={style.button}>
                Claim 50
                <img
                  src="/assets/svgs/coin.svg"
                  alt="Coin"
                  className={style.icon}
                />
              </button>
            </div>
            <div className={style.levelContainer}>
              <Link className={style.link}>
                Click here to follow us on twitter
              </Link>
              <button className={style.buttondesktop}>
                Claim 50
                <img
                  src="/assets/svgs/coin.svg"
                  alt="Coin"
                  className={style.icon}
                />
              </button>
            </div>
            <div className={style.flexRow}>
              <p className={style.label}>Claim bonus code</p>
              <button className={style.button}>
                Claim 50
                <img
                  src="/assets/svgs/coin.svg"
                  alt="Coin"
                  className={style.icon}
                />
              </button>
            </div>
            <div className={style.levelContainer}>
              <Link className={style.link}>Enter a bonus code</Link>
              <button className={style.buttondesktop}>
                Claim 50
                <img
                  src="/assets/svgs/coin.svg"
                  alt="Coin"
                  className={style.icon}
                />
              </button>
            </div>
            <div className={style.flexRow}>
              <p className={style.label}>Claim opt email bonus</p>
              <button className={style.button}>
                Claim 100
                <img
                  src="/assets/svgs/coin.svg"
                  alt="Coin"
                  className={style.icon}
                />
              </button>
            </div>
            <div className={style.levelContainer}>
              <Link className={style.link}>Enter email for Opt Readinf</Link>
              <button className={style.buttondesktop}>
                Claim 100
                <img
                  src="/assets/svgs/coin.svg"
                  alt="Coin"
                  className={style.icon}
                />
              </button>
            </div> */}
                <div
                  onClick={() => {
                    setConfirm(true);
                  }}
                  className={style.flexRowOptional}
                >
                  <p className={style.label}>Delete account</p>
                  <button className={style.submit}>Delete</button>
                </div>
              </div>
            </div>
          </div>
          {confirm ? (
            <div className={style.confirmContainer}>
              <div className={style.wrapper}>
                <div className={style.content}>
                  <div className={style.titleContainer}>
                    <p className={style.title}>Are you sure ? </p>
                  </div>
                  <div className={style.infoContainer}>
                    {hover ? (
                      <img src={xD} alt="Image" className={style.face} />
                    ) : (
                      <img src={sadFace} alt="Image" className={style.face} />
                    )}
                    <p className={style.description}>
                      You can delete your Kef.gg Account at any time. If you
                      change your mind, you wont be able to recover it and use
                      the same email address
                    </p>
                    <div className={style.buttons}>
                      <div className={style.buttonContainer}>
                        <p className={style.buttonTextGray}>Yes</p>
                      </div>

                      <div
                        onClick={() => {
                          setConfirm(false);
                          setHover(false);
                        }}
                        onMouseOver={() => {
                          setHover(true);
                        }}
                        onMouseOut={() => {
                          setHover(false);
                        }}
                        className={style.buttonContainer}
                      >
                        <p className={style.buttonText}>NO!</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {roulette ? (
            <div className={style.rouletteContainer}>
              <div className={style.wrapper}>
                <div className={style.content}>
                  <div className={style.titleContainer}>
                    <p className={style.title}>Roulette Wheel!</p>
                  </div>
                  <div className={style.infoContainer}>
                    <p className={style.description}>
                      This is your daily roulette! Click Spin to start spinning
                      and lets see how much you will earn today.
                    </p>
                    {/* <p className={style.coins}>10 Coins</p> */}
                    <div className={style.wheelWrapper}>
                      <Wheel
                        mustStartSpinning={mustSpin}
                        prizeNumber={prizeNumber}
                        data={wheeldata}
                        textColors={["#ffffff"]}
                        textDistance={70}
                        //innerBorderColor={"#e94560"}
                        innerBorderWidth={25}
                        innerBorderColor={"#FFE483"}
                        outerBorderWidth={10}
                        outerBorderColor={"#e94560"}
                        radiusLineColor={null}
                        fontSize={26}
                        spinDuration={0.6}
                        onStopSpinning={() => {
                          setMustSpin(false);
                          setMessage("You earned: " + claimReward + " coins.");
                          setButtonMessage("Claim.");
                        }}
                      />
                      <img src={Pinger} alt="" className={style.pinger} />
                    </div>
                    {message.length ? (
                      <>
                        <p className={style.message}>Congratulations!</p>
                        <p className={style.message}>{message}</p>
                      </>
                    ) : null}
                    <div
                      className={`${"button-secondary"} ${style.spinButton}`}
                      onClick={() => {
                        if (availableSpin === true) {
                          claimDaily();
                        }
                        if (availableSpin === false) {
                          setAvailableSpin(false);
                          setRoulette(false);
                          routerController.push(
                            `${router.pathname}?popup=notification`,
                            {
                              amount: claimReward,
                            }
                          );
                        }
                        //  handleSpinClick();
                      }}
                    >
                      {buttonMessage}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
}
