import React, { useState, useRef, useEffect } from "react";

import Title from "../../../assets/Login/Title.png";
import Lock from "../../../assets/Login/Lock.png";
import Finger from "../../../assets/Login/Finger.png";
import Cube from "../../../assets/Login/Cube.png";
import Section from "../../../assets/Login/Section.png";
import kefLogo from "../../../assets/logo.svg";
import style from "./style.module.scss";
import axios from "axios";
import ReactGA from "react-ga4";
import Input from "../../../components/Input";
import API from "../../../api";
import TotalRevenue from "../../../components/TotalRevenue";
import VerificationSent from "../VerificationSent";
import { Link, useLocation, useHistory } from "react-router-dom";
import { store } from "../../../store";
import { Login as LoginAction } from "../../../store/actions/auth";
import { updateUsername } from "../../../store/actions/username";

import Amplify, { Auth } from "aws-amplify";
import awsconfig from "../../../aws-exports";

Amplify.configure(awsconfig);

export default function ConfirmAccount({ usernamelogin, passwordlogin }) {
  const wrapperRef = useRef(null);
  const router = useLocation();
  const routerController = useHistory();
  const Client = API.getInstance();
  const [country, setCountry] = useState("");

  const callGoogleAnalyticsConversion = () => {
    ReactGA.event({
      category: "Sign Up",
      action: "Successful",
      label: "User Signed Up",
      value: 1,
    });
  };
  useEffect(() => {
    (async () => {
      axios
        .get("https://ipapi.co/json/")
        .then((response) => {
          let data = response.data;
          setCountry(data.country);
        })
        .catch((error) => {
          console.log(error);
        });
    })();
  }, []);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      routerController.push(router.pathname);
    }
  };

  useEffect(async () => {
    const response = await Auth.currentAuthenticatedUser();
    console.log(response);
  });

  const hasUsername = Boolean(
    routerController.location.state
      ? routerController.location.state.username
      : false
  );
  const hasPassword = Boolean(
    routerController.location.state
      ? routerController.location.state.password
      : false
  );
  const [username, setUsername] = useState(
    hasUsername ? routerController.location.state.username : ""
  );
  const [password, setPassword] = useState(
    hasPassword ? routerController.location.state.password : ""
  );
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");
  async function handleSubmit(event) {
    event.preventDefault();
    try {
      setMessage("Confirming Account!");
      await Auth.confirmSignUp(username, code);
      setMessage("Logging In!");
      await Auth.signIn(username, password);
      await store.dispatch(updateUsername(username));
      callGoogleAnalyticsConversion();
      const response = await Auth.currentAuthenticatedUser();
      routerController.push(router.pathname);
    } catch (error) {
      setMessage(error.message);
      if (error.code === "NotAuthorizedException") {
        routerController.push(`${router.pathname}?popup=login`);
      }
      console.log(error);
    }
  }

  return (
    <div className={style.backgroundContainer}>
      <div className={style.container} ref={wrapperRef}>
        <div className={style.titleContainer}>
          {/* <img src={kefLogo} alt="" className={style.kefLogo} /> */}
          <Link className={style.closeButton} to={router.pathname}>
            <svg width="20" height="20" viewBox="0 0 20 20">
              <path
                d="M11.1746 10.0002L16.4246 4.75849C16.5815 4.60157 16.6697 4.38874 16.6697 4.16682C16.6697 3.9449 16.5815 3.73207 16.4246 3.57515C16.2677 3.41823 16.0549 3.33008 15.833 3.33008C15.611 3.33008 15.3982 3.41823 15.2413 3.57515L9.99962 8.82515L4.75796 3.57515C4.60104 3.41823 4.38821 3.33008 4.16629 3.33008C3.94437 3.33008 3.73154 3.41823 3.57462 3.57515C3.4177 3.73207 3.32955 3.9449 3.32955 4.16682C3.32955 4.38874 3.4177 4.60157 3.57462 4.75849L8.82462 10.0002L3.57462 15.2418C3.49652 15.3193 3.43452 15.4115 3.39221 15.513C3.34991 15.6146 3.32812 15.7235 3.32812 15.8335C3.32812 15.9435 3.34991 16.0524 3.39221 16.154C3.43452 16.2555 3.49652 16.3477 3.57462 16.4252C3.65209 16.5033 3.74426 16.5653 3.84581 16.6076C3.94736 16.6499 4.05628 16.6717 4.16629 16.6717C4.2763 16.6717 4.38522 16.6499 4.48677 16.6076C4.58832 16.5653 4.68049 16.5033 4.75796 16.4252L9.99962 11.1752L15.2413 16.4252C15.3188 16.5033 15.4109 16.5653 15.5125 16.6076C15.614 16.6499 15.7229 16.6717 15.833 16.6717C15.943 16.6717 16.0519 16.6499 16.1534 16.6076C16.255 16.5653 16.3472 16.5033 16.4246 16.4252C16.5027 16.3477 16.5647 16.2555 16.607 16.154C16.6493 16.0524 16.6711 15.9435 16.6711 15.8335C16.6711 15.7235 16.6493 15.6146 16.607 15.513C16.5647 15.4115 16.5027 15.3193 16.4246 15.2418L11.1746 10.0002Z"
                fill="#E87588"
              />
            </svg>
          </Link>
          <h4 className={style.title}>Welcome</h4>
          <p className={style.subtitle}>We’re so excited to see you again!</p>
        </div>
        <div className={style.background}>
          <div className={style.imageContainer}>
            <h1 className={style.btcText}>Start earning free bitcoin</h1>
            <img src={Section} alt="Image" className={style.FreeBtcImg} />
            <h2 className={style.ammountText}>Total amount cashed</h2>
            <div className={style.totAmmountContainer}>
              <TotalRevenue />
            </div>
          </div>
          <form
            className={style.content}
            onSubmit={(event) => handleSubmit(event)}
          >
            <div className={style.imageTitleContainer}>
              <img src={Title} alt="Title" className={style.title} />
            </div>
            {message.length ? (
              <div className={style.errorContainer}>
                <p className={style.message}>{message}</p>
              </div>
            ) : null}
            {!hasUsername ? (
              <div className={style.inputContainer}>
                <Input
                  label="Enter Your Username"
                  type="text"
                  placeholder="Username"
                  value={username}
                  setValue={setUsername}
                />
              </div>
            ) : null}
            <div className={style.inputContainer}>
              <Input
                label="Enter Your Confirmation Code"
                type="text"
                placeholder="Code"
                value={code}
                setValue={setCode}
              />
            </div>
            <div className={style.flexButtonContainer}>
              <button className={`button-secondary ${style.noFlex}`}>
                Confirm
              </button>
              <div
                className={`button-inactive ${style.noFlex}`}
                onClick={() => {
                  routerController.push(
                    `${router.pathname}?popup=verification-sent`
                  );
                }}
              >
                Resend
              </div>
              <Link
                className={`button-inactive ${style.noFlex}`}
                to={`${router.pathname}`}
              >
                Cancel
              </Link>
            </div>
            <div className={style.disclaimerContainer}>
              <p className={style.disclaimer}>
                Check your email for your confirmation code
              </p>
            </div>
          </form>
          <img src={Lock} alt="Lock" className={style.lock} />
          <img src={Finger} alt="Finger" className={style.finger} />
          <img src={Cube} alt="Cube" className={style.cube} />
        </div>
      </div>
    </div>
  );
}
