export const UPDATE_FEATURED_OFFERS = "UPDATE_FEATURED_OFFERS";
export const CLEAR_FEATURED_OFFERS = "CLEAR_FEATURED_OFFERS";

export const updateFeaturedOffers = (featuredOffers) => {
  return {
    type: UPDATE_FEATURED_OFFERS,
    featuredOffers: featuredOffers,
  };
};

export const clearFeaturedOffers = () => {
  return {
    type: CLEAR_FEATURED_OFFERS,
  };
};
