import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import style from "./style.module.scss";
import API from "../../api/";

export default function GeneralTab() {
  const [userdata, setUserData] = useState();
  const Client = API.getInstance();
  const history = useHistory();
  const router = useLocation();
  useEffect(async () => {
    const response = await Client.getProfileGeneral();
    setUserData(response.data);
  }, []);
  return (
    <div className={style.container}>
      <div className={style.section}>
        <p className={style.title}>Your info</p>
        <div className={style.table}>
          {/* <div className={style.tableRow}>
            <p className={style.label}>kEF ID</p>
            <p className={style.value}>996699</p>
          </div> */}
          {/* <div className={style.tableRow}>
            <p className={style.label}>Googleid </p>
            <p className={style.value}>10938493250822353233</p>
          </div> */}
          <div className={style.tableRow}>
            <p className={style.label}>Balance</p>
            <p className={style.value}>
              {userdata?.balance}
              <img
                className={style.icon}
                src="/assets/svgs/coin.svg"
                alt="Coin"
              />
            </p>
          </div>
          <div className={style.tableRow}>
            <p className={style.label}>Verified?</p>
            <p className={style.value}>Yes</p>
          </div>
          <div className={style.tableRow}>
            <p className={style.label}>Max offer value</p>
            <p className={style.value}>
              {userdata?.max_offer_value}
              <img
                className={style.icon}
                src="/assets/svgs/coin.svg"
                alt="Coin"
              />
            </p>
          </div>
          <div className={style.tableRow}>
            <p className={style.label}>Referrer</p>
            <p className={style.value}>{userdata?.users_referred ?? 0} users</p>
          </div>
          <div className={style.tableRow}>
            <p className={style.label}>Referral earnings</p>
            <p className={style.value}>
              {userdata?.referral_earnings}
              <img
                className={style.icon}
                src="/assets/svgs/coin.svg"
                alt="Coin"
              />
            </p>
          </div>
          {userdata?.referrer != "null" && (
            <div className={style.tableRow}>
              <p className={style.label}>Referred by</p>
              <p className={style.value}>{userdata?.referrer}</p>
            </div>
          )}
          {/* <div className={style.tableRow}>
            <p className={style.label}>Date joined</p>
            <p className={style.value}>2021/05/24</p>
          </div> */}
          <div className={style.tableRow}>
            <p className={style.label}>Email</p>
            <p className={style.value}>{userdata?.user_email}</p>
          </div>
          <div className={style.tableRow}>
            <p className={style.label}>Tree Certeficates</p>
            <p className={style.value}>
              <div
                className={`${"button button-secondary"} ${
                  style.certeficateButton
                }`}
                onClick={() => {
                  history.push(`${router.pathname}?popup=tree-certeficates`);
                }}
              >
                Certeficates
              </div>
            </p>
          </div>
        </div>
      </div>
      <div className={style.section}>
        <p className={style.title}>Settings</p>
        {/* <div className={style.flexRow}>
          <p className={style.label}>Change name</p>
          <div className={style.inputContainer}>
            <input type="text" className={style.input} />
            <button className={style.submit}>Change</button>
          </div>
        </div> */}
        {/* <div className={style.flexRow}>
          <p className={style.label}>Profile visability</p>
          <div className={style.switchContainer}>
            <p className={style.switchLabel}>Private</p>
            <input id="swich" type="checkbox" className={style.input} />
            <label htmlFor="swich" className={style.switchWrapper}>
              <div className={style.switchIndicator} />
            </label>
            <p className={style.switchLabel}>Public</p>
          </div>
        </div> */}
        {/* <div className={style.flexRow}>
          <p className={style.label}>Delete account</p>
          <button className={style.submit}>Delete</button>
        </div> */}
      </div>
    </div>
  );
}
