import { UPDATE_CLICKED, CLEAR_CLICKED } from "../actions/offerClicked";

export default (prevState = { clicked: 0 }, action) => {
  switch (action.type) {
    case UPDATE_CLICKED:
      return { ...prevState, clicked: action.clicked };
    case CLEAR_CLICKED: {
      return { ...prevState, clicked: 0 };
    }
    default:
      return prevState;
  }
};
