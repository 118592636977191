import React from "react";

import Google from "../../assets/Login/Google-Icon.png";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import style from "./style.module.scss";

export default function Login() {
  const router = useLocation();
  return (
    <div className={style.container}>
      <div className={style.popupContainer}>
        <div className={style.popup}>
          <div className={style.loginPopup}>
            <p className={style.login}>Please Login to Continue</p>
            <div className={style.buttonContainer}>
              <Link
                className={`button-secondary ${style.buttonContent}`}
                to={`${router.pathname}?popup=login`}
              >
                <p className={style.buttonContent}>Login</p>
              </Link>
              <button
                className="button-inactive"
                onClick={() => {
                  Auth.federatedSignIn({
                    provider: "Google",
                  });
                }}
              >
                <div className={style.buttonContent}>
                  <img className={style.icon} src={Google} alt="Google" />
                  <p className={style.buttonText}>Google</p>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
