import React from "react";

import style from "./style.module.scss";

import Dizzy from "../../assets/NotFound/Dizzy.svg";
import eyes from "../../assets/NotFound/eyes.svg";

export default function NotFound() {
  return (
    <div className={style.backgroundContainer}>
      <div className={style.image}>
        <img className={style.face} src={Dizzy} alt="Image" />
        <img className={style.leftEye} src={eyes} alt="Image" />
        <img className={style.rightEye} src={eyes} alt="Image" />
      </div>
      <h4 className={style.title}>PAGE NOT FOUND!</h4>
    </div>
  );
}
