import React from "react";
import { useRef } from "react";
import style from "./style.module.scss";

import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

import header from "../../assets/Advertisers2/header.svg";
import face from "../../assets/Advertisers2/face.svg";
import popcorn from "../../assets/Advertisers2/popcorn.svg";
import flag from "../../assets/Advertisers2/flag.svg";
import ufo from "../../assets/Advertisers2/ufo.svg";
import nonGaming from "../../assets/Advertisers2/nonGaming.png";
import gaming from "../../assets/Advertisers2/gaming.png";
import surveys from "../../assets/Advertisers2/surveys.png";
import web3 from "../../assets/Advertisers2/web3.png";
import rubik from "../../assets/Advertisers2/rubik.svg";
import planet from "../../assets/Advertisers2/planet.svg";
import crystals from "../../assets/Advertisers2/crystals.svg";
import kef from "../../assets/Advertisers2/kef.svg";

import jonathan from "../../assets/Advertisers/Team/jonathan.svg";
import liran from "../../assets/Advertisers/Team/liran.svg";
import nimrod from "../../assets/Advertisers/Team/nimrod.svg";
import armando from "../../assets/Advertisers/Team/armando.svg";
import gil from "../../assets/Advertisers/Team/gil.svg";

import daniel from "../../assets/Advertisers/Advisor/daniel.svg";
import tomer from "../../assets/Advertisers/Advisor/tomer.svg";
import tess from "../../assets/Advertisers/Advisor/tess.svg";
import ido from "../../assets/Advertisers/Advisor/ido.svg";

export default function Advertisers() {
  const tableData = [
    {
      title: "Apps",
      description:
        "We partner with game studios and app developers to increase user loyalty and orchestrate brand messaging in a 100% user initiated interactions",
      subDescription:
        "We reward users for what really moves the needle for developers",
    },
    {
      title: "Surveys",
      description:
        "Brands are actively seeking insights from an increasingly diverse and segmented global population.",
      subDescription:
        " We are closely connected to a vibrant demographic of predominantly young adults aged 13-24, with a majority of 55% being male.",
      moreDescription:
        "primarily comprises of English speakers from the US, UK, and Canada, making up 85% of our reach, with a shared passion for technology, gadgets, and gaming.",
    },
    {
      title: "Contest winners",
      description:
        "We’re running daily, weekly and monthly contests for better engagement with our offerings",
      subDescription: "",
    },
    {
      title: "CTV channels",
      description:
        "We will be the first user acquisition platform for CTV channels",
      subDescription:
        "As the industry is growing, attribution is becoming adopted by various advertisers",
    },
    {
      title: "Email Newsletter",
      description:
        "Every week, we send out an informative newsletter packed with the latest trends, news, and updates about our platform and the gaming industry as a whole.",
      subDescription:
        "Our recipients eagerly anticipate it, with an impressive 35% open rate, and we're thrilled to see our click rates at 10% and steadily increasing.",
    },
  ];

  return (
    <div className={style.backgroundContainer}>
      <div className={style.headerContainer}>
        <img src={header} alt="Image" className={style.header} />
        <div className={style.welcomeContainer}>
          <div className={style.welcome}>WELCOME TO</div>
          <img src={kef} alt="Image" className={style.kef} />
        </div>
      </div>
      <div className={style.sectionContainer}>
        <p className={style.sectionTitle}>Mission & Vision </p>
        <p className={style.sectionSubTitle}>
          Kef.gg is the leading platform for rewarded games online
        </p>
        <p className={style.sectionParagraph}>
          We picture a world where earning a little extra cash is as easy as
          playing a game, completing a task online gets you paid instantly and
          digitally.
        </p>
        <p className={style.sectionParagraph}>
          It's a vision where every individual's attention is recognized as a
          precious commodity, a resource that we all possess in limited supply.
        </p>
        <p className={style.sectionParagraph}>
          The focus is on assisting brands with not just to capture attention
          but to truly connect with users, offering products and services that
          genuinely enhance their lives.
        </p>
        <p className={style.sectionParagraph}>
          We believe that the current methods of bombarding users with targeted
          ads and excessive data mining fall short of creating meaningful,
          positive interactions with brands in our lives, and we are not the
          only ones
        </p>
      </div>
      <div className={style.sectionContainer}>
        <p className={style.sectionTitle}>Market Opportunity</p>

        <p className={style.sectionParagraph}>
          the market opportunity lies in creating a platform that facilitates
          genuine conversations between brands and users, and rewards users for
          their engagement. This concept aligns with evolving trends in digital
          advertising, data privacy, and user-centric experiences
        </p>
        <p className={style.sectionParagraph}>
          Brands are continually seeking better ways to engage with their target
          audience. A platform that enables authentic, two-way conversations
          with users can provide a new and effective channel for brands to
          connect with potential customers and increase customer loyalty
        </p>
      </div>
      <div className={style.ourProductSection}>
        <h2
          className={style.ourProductTitle}
        >{`Revenue Streams {current and future}`}</h2>
        <img src={face} alt="Image" className={style.face} />
        <img src={popcorn} alt="Image" className={style.popcorn} />
        <div className={style.cardsContainer}>
          {tableData.map((data, index) => (
            <div className={style.card} key={index}>
              <h2 className={style.cardTitle}>{data.title}</h2>
              <p className={style.cardDescription}>{data.description}</p>
              {data?.subDescription && (
                <p className={style.cardDescription}>{data.subDescription}</p>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={style.roadmapContainer}>
        <h2 className={style.roadMapTitle}>!ROADMAP!</h2>
        <Swiper
          slidesPerView={1} // Set this to 6 for vertical show
          freeMode={true}
          modules={[Pagination, Navigation]}
          className={style.roadMapSwiper}
          navigation={true}
          initialSlide={2}
          // direction={"vertical"}
          breakpoints={{
            1100: {
              slidesPerView: 6.1,
              spaceBetween: 20,
            },
          }}
        >
          <SwiperSlide className={style.roadMap}>
            <h2 className={style.title}>Q1 23</h2>
            <div className={style.bigCircle}>
              <img src={flag} alt="Image" className={style.flag} />
            </div>
            <div className={style.roadProgress} />
            <ul className={style.itemsContainer}>
              <li className={style.item}>Working MVP - full user cycle</li>
              <li className={style.item}>Sign up</li>
              <li className={style.item}>Wallet withdrawal </li>
              <li className={style.item}>Referral system</li>
            </ul>
          </SwiperSlide>
          <SwiperSlide className={style.roadMap}>
            <h2 className={style.title}>Q2 23</h2>
            <div className={style.bigCircle}>
              <img src={flag} alt="Image" className={style.flag} />
            </div>
            <div className={style.roadProgressCurrent}>
              <div className={style.fill} />
            </div>
            <ul className={style.itemsContainer}>
              <li className={style.item}>Site functionality</li>
              <li className={style.item}>Direct Advertising partners</li>
              <li className={style.item}>Increased media buying budgets</li>
              <li className={style.item}>Support</li>
              <li className={style.item}>Crypto wallet integrations</li>
              <li className={style.item}>Weekly newsletter</li>
              <li className={style.item}>Gift Cards withdrawal</li>
            </ul>
          </SwiperSlide>
          <SwiperSlide className={style.roadMap}>
            <h2 className={style.title}>Q3 23</h2>
            <div className={style.currCircle}>
              <img src={ufo} alt="Image" className={style.ufo} />
            </div>
            <div className={style.roadProgressCurrent}></div>
            <ul className={style.itemsContainer}>
              <li className={style.item}>Time based rewards</li>
              <li className={style.item}>Direct casual app advertisers</li>
              <li className={style.item}>Community building</li>
              <li className={style.item}>Daily and weekly contests</li>
              <li className={style.item}>Video based advertising</li>
              <li className={style.item}>
                Retention Formula - UA is being a DJ
              </li>
            </ul>
          </SwiperSlide>
          <SwiperSlide className={style.roadMap}>
            <h2 className={style.title}>Q4 23</h2>
            <div className={style.currCircle}>
              <img src={ufo} alt="Image" className={style.ufo} />
            </div>
            <div className={style.roadProgressCurrent}></div>
            <ul className={style.itemsContainer}>
              <li className={style.item}>SDK for developers (time based)</li>
              <li className={style.item}>
                Onboard Casual & midcore advertisers
              </li>
              <li className={style.item}>Admin dashboard reporting</li>
              <li
                className={style.item}
              >{`increasing user segmentation {questionnaires}`}</li>
              <li className={style.item}>Android App</li>
            </ul>
          </SwiperSlide>
          <SwiperSlide className={style.roadMap}>
            <h2 className={style.title}>Q1 24</h2>
            <div className={style.currCircle}>
              <img src={ufo} alt="Image" className={style.ufo} />
            </div>
            <div className={style.roadProgressCurrent}></div>
            <ul className={style.itemsContainer}>
              <li className={style.item}>Creating a Token</li>
              <li className={style.item}>In-house video creation</li>
              <li className={style.item}>Create brand collectibles</li>
              <li className={style.item}>improve contests and giveaways</li>
              <li className={style.item}>
                strategic partnerships with gaming studios
              </li>
            </ul>
          </SwiperSlide>
          <SwiperSlide className={style.roadMap}>
            <h2 className={style.title}>Q2 24</h2>
            <div className={style.currCircle}>
              <img src={ufo} alt="Image" className={style.ufo} />
            </div>
            <ul className={style.itemsContainer}>
              <li className={style.item}>Scaling ad spend</li>
              <li className={style.item}>Increase platform retention</li>
              <li className={style.item}>Direct advertisers</li>
              <li className={style.item}>iOS App</li>
            </ul>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className={style.companiesSection}>
        <h2 className={style.gtm}>GTM:</h2>
        <p className={style.companiesTitle}>
          Growth companies actively running open web user acquisition campaigns
        </p>
        <div className={style.imageContainer}>
          <img src={gaming} alt="Image" className={style.gaming} />
          <div className={style.middleContainer}>
            <img src={surveys} alt="Image" className={style.middle} />
            <img src={web3} alt="Image" className={style.middle} />
          </div>
          <img src={nonGaming} alt="Image" className={style.gaming} />
        </div>
      </div>
      <div className={style.customerAcquisition}>
        <h2 className={style.title}>Customer Acquisition:</h2>
        <h3 className={style.subTitle}>LTV english speaking users - $34</h3>
        <div className={style.currentChannelsWrapper}>
          <p className={style.currentChannels}>Current UA channels</p>
          <table className={style.table}>
            <tr className={style.tableRow}>
              <td className={style.tableCell}>Google search -</td>
              <td className={style.tableCell}>eCPA=$0.32</td>
              <td
                className={style.tableCell}
              >{`{reduced from an eCPA of $0.9}`}</td>
            </tr>
            <tr className={style.tableRow}>
              <td className={style.tableCell}>Open web platforms -</td>
              <td className={style.tableCell}>eCPA=$2.5</td>
              <td className={style.tableCell}>{`{complete a task}`}</td>
            </tr>
          </table>
        </div>
      </div>
      <div className={style.financialProjections}>
        <h2 className={style.title}>Financial Projections:</h2>
        <h3 className={style.subTitle}>
          We are currently bootstrapped, profitable, lean and ‘cheap’
        </h3>
        <h3 className={style.subTitle}>
          adding 1 direct advertiser every quarter should result in the
          following conservative projections:
        </h3>
        <div className={style.currentChannelsWrapper}>
          <div className={style.tableWrapper}>
            <table className={style.table}>
              <tr className={style.tableRow}>
                <td className={style.tableCellRight}>Revenue</td>
                <td className={style.tableCellCenter}>Q2-23</td>
                <td className={style.tableCell}>300$</td>
              </tr>
              <tr className={style.tableRow}>
                <td className={style.tableCell}></td>
                <td className={style.tableCellCenter}>Q3-23</td>
                <td
                  className={style.tableCell}
                >{`35,000$ {so far generated}`}</td>
              </tr>
              <tr className={style.tableRow}>
                <td className={style.tableCell}></td>
                <td className={style.tableCellCenter}>Q4-23</td>
                <td className={style.tableCell}>{`85,000$ {projection}`}</td>
              </tr>
              <tr className={style.tableRow}>
                <td className={style.tableCell}></td>
                <td className={style.tableCellCenter}>Q1-24</td>
                <td className={style.tableCell}>{`110,000$ {projection}`}</td>
              </tr>
              <tr className={style.tableRow}>
                <td className={style.tableCell}></td>
                <td className={style.tableCellCenter}>Q2-24</td>
                <td className={style.tableCell}>{`200,000$ {projection}`}</td>
              </tr>
              <tr className={style.tableRow}>
                <td className={style.tableCell}></td>
                <td className={style.tableCellCenter}>Q3-24</td>
                <td className={style.tableCell}>{`280,000$ {projection}`}</td>
              </tr>
              <tr className={style.tableRow}>
                <td className={style.tableCell}></td>
                <td className={style.tableCellCenter}>Q4-24</td>
                <td className={style.tableCell}>{`330,000$ {projection}`}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div className={style.userContainer}>
        <div className={style.teamContainer}>
          <h2 className={style.title}>DA TEAM!</h2>
          <div className={style.user}>
            <div className={style.userDescription}>
              <div className={style.container}>
                <h3 className={style.userName}>Jonathan Storch</h3>
                <h2 className={style.userRank}>CEO</h2>
              </div>
              <img src={jonathan} alt="Image" className={style.userImage} />
            </div>
            <p className={style.userParagraph}>
              Entrepreneur, Performance marketer, Music prodcuer, PC gamer
            </p>
          </div>
          <div className={style.user}>
            <div className={style.userDescription}>
              <div className={style.container}>
                <h3 className={style.userName}>Nimrod Carmi</h3>
                <h2 className={style.userRank}>UX Lead Designer</h2>
              </div>
              <img src={nimrod} alt="Image" className={style.userImage} />
            </div>
            <p className={style.userParagraph}>
              Master of arts from städelschule Frankfurt ‘create school'
              graduate tel aviv
            </p>
          </div>
          <div className={style.user}>
            <div className={style.userDescription}>
              <div className={style.container}>
                <h3 className={style.userName}>Armando Gjoni</h3>
                <h2 className={style.userRank}>Front End</h2>
              </div>
              <img src={armando} alt="Image" className={style.userImage} />
            </div>
            <p className={style.userParagraph}>
              B.Sc. Computer Science from FanS.Noli, Google skeptic
            </p>
          </div>
          <div className={style.user}>
            <div className={style.userDescription}>
              <div className={style.container}>
                <h3 className={style.userName}>Gil Twill</h3>
                <h2 className={style.userRank}>System architect</h2>
              </div>
              <img src={gil} alt="Image" className={style.userImage} />
            </div>
            <p className={style.userParagraph}>
              MAMRAM graduate, Israeli P"M office, cloud solution architect, MMA
              fighter
            </p>
          </div>
        </div>
        <div className={style.advisorContainer}>
          <h2 className={style.title}>DA ADVISORS!</h2>
          <div className={style.user}>
            <div className={style.userDescription}>
              <div className={style.container}>
                <h3 className={style.userName2}>Tomer Warschauer Nuni</h3>
                <h2 className={style.userRank2}>The crypto influencer</h2>
              </div>
              <img src={tomer} alt="Image" className={style.userImage} />
            </div>
            <p className={style.userParagraph}>
              {`Tomer is the CMO of kryptomon, an NFT Play-and-Earn blockchain game, (10mm round led by NFX)
Seed investor {Cookie3, DucksVegas, Utopian Game Labs} and public speaker `}
            </p>
          </div>
          <div className={style.user}>
            <div className={style.userDescription}>
              <div className={style.container}>
                <h3 className={style.userName}>Daniel Rechter</h3>
                <h2 className={style.userRank}>The founder</h2>
              </div>
              <img src={daniel} alt="Image" className={style.userImage} />
            </div>
            <p className={style.userParagraph}>
              Daniel is a Serial gaming founder, Big Blue Parrot was acquired by
              Playtika, Playright Games exit was to Qiiwi, created a Japanese
              rhythm game which was sold to Eisys
            </p>
          </div>
          <div className={style.user}>
            <div className={style.userDescription}>
              <div className={style.container}>
                <h3 className={style.userName}>Tess r. suchoff</h3>
                <h2 className={style.userRank}>Data geek</h2>
              </div>
              <img src={tess} alt="Image" className={style.userImage} />
            </div>
            <p className={style.userParagraph}>
              Tess is a Director of Strategic Data Partnerships @ Apptopia and
              an advisor to Kindred while being a research team leader at
              Harvard Uni
            </p>
          </div>
          <div className={style.user}>
            <div className={style.userDescription}>
              <div className={style.container}>
                <h3 className={style.userName}>Ido Kirshenboim</h3>
                <h2 className={style.userRank}>Open web master</h2>
              </div>
              <img src={ido} alt="Image" className={style.userImage} />
            </div>
            <p className={style.userParagraph}>
              Ido is a leading open web marketer mastering discovery and
              attribution processes @ Monday.com
            </p>
          </div>
        </div>
        <img src={rubik} alt="Image" className={style.rubik} />
        <img src={planet} alt="Image" className={style.planet} />
      </div>
      <div className={style.contactSection}>
        <div className={style.contactContainer}>
          <div className={style.centering}>
            <a href="mailto:js@commodifiedimage.com" className={style.contact}>
              Click To Contact
            </a>
          </div>
        </div>
        <img src={crystals} alt="Image" className={style.crystals} />
      </div>
    </div>
  );
}
