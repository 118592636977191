import React, { useState } from "react";
import style from "./style.module.scss";
import capitalOneCampaign from "../../assets/Campaign/capitalOneCampaign.jpeg";
import mafiaCityCampaign from "../../assets/Campaign/mafiaCityCampaign.jpeg";
import fantasticBricksCampaign from "../../assets/Campaign/fantasticBricksCampaign.jpeg";
import campaignKef from "../../assets/logo.svg";
import { getMobileOperatingSystem } from "../../components/FetchOffers";
import downloadFromAppStore from "../../assets/Campaign/downloadFromAppStore.webp";
import downloadFromPlayStore from "../../assets/Campaign/downloadFromPlayStore.webp";
import iphone from "../../assets/Campaign/iphone-picture.png";
import android from "../../assets/Campaign/android-picture.webp";
import versus from "../../assets/Campaign/versus.svg";
import { useEffect } from "react";
import QRCode from "react-qr-code";

export default function Campaign() {
  const [deviceType, setDeviceType] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const operatingSystem = getMobileOperatingSystem();
  const [description1, setDescription1] = useState(false);
  const [description2, setDescription2] = useState(false);
  const [description3, setDescription3] = useState(false);
  const [description4, setDescription4] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const publisherName = urlParams.get("publisher") ?? " ";

  const isIosAvailable = deviceType == "ios" || selectedDevice == "ios";
  const isAndroidAvailable =
    deviceType == "android" || selectedDevice == "android";

  useEffect(() => {
    if (operatingSystem == "ios") {
      setDeviceType("ios");
      setSelectedDevice("ios");
    } else if (operatingSystem == "android") {
      setDeviceType("android");
      setSelectedDevice("android");
    } else {
      setDeviceType(null);
    }
  }, [operatingSystem]);

  const deviceTypeTitle = `Click to support ${publisherName} - download one of these apps from the  ${
    operatingSystem == "ios" ? `AppStore` : ""
  }  ${operatingSystem == "android" ? `PlayStore` : ""} ${
    operatingSystem != "android" && operatingSystem != "ios"
      ? `Appstore or PlayStore`
      : ""
  }`;
  return (
    <div className={style.wrapper}>
      {deviceType == null && selectedDevice != null && (
        <button
          className={` ${"button-secondary"} ${style.backButton}`}
          onClick={() => {
            setSelectedDevice(null);
          }}
        >
          {"<- Back"}
        </button>
      )}
      <p className={style.containerTitle}>
        {selectedDevice
          ? "You can select one of the offers below!"
          : deviceTypeTitle}
      </p>
      {selectedDevice ? (
        <div className={style.container}>
          {isIosAvailable && (
            <>
              <div className={style.firstCampaign}>
                <img
                  className={style.campaignImage}
                  onClick={() => {
                    setDescription1(true);
                  }}
                  src={mafiaCityCampaign}
                />
                <p className={style.campaignTitle}>Mafia City - US - IOS</p>
                <p className={style.campaignPrice}>Earns: 0.70$</p>

                {description1 && (
                  <p>{`Mafia city is a virtual reality you CAN'T miss out on!
A Real-Time Strategy game that requires you to make alliances with other players and constantly improve 
Download now to add $0.7 to ${publisherName}`}</p>
                )}
                <div className={style.buttonContainer}>
                  <img
                    src={downloadFromAppStore}
                    className={style.campaignButton}
                    onClick={() => {
                      if (operatingSystem)
                        window.open(
                          "https://tracking.kef.gg/click?pid=8079&offer_id=6224673",
                          "_blank"
                        );
                    }}
                  ></img>
                </div>
                {operatingSystem == "desktop" && (
                  <QRCode
                    size={206}
                    style={{
                      marginTop: "24px",
                      height: "auto",
                      width: "200px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    value={
                      "https://tracking.kef.gg/click?pid=8079&offer_id=6224673"
                    }
                  />
                )}
              </div>
              <img src={versus} className={style.versus}></img>
              <div className={style.firstCampaign}>
                <img
                  className={style.campaignImage}
                  onClick={() => {
                    setDescription2(true);
                  }}
                  src={capitalOneCampaign}
                />
                <p className={style.campaignTitle}>
                  Capital One Shopping US iOS - Install
                </p>
                <p className={style.campaignPrice}>Earns: $1.00</p>
                {description2 && (
                  <p>{`Download now to add $1 to ${publisherName}
                  Get free automatic coupons and exclusive savings on the go from the biggest finance app! only install the app
                  no Capital One account required! Get free automatic coupons and exclusive savings on the go. Plus, get a free $10 rewards bonus when you download the app and add the free tool to Safari.`}</p>
                )}
                <div className={style.buttonContainer}>
                  <img
                    src={downloadFromAppStore}
                    className={style.campaignButton}
                    onClick={() => {
                      window.open(
                        "https://tracking.kef.gg/click?pid=8079&offer_id=6224048",
                        "_blank"
                      );
                    }}
                  ></img>
                </div>
                {operatingSystem == "desktop" && (
                  <QRCode
                    size={206}
                    style={{
                      marginTop: "24px",
                      height: "auto",
                      width: "200px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    value={
                      "https://tracking.kef.gg/click?pid=8079&offer_id=6224048"
                    }
                  />
                )}
              </div>
            </>
          )}
          {isAndroidAvailable && (
            <>
              <div className={style.firstCampaign}>
                <img
                  className={style.campaignImage}
                  onClick={() => {
                    setDescription3(true);
                  }}
                  src={fantasticBricksCampaign}
                />
                <p className={style.campaignTitle}>Fantastic Bricks</p>
                <p className={style.campaignPrice}>Earns: 0.30$</p>
                {description3 && (
                  <p>{`Fantastic Bricks: Ball Games
                  is a mesmerizing decompression and puzzle game that will challenge your skills and entertain you and your friends for hours on end`}</p>
                )}
                <div className={style.buttonContainer}>
                  <img
                    src={downloadFromPlayStore}
                    className={style.campaignButton}
                    onClick={() => {
                      window.open(
                        "https://tracking.kef.gg/click?pid=8079&offer_id=6224667",
                        "_blank"
                      );
                    }}
                  ></img>
                </div>
                {operatingSystem == "desktop" && (
                  <QRCode
                    size={206}
                    style={{
                      marginTop: "24px",
                      height: "auto",
                      width: "200px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    value={
                      "https://tracking.kef.gg/click?pid=8079&offer_id=6224667"
                    }
                  />
                )}
              </div>
              <img src={versus} className={style.versus}></img>
              <div className={style.firstCampaign}>
                <img
                  className={style.campaignImage}
                  onClick={() => {
                    setDescription4(true);
                  }}
                  src={campaignKef}
                />
                <p className={style.campaignTitle}>Kef.gg</p>
                <p className={style.campaignPrice}>Earns: 0.30$</p>
                {description4 && (
                  <p>{`Discover amazing new digital products and earn rewards for playing.`}</p>
                )}
                <div className={style.buttonContainer}>
                  <img
                    src={downloadFromPlayStore}
                    className={style.campaignButton}
                    onClick={() => {
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.commodifiedimage.keffgg",
                        "_blank"
                      );
                    }}
                  ></img>
                </div>
                {operatingSystem == "desktop" && (
                  <QRCode
                    size={206}
                    style={{
                      marginTop: "24px",
                      height: "auto",
                      width: "200px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    value={
                      "https://play.google.com/store/apps/details?id=com.commodifiedimage.keffgg"
                    }
                  />
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className={style.selectDeviceContainer}>
          <div className={style.deviceContainer}>
            <img
              className={style.iphone}
              src={iphone}
              onClick={() => {
                setSelectedDevice("ios");
              }}
            ></img>
            <img
              src={downloadFromAppStore}
              className={style.campaignButton}
              onClick={() => {
                setSelectedDevice("ios");
              }}
            ></img>
          </div>
          <div className={style.deviceContainer}>
            <img
              className={style.android}
              src={android}
              onClick={() => {
                setSelectedDevice("android");
              }}
            ></img>
            <img
              src={downloadFromPlayStore}
              className={style.campaignButton}
              onClick={() => {
                setSelectedDevice("android");
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
