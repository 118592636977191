import React from "react";
import style from "./style.module.scss";

import triangle from "../../assets/TermsOfService/triangle.svg";
import zShape from "../../assets/TermsOfService/Z-shape.svg";

export default function TermsOfService() {
  return (
    <div className={style.terms}>
      <div className={style.bcgSvg}>
        <div className={style.topLeftCube}>
          <svg
            width="59"
            height="84"
            viewBox="0 0 59 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.9408 35.584L38.6853 17.2252L9.73973 8.0168C9.70543 8.0063 9.66838 8.00959 9.63646 8.02596L-23.5625 25.554L5.9408 35.584Z"
              fill="white"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.0872 75.5932L-16.1564 64.6073C-16.2788 64.563 -16.3685 64.4572 -16.3923 64.3292L-23.5635 25.5566L5.94052 35.5845L13.0872 75.5932Z"
              fill="#9AF3FF"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M47.4137 57.434L13.0875 75.5929L5.94085 35.5842L38.6853 17.2255L47.6321 56.9457C47.6762 57.1389 47.5872 57.3381 47.4137 57.434Z"
              fill="#62A3FF"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M26.8361 39.8223L26.0293 36.0346L23.1845 38.6606"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.5463 56.3845L14.0298 54.7617L14.4267 58.6127"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M40.7641 57.5961L44.174 55.7613L40.8584 53.7637"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M29.338 61.5825L33.2069 61.7164L31.347 58.3195"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M37.1162 43.28L40.9857 43.4118L39.1258 40.0148"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M29.7676 43.4688L28.7407 47.2029L32.4769 46.1837"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.0885 39.5571L14.9553 39.6904L13.0975 36.2941"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M34.3949 27.8042L37.0864 25.022L33.3203 24.125"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.8623 66.9844L16.9689 70.8527L20.2442 68.7884"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.3112 34.9652L20.7485 31.1182"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.1456 48.64L13.8486 46.6133"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M39.1802 50.7721L36.7822 47.7314"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M27.402 53.1576L23.7136 54.3343"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M33.7491 35.2535L30.5664 33.0508"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M22.8863 66.6696L23.5972 62.8652"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.3536 44.4166C21.8856 44.5858 22.1485 43.7593 21.6166 43.5901C21.0846 43.4208 20.8195 44.2467 21.3536 44.4166Z"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M28.3385 28.776C28.8704 28.9452 29.1355 28.1193 28.6014 27.9494C28.0673 27.7795 27.8065 28.6067 28.3385 28.776Z"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M35.0143 56.0348C35.5441 56.2033 35.8091 55.3775 35.2772 55.2082C34.7452 55.039 34.4802 55.8649 35.0143 56.0348Z"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.3932 51.1891C12.923 51.3576 13.1881 50.5318 12.6561 50.3625C12.1242 50.1933 11.8591 51.0192 12.3932 51.1891Z"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19.9449 61.484C20.4769 61.6532 20.7419 60.8273 20.2079 60.6574C19.6738 60.4875 19.413 61.3147 19.9449 61.484Z"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M38.6057 34.9945C39.1377 35.1637 39.4006 34.3372 38.8687 34.1679C38.3367 33.9987 38.0738 34.8252 38.6057 34.9945Z"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M-6.76564 68.1305L-13.8184 28.8682L-6.76564 68.1305Z"
              fill="#9AF3FF"
            />
            <path
              d="M-6.76564 68.1305L-13.8184 28.8682"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M-1.26492 70.1937L-8.62842 30.6318L-1.26492 70.1937Z"
              fill="#9AF3FF"
            />
            <path
              d="M-1.26492 70.1938L-8.62842 30.6318"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3.99382 72.1741L-3.48682 32.3789L3.99382 72.1741Z"
              fill="#9AF3FF"
            />
            <path
              d="M3.99382 72.1741L-3.48682 32.3789"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.63579 73.8375L1.60693 34.1113L8.63579 73.8375Z"
              fill="#9AF3FF"
            />
            <path
              d="M8.63579 73.8375L1.60693 34.1113"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className={style.topRightCube}>
          <svg
            width="50"
            height="80"
            viewBox="0 0 50 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.0893 39.8607L47.6627 69.9649L15.8092 63.4966C15.651 63.4632 15.5215 63.35 15.4672 63.1977L5.15527 33.8379L37.0893 39.8607Z"
              fill="#7774FF"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M58.2523 15.1887L68.5643 44.5485C68.6171 44.7013 68.5869 44.8706 68.4843 44.9956L47.663 69.9647L37.0896 39.8605L58.2523 15.1887Z"
              fill="#FF81AF"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M58.2518 15.1884L37.089 39.8602L5.15501 33.8374L26.2849 10.5728C26.5193 10.3141 26.8686 10.1914 27.2133 10.2467L58.2518 15.1884Z"
              fill="#FFFEAA"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.18288 34.5015L30.1442 10.714"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.2086 35.3287L34.3155 11.3781"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.1746 35.9668L38.2855 12.0111"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.2029 36.7937L42.4571 12.6738"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M25.1857 37.4995L46.4921 13.3177"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M29.1513 38.1373L50.3894 14.0364"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M33.1794 38.9638L54.6336 14.6144"
              stroke="#0D052C"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className={style.bottomRightTriangle}>
          <img src={triangle} alt="" />
        </div>
        <div className={style.bottomLeftFigure}>
          <img src={zShape} alt="" />
        </div>
      </div>
      <div className={style.title}>
        <h2>Terms Of Service</h2>
        <h3>last updated - 2021-08-01</h3>
      </div>
      <div className={style.info}>
        <div className={style.single}>
          <h3>
            Welcome to Kef.gg If you continue to browse and use this website,
            you are agreeing to comply with and be bound by the following terms
            and conditions of use, which together with our privacy policy govern
            Kef.gg’s relationship with you in relation to this website. If you
            disagree with any part of these terms and conditions, please
            discontinue use of our website. <br /> <br />
          </h3>
        </div>
        <div className={style.single}>
          <h3>Terms</h3>
          <p>
            By accessing our services on this site, you are agreeing to the
            Terms and Conditions laid out here. You also agree to comply with
            all applicable laws and regulations, and you are responsible for
            compliance with any local laws. This site is protected by applicable
            copyright law. We reserve the right to refuse service to anyone,
            without reason at any time.
          </p>
        </div>
        <div className={style.single}>
          <h3>Prohibited Uses</h3>
          <p>This site may not be used for unlawful purposes.</p>
        </div>
        <div className={style.single}>
          <h3>On-site Chat</h3>
          <p>
            The chat feature on the site may not be used for the following:{" "}
            <br /> <br />
            Harassing other users or making them feel unwelcome. <br />
            Advertising or spamming. This includes posting referral links.{" "}
            <br />
            Asking other users or staff for money (begging). <br />
            Sharing pornographic or deep web links.
          </p>
        </div>
        <div className={style.single}>
          <h3>Restricted Access</h3>
          <p>You must be at least 13 years of age to use our services.</p>
        </div>
        <div className={style.single}>
          <h3>Referral Link/Redeem Code</h3>
          <p>
            We reserve the right to revoke users' referral link/redeem code.
            This would only be done in the case of a user taking a code commonly
            used by another person, such as YouTubers or online celebrities.
          </p>
        </div>
        <div className={style.single}>
          <h3>Account Bans</h3>
          <p>
            We reserve the right to temporarily or permanently ban users at any
            time. Any activity we deem to be suspicious, or potentially
            fraudulent, would warrant an account freeze. <br />
            <br />
            Any of the following would warrant an account ban/freeze: <br />{" "}
            <br />
            Using multiple accounts. <br />
            Receiving offer chargebacks. <br />
            Completing offers on another user's account. <br />
            Using any type of VPN, VPS or Emulator software. <br />
            Completing many offers in a short space of time. <br />
            Completing suspicious offers which have a high rate of chargeback.
          </p>
        </div>
        <div className={style.single}>
          <h3>Offer Holds</h3>
          <p>
            We reserve the right to temporarily hold offer credits at any time.
            This will occur automatically if a survey goes over a user's credit
            limit. <br /> <br />
            We reserve the right to hold these credits for up to 90 days after
            offer completion.
          </p>
        </div>
        <div className={style.single}>
          <h3>Coins</h3>
          <p>
            All 'currency' values on this site are represented by Coins. Coins
            do not represent any real life value, and can only be used in
            exchange for our withdrawal options.
          </p>
        </div>
        <div className={style.single}>
          <h3>Refunds</h3>
          <p>
            You may receive a refund if the site has made an error. Any other
            error, such as an error with PayPal or third party, or mistakes
            inputting withdrawal addresses are outside of our control, and are
            not eligible for refund from us. You would need to contact the
            provider directly to receive a refund. <br /> <br />
            We cannot refund coins for withdrawing to a banned/limited PayPal
            account, however if you contact PayPal and arrange for the
            transaction to be refunded back into our account, we will be able to
            refund your coins when we receive the funds back.
          </p>
        </div>
      </div>
    </div>
  );
}
