import React from "react";

import style from "./style.module.scss";

export default function Message(props) {
  const { data } = props;
  props.handleUsers(data.users_count);
  return (
    <div className={style.container}>
      <div className={style.avatarContainer}>
        <img
          className={style.avatar}
          src={`https://staging.kef.gg/api/static/user_avatars/${data.picId}.svg`}
          alt="Avatar"
        />
      </div>
      <div className={style.content}>
        <div className={style.nameContainer}>
          <p className={style.level}>{data.currentTime}</p>
          <p className={style.name}>{data.username}</p>
        </div>
        <p className={style.message}>{data.msg}</p>
      </div>
    </div>
  );
}
