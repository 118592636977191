import React from "react";

import style from "./style.module.scss";

export default function Loading() {
  return (
    <div className={style.container}>
      <div className={style.spinner}>
        <div className={style.dot1}></div>
        <div className={style.dot2}></div>
      </div>
      <h3 className={style.loading}>
        Our elves are working on getting you paid!
      </h3>
    </div>
  );
}
