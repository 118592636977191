import React from "react";

import { useRef } from "react";
import style from "./style.module.scss";

import Eyes from "../../assets/BlogArticle/eyes.svg";
import Image from "../../assets/BlogArticle/Rectangle.png";
import Brain from "../../assets/BlogArticle/brain.svg";
import blogmain from "../../assets/BlogMain/gaming4.png";

export default function BlogArticle7() {
  return (
    <div className={style.backgroundContainer}>
      <div className={style.dateContainer}>
        <p className={style.timeDate}>28/02/2023</p>
        <p className={style.timeline}>4 Minutes Read</p>
      </div>
      <div className={style.title}>
        Fiver app gets people to grow their talents
      </div>
      <div className={style.mainPicContainer}>
        <img
          src="https://fiverr-res.cloudinary.com/npm-assets/layout-server/fiverr-og-logo.5fd6463.png"
          alt=""
          className={style.blogPhoto}
        />
        <img src={Eyes} alt="" className={style.eyes} />
        <img src={Brain} alt="" className={style.brain} />
      </div>
      <div className={style.subContent}>
        <p>
          As a hustler and content creator, I'm always on the lookout for tools
          that can help me streamline my work and increase my productivity.
          That's why I'm excited to share my review of the Fiverr app, a
          platform that connects freelancers with clients who need their
          services.
        </p>
        <div className={style.subtitle}></div>
        <p>
          First and foremost, I have to say that the Fiverr app is incredibly
          userfriendly. It's easy to navigate, and finding the services you need
          is a breeze. Whether you're looking for graphic design, copywriting,
          programming, or any other type of service, Fiverr has you covered. The
          app allows you to filter your search results by category, budget,
          delivery time, and more, making it easy to find the perfect freelancer
          for your needs.
        </p>
        <div className={style.subtitle}></div>
        <p>
          Download here <a href="http://bit.ly/3z7Mlhl">Fiver iOS</a>{" "}
          <a href="http://bit.ly/3JMiBf1">Fiver Android</a>
        </p>
        <div className={style.subtitle}></div>
        <p>
          Another great feature of the Fiverr app is the messaging system. Once
          you've hired a freelancer, you can communicate with them directly
          through the app. This is a great way to stay in touch with your
          freelancer and ensure that the project is on track. You can also use
          the messaging system to share files and ask any questions you might
          have.
        </p>
        <div className={style.subtitle}></div>
        <p>
          Of course, one of the biggest advantages of using the Fiverr app is
          the cost savings. Because freelancers on Fiverr come from all over the
          world, the prices for their services can be much lower than what you
          would pay for the same services in your local market. This is a huge
          advantage for businesses and individuals who are looking to save money
          without sacrificing quality.
        </p>
        <div className={style.subtitle}></div>
        <p>
          Overall, I have to say that the Fiverr app is one of my favorite tools
          for freelancers and businesses alike. Whether you're looking to hire a
          freelancer for a one-time project or you need ongoing support for your
          business, Fiverr has you covered.
        </p>
        <div className={style.subtitle}></div>
        <p>
          With its user-friendly interface, high-quality freelancers, messaging
          system, and cost savings, Fiverr is a must-have app for anyone who
          wants to get more done with less. So if you haven't already, download
          the Fiverr app today and see for yourself why it's one of the best
          apps on the market for freelancers and businesses alike.
        </p>
        <div className={style.subtitle}></div>
        <p>
          Check out the awesome app by downloading it here
          <a href="http://bit.ly/3z7Mlhl">Fiver iOS</a>
          <a href="http://bit.ly/3JMiBf1">Fiver android</a>
        </p>
        <div className={style.subtitle}></div>
        <iframe
          width="853"
          height="480"
          src="https://www.youtube.com/embed/U__YrDLoHRw"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div>
  );
}
