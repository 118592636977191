import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import ReactGA from "react-ga4";

import style from "./style.module.scss";

export default function EventGA(props) {
  useEffect(() => {
    ReactGA.event({
      category: props.category,
      action: props.action,
    });
  }, []);
  return null;
}
