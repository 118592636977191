export const UPDATE_LAST_EARNINGS = "UPDATE_LAST_EARNINGS";
export const ADD_LAST_EARNING = "ADD_LAST_EARNING";

export const updateLastEarnings = (data) => {
  return {
    type: UPDATE_LAST_EARNINGS,
    lastUsers: data,
  };
};

export const addLastEarnings = (data) => {
  return {
    type: ADD_LAST_EARNING,
    newItem: data,
  };
};
