import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import style from "./style.module.scss";

export default function Input(props) {
  const { onBlur, label, type, placeholder, value, setValue } = props;
  const [id, setId] = useState();
  useEffect(() => {
    setId(
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    );
  }, []);
  function handleBlur() {
    if (onBlur) {
      onBlur();
    }
  }
  return (
    <div className={style.container}>
      <label className={style.label} htmlFor={id}>
        {label}
      </label>
      <input
        className={style.input}
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        onBlur={() => handleBlur()}
      />
    </div>
  );
}
